import styled, { keyframes } from "styled-components";

import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Text from "./Text";

export default function Button({ children, ...rest }) {
  return (
    <Container {...rest} disabled={rest.disabled || rest.loading}>
      <F1>{rest.loading && <LoadingOutlinedExtended spin />}</F1>
      <Text color="white">{children}</Text>
      <F1 />
    </Container>
  );
}

export const F1 = styled.div`
  width: 1.3rem;
`;

export const LoadingOutlinedExtended = styled(LoadingOutlined)`
  font-size: 1.3rem;
`;

export const Container = styled.button`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
padding: ${(props) => (props.big ? `1rem` : `0 0.5rem`)};
  border-radius: 0.8rem;
  /* text-transform: uppercase; */
  text-decoration: none;
  position: relative;

  overflow: hidden;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
  border: 0px;
  color: #fff;
  z-index: 0;
  background-color: #0070c9;
  background: linear-gradient(#42a1ec, #0070c9);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem;
  min-width: 9rem;

  /* height: ${(props) => (props.big ? `5rem` : `3rem`)}; */
  width: ${(props) => props.big && `40rem`};

  @media (max-width: 768px) {
    width: ${(props) => props.big && `90vw`};
  }

  &:disabled {
    background-color: #d9d9d9;
    background: #d9d9d9;
  }

  &:not(:disabled):hover {
    cursor: pointer;
  }
  &:not(:disabled):hover:after,
  &:not(:disabled):focus:after {
    -webkit-animation-name: bgin;
    animation-name: bgin;
    -webkit-transform: skew(-45deg) translate(-50%);
    transform: skew(-45deg) translate(-50%);
  }
  &:focus {
    outline: 0;
  }
  &:after {
    content: "";
    width: 150%;
    z-index: -1;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-animation-name: bgout;
    animation-name: bgout;
    -webkit-animation-duration: 400ms;
    animation-duration: 400ms;
    -webkit-transform: skew(-45deg) translate(-150%);
    transform: skew(-45deg) translate(-150%);
    background-color: #0061ad;
    background: linear-gradient(#42a1ec, #0061ad);
  }

  @-webkit-keyframes bgin {
    from {
      -webkit-transform: skew(-45deg) translate(-150%);
      transform: skew(-45deg) translate(-150%);
    }
    to {
      -webkit-transform: skew(-45deg) translate(-50%);
      transform: skew(-45deg) translate(-50%);
    }
  }

  @keyframes bgin {
    from {
      -webkit-transform: skew(-45deg) translate(-150%);
      transform: skew(-45deg) translate(-150%);
    }
    to {
      -webkit-transform: skew(-45deg) translate(-50%);
      transform: skew(-45deg) translate(-50%);
    }
  }
  @-webkit-keyframes bgout {
    from {
      -webkit-transform: skew(-45deg) translate(-50%);
      transform: skew(-45deg) translate(-50%);
    }
    to {
      -webkit-transform: skew(-45deg) translate(50%);
      transform: skew(-45deg) translate(50%);
    }
  }
  @keyframes bgout {
    from {
      -webkit-transform: skew(-45deg) translate(-50%);
      transform: skew(-45deg) translate(-50%);
    }
    to {
      -webkit-transform: skew(-45deg) translate(50%);
      transform: skew(-45deg) translate(50%);
    }
  }
`;
