import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default (ComposedClass) => (props) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (!localStorage.getItem("accessToken")) {
      window.location.href = "/login";
    }
  }, []);

  return <ComposedClass {...props} />;
};
