import React, { useEffect } from "react";

function PrivatePolicy() {
  const currentLanguage = window.localStorage.i18nextLng === "ko" ? "ko" : "en";

  if (currentLanguage === "ko") {
    return (
      <>
        <section>
          <div
            style={{
              marginBottom: "100px",
              lineHeight: "26px",
              maxWidth: "1020px",
              margin: "0 auto",
              padding: "0 20px",
            }}
          >
            <p
              style={{
                fontSize: "3rem",
                marginBottom: "40px",
                textAlign: "center",
                paddingTop: "100px",
                lineHeight: "66px",
              }}
            >
              UCWORKS 개인정보 처리방침
            </p>
            <div>
              <br />
              (주)유씨웨어 주식회사는 이용자들의 개인정보를 소중히 다루고
              있습니다.
              <br />
              <br />
              (주)유씨웨어 주식회사(이하, ‘회사’라 함)는 정보통신망 이용촉진 및
              정보보호 등에 관한 법률, 개인정보보호법, 통신비밀보호법,
              전기통신사업법 등 정보통신서비스제공자가 준수하여야 할 관련
              법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한
              개인정보방침을 정하여 이용자권익 보호에 최선을 다하고 있습니다.
              <br />
              <br />본 개인정보처리방침은 회사가 제공하는 UCWORKS 서비스에 한해
              적용되며 다음과 같은 내용을 담고 있습니다.
              <br />
              <br />
              1. 수집하는 개인정보의 항목 및 수집방법
              <br />
              2. 개인정보의 수집 및 이용목적
              <br />
              3. 개인정보 공유 및 제공
              <br />
              4. 개인정보의 보유 및 이용기간
              <br />
              5. 개인정보 반환절차 및 방법
              <br />
              6. 개인정보 파기절차 및 방법
              <br />
              7. 이용자 및 법정대리인의 권리와 그 행사방법
              <br />
              8. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항
              <br />
              9. 개인정보의 기술적/관리적 보호 대책
              <br />
              10. 적용범위
              <br />
              11. 고지의 의무
              <br />
              <br />
              <br />
              <h2>1.수집하는 개인정보의 항목 및 수집방법</h2>
              가. 수집하는 개인정보의 항목
              <br />
              회사는 회원가입, 원활한 고객상담, 서비스 제공을 위해 아래와 같은
              최소한의 개인정보를 필수항목으로 수집하고 있습니다.
              <br />
              -필수항목 :관리자 이름, 회사이름, 회사 전화번호, 이메일 주소(가입
              인증 정보 또는 기본 입력 정보용), 휴대전화번호(가입 인증 정보),
              관리자 계정정보 (아이디, 비밀번호)
              <br />
              회사와 계약관계에 있는 제휴회사를 통한 서비스 이용 시 계약
              과정에서 아래와 같은 개인정보를 수집합니다.
              <br />
              -필수항목: 관리자 정보(성명, 계정정보, 이메일 주소, 전화번호)
              <br />
              또한 서비스 이용과정이나 사업처리 과정에서 아래와 같은 정보들이
              추가로 수집될 수 있습니다. -거래정보: 개인의 경우
              생년월일(정기결제에 한함), 신용카드정보(신용카드번호, 유효기간,
              비밀번호 앞 두 자리), 세금계산서 발행을 위한 회계 담당자
              정보(이름, 연락처, 이메일 주소) 등
              <br />- IP Address, 쿠키, 방문 일시, 서비스 이용 기록, 불량 이용
              기록 등
              <br />
              -필수항목 : 도메인 정보, 회사(단체)명, 대표자명, 대표주소,
              대표번호(유선), 사업자 등록번호, 법인 등록번호, 고유번호
              <br />- 선택항목 : 팩스번호
              <br />
              <br />
              나. 개인정보 수집방법
              <br />
              회사는 다음과 같은 방법으로 개인정보를 수집합니다.
              <br />– 홈페이지, 서면양식, 팩스, 전화, 상담 게시판, 이메일,
              이벤트 응모, 배송요청
              <br />– 협력회사로부터의 제공
              <br />– 생성정보 수집 툴을 통한 수집
              <br />
              <br />
              <br />
              <h2>2.개인정보의 수집 및 이용목적</h2>
              가. 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산
              <br />
              콘텐트 제공, 특정 맞춤 서비스 제공, 물품배송 또는 청구서 등 발송,
              본인확인, 구매 및 요금 결제, 요금추심
              <br />
              <br />
              나. 회원관리
              <br />
              회원제 서비스 제공, 개인식별, 이용약관 위반 회원에 대한 이용제한
              조치, 서비스의 원활한 운영에 지장을 미치는 행위 및 서비스 부정이용
              행위 제재, 가입의사 확인, 가입 및 가입횟수 제한, 만14세 미만
              아동의 개인정보 수집 시 법정 대리인 동의여부 확인, 추후 법정
              대리인 본인확인, 분쟁 조정을 위한 기록보존, 불만처리 등 민원처리,
              고지사항 전달, 회원탈퇴 의사의 확인
              <br />
              <br />
              다. 신규 서비스 개발 및 마케팅•광고에의 활용
              <br />
              신규 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스
              제공 및 광고 게재, 서비스의 유효성 확인, 이벤트 정보 및 참여기회
              제공, 광고성 정보 제공, 접속빈도 파악, 회원의 서비스이용에 대한
              통계
              <br />
              <br />
              <br />
              <h2>3.개인정보의 공유 및 제공</h2>
              회사는 이용자들의 개인정보를 ‘2. 개인정보의 수집목적 및
              이용목적’서 고지한 범위 내에서 사용하며, 이용자의 사전 동의 없이는
              동 범위를 초과하여 이용하거나 원칙적으로 이용자의 개인정보를
              외부에 공개하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.
              <br />
              가. 이용자가 사전에 동의한 경우
              <br />
              <br />
              나. 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와
              방법에 따라 수사기관의 요구가 있는 경우
              <br />
              <br />
              <br />
              <h2>4.개인정보의 보유 및 이용기간</h2>
              이용자의 개인정보는 원칙적으로 해지 전까지 원활한 서비스의 제공을
              위해 보관됩니다. 해지를 하는 경우 데이터 복구요청 등 이용자의
              보호를 위해 해지 후 15일간 보관되며, 15일이 지난 후 즉시
              삭제됩니다. 단, 상법, 전자상거래 등에서의 소비자보호에 관한 법률
              등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는
              관계법령에서 정한 일정한 기간 동안 회원 정보를 보관합니다. 이 경우
              회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은
              아래와 같습니다.
              <br />– 계약 또는 청약철회 등에 관한 기록
              <br />
              보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률
              <br />
              보존 기간 : 5년
              <br />– 대금결제 및 재화 등의 공급에 관한 기록
              <br />
              보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률
              <br />
              보존 기간 : 5년
              <br />
              보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률
              <br />
              보존 기간 : 3년
              <br />– 웹사이트 방문기록
              <br />
              보존 이유 : 통신비밀보호법
              <br />
              보존 기간 : 3개월
              <br />
              <br />
              <br />
              <h2>5. 개인정보 반환 절차 및 방법</h2>
              이용자의 개인정보는 원칙적으로 해지 전까지 원활한 서비스의 제공을
              위해 보관됩니다. 해지를 하는 경우 데이터 복구요청 등 이용자의
              보호를 위해 해지 후 15일간 보관되며, 15일이 지난 후 즉시
              삭제되지만 사용자가 반환을 요청하는 경우 아래와 같이 반환 받을 수
              있습니다.
              <br />
              <br />
              가. 반환 절차
              <br />- 사용자가 서비스 해지 요청 후 7일 이내 반환 요청 시 반환
              <br />
              <br />
              나. 반환 방법
              <br />- 반환 데이터: 사용자 정보
              <br />
              -제공 형태: CSV 파일
              <br />
              <br />
              <br />
              <h2>6. 개인정보 파기 절차 및 방법</h2>
              이용자의 개인정보는 원칙적으로 해지 전까지 원활한 서비스의 제공을
              위해 보관됩니다. 해지를 하는 경우 데이터 복구요청 등 이용자의
              보호를 위해 해지 후 15일간 보관되며, 15일이 지난 후 즉시
              삭제됩니다.
              <br />
              <br />
              가. 파기절차
              <br />- 사용자가 회원가입 등을 위해 입력한 정보 및 채팅, 쪽지,
              파일 내역은 별도의 DB로 옮겨져 내부 방침 및 기타 관련 법령에 의한
              정보 보호 사유에 따라 (보유 및 이용기간 참조) 저장된 후
              파기됩니다.
              <br />- 동 개인정보는 법률에 의한 경우가 아니 고서는 보유되는
              이외의 다른 목적으로 이용되지 않습니다.
              <br />
              <br />
              나. 파기 방법
              <br />- 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여
              파기합니다.
              <br />- 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는
              기술적 방법을 사용하여 삭제합니다.
              <br />
              (1)사용자 정보-해지 요청 15일 이후 관리자 수동 삭제
              <br />
              (2)쪽지, 채팅, 파일- 15일 주기로 자동 삭제
              <br />
              <br />
              <br />
              <h2>7.이용자 및 법정대리인의 권리와 그 행사방법</h2>
              – 이용자 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만
              14세 미만 아동의 개인정보를 조회하거나 수정할 수 있으며, 회사의
              개인정보의 처리에 동의하지 않는 경우 동의를 거부하거나
              가입해지(회원탈퇴)를 요청하실 수 있습니다. 다만, 그러한 경우
              서비스의 일부 또는 전부 이용이 어려울 수 있습니다.
              <br />– 이용자 혹은 만 14세 미만 아동의 개인정보 조회, 수정을
              위해서는 ‘개인정보변경'(또는 ‘회원정보수정’ 등)을,
              가입해지(동의철회)를 위해서는 ‘회원탈퇴’를 클릭하여 직접 열람,
              정정 또는 탈퇴가 가능합니다.
              <br />– 혹은 개인정보보호책임자에게 서면, 전화 또는 이메일로
              연락하시면 지체 없이 조치하겠습니다.
              <br />– 이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는
              정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지
              않습니다. 또한 잘못된 개인정보를 제3 자에게 이미 제공한 경우에는
              정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록
              하겠습니다.
              <br />– 회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는
              삭제된 개인정보는 ‘5. 개인정보의 보유 및 이용기간’에 명시된 바에
              따라 처리하고 그 외의 도로 열람 또는 이용할 수 없도록 처리하고
              있습니다.
              <br />
              <br />
              <br />
              <h2>8.개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항</h2>
              가. 쿠키란?
              <br />– 회사는 개인화되고 맞춤화된 서비스를 제공하기 위해서
              이용자의 정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를
              사용합니다.
              <br />– 쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의
              브라우저에게 보내는 아주 작은 텍스트 파일로 이용자 컴퓨터의
              하드디스크에 저장됩니다. 이후 이용자가 웹 사이트에 방문할 경우 웹
              사이트 서버는 이용자의 하드 디스크에 저장되어 있는 쿠키의 내용을
              읽어 이용자의 환경설정을 유지하고 맞춤화된 서비스를 제공하기 위해
              이용됩니다.
              <br />
              <br />
              나. 회사의 쿠키 사용 목적
              <br />– 회사는 쿠키를 통해 이용자가 선호하는 설정 등을 저장하여
              이용자에게 보다 빠른 웹 환경을 지원하며, 편리한 이용을 위해 서비스
              개선에 활용합니다.
              <br />– 회사는 쿠키를 통해 서비스의 접속 빈도, 방문 시간, 방문
              회수 등을 분석하여 이용자의 취향과 관심분야를 파악하여 서비스
              제공에 활용합니다.
              <br />
              <br />
              다. 쿠키의 설치/운영 및 거부
              <br />– 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서
              이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를
              허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든
              쿠키의 저장을 거부할 수도 있습니다.
              <br />– 다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한
              서비스의 경우, 이용에 어려움이 있을 수 있습니다.
              <br />– 쿠키 설치 허용 여부를 지정하는 방법(Internet Explorer의
              경우)은 다음과 같습니다.
              <br />① [도구] 메뉴에서 [인터넷 옵션]을 선택합니다.
              <br />② [개인정보 탭]을 클릭합니다.
              <br />③ [개인정보처리 수준]을 설정하시면 됩니다.
              <br />
              <br />
              <br />
              <h2>9.개인정보의 기술적/관리적 보호 대책</h2>
              가. 비밀번호 암호화 UCWORKS 이용자의 비밀번호는 암호화되어 저장 및
              관리되고 있어 본인만이 알고 있으며, 개인정보의 확인 및 변경도
              비밀번호를 알고 있는 본인에 의해서만 가능합니다.
              <br />
              <br />
              나. 해킹 등에 대비한 대책 회사는 해킹이나 컴퓨터 바이러스 등에
              의해 회원의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을
              다하고 있습니다.
              <br />
              개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 최신
              백신프로그램을 이용하여 이용자들의 개인정보나 자료가 유출되거나
              손상되지 않도록 방지하고 있으며, 암호화 통신 등을 통하여
              네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다.
              그리고 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고
              있으며, 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든
              기술적 장치를 갖추려 노력하고 있습니다.
              <br />
              <br />
              다. 처리 직원의 최소화 및 교육 회사의 개인정보관련 처리 직원은
              담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여
              정기적으로 갱신하고 있으며, 담당자에 대한 수시 교육을 통하여
              UCWORKS 개인정보처리방침의 준수를 항상 강조하고 있습니다.
              <br />
              <br />
              라. 개인정보보호전담기구의 운영
              <br />
              그리고 사내 개인정보보호전담기구 등을 통하여 UCWORKS
              개인정보처리방침의 이행사항 및 담당자의 준수여부를 확인하여 문제가
              발견될 경우 즉시 수정하고 바로 잡을 수 있도록 노력하고 있습니다.
              단, 이용자 본인의 부주의나 인터넷상의 문제로 ID, 비밀번호 등
              개인정보가 유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지
              않습니다.
              <br />
              <br />
              기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래
              기관에 문의하시기 바랍니다.
              <br />– 개인정보침해신고센터 (privacy.kisa.or.kr/ 국번없이 118)
              <br />– 대검찰청 사이버범죄수사단 (www.spo.go.kr/ 국번없이 1301)
              <br />– 경찰청 사이버안전국 (cyberbureau.police.go.kr/ 국번없이
              182)
              <br />
              <br />
              <br />
              <h2>10.적용범위</h2>
              UCWORKS에 링크되어 있는 다른 웹사이트들이 개인정보를 수집하는
              행위에 대해서는 본 ‘UCWORKS 개인정보처리방침’이 적용되지 않음을
              알려 드립니다. 또한 본 ‘UCWORKS 개인정보처리방침’은 회사와
              이용계약을 체결한 회원에 한해 적용되며, 회원의 관리하에 UCWORKS의
              권한을 부여 받은 구성원에게는 적용되지 않습니다. 구성원에게
              적용되는 개인정보처리방침은 각 회사(단체)의 개인정보
              보호책임자(혹은 개인정보 보호책임자)를 통해 확인하셔야 합니다.
              <br />
              <br />
              <br />
              <h2>11. 고지의 의무</h2>
              현 개인정보처리방침 내용 추가, 삭제 및 수정이 있을 시에는 개정
              최소 7일전부터 홈페이지의 ‘공지사항’을 통해 고지할 것입니다. 다만,
              개인정보의 수집 및 활용, 제3자 제공 등과 같이 이용자 권리의 중요한
              변경이 있을 경우에는 최소 30일 전에 고지합니다.
              <br />– 공고일자 : 2020년 4월 9일
              <br />– 시행일자 : 2020년 4월 9일
            </div>
          </div>
        </section>
      </>
    );
  } else {
    return (
      <>
        <section class="contactsec privacy" style={{ paddingTop: "1.5rem" }}>
          <div class="content" style={{ width: `100%`, maxWidth: "1020px" }}>
            <h5 style={{ fontSize: "2.5rem", marginBottom: "1.3rem" }}>
              UCWORKS Privacy Policy
            </h5>
            <p>
              UCWARE Inc. (the “Company”) complies with the privacy regulations
              of applicable laws, including, without limitation, the Law
              Regarding the Promotion of Information and Communication Network
              Use and Protection of Information, the Privacy Protection Act, the
              Protection of Communications Secrets Act, and the
              Telecommunications Business Act. The Company hereby establishes
              this privacy policy (this “Privacy Policy”) based on the
              applicable laws. The Company will reasonable precautions to
              protect rights of its service users and customers. The Privacy
              Policy herein shall be applied to services provided by the Company
              and the details are as follows:{" "}
            </p>
            <h4>
              Chapter 1. Personal Information to be Collected and Collection
              Methods
            </h4>
            <p>Article 1 (Personal Information to be Collected) </p>
            <p>
              ① The Company collects the following personal information that is
              necessary for creating accounts, providing customer consultation,
              and allowing customers to access and use various services:{" "}
            </p>
            <p>
              1. For Individual Member Sign-Up - Mandatory Information: Email
              address, password, name, Birthday, mobile phone number, country,
              address{" "}
            </p>
            <p>
              2. For Corporate Member Sign-Up - Mandatory Information: Email
              address, password, name of business, country, phone number,
              address of business (For residents in Korea) name of
              representative, business registration number, copy of business
              license{" "}
            </p>
            <p>
              3. For Registering an Automatic Payment - Credit card: Date of
              birth (business registration number for businesses), credit card
              number, credit card expiration date - (For the Korean corporate
              members) Bank transfer: Bank name, date of birth (business
              registration number for businesses), account number{" "}
            </p>
            <p>
              ② The following information is automatically created or collected
              while using the service or processing applications for service: -
              IP Address, cookies, connection log, visit date, service use
              records, defective use records, payment records{" "}
            </p>
            <p>
              ③ The following information is collected for users of certain
              services of the Company. Such information is retained only while
              using the specific service:{" "}
            </p>
            <p>Q&A –Email address, Mobile Phone Number </p>
            <p>
              Cash receipt application - Mobile phone number or cash receipt
              card number{" "}
            </p>
            <p>
              When being added as a notification recipient - Name, mobile phone
              number, email address{" "}
            </p>
            <p>
              For two-factor authentication with SSL VPN - Name, Email address{" "}
            </p>
            <p>
              For mobile phone authentication - Name, date of birth, gender,
              duplication information(DI), connection Information (CI), mobile
              phone number and carrier (in the case of mobile phone
              authentication), nationality or residential information
              (Korean/foreigner){" "}
            </p>
            <p>Article 2 (Personal Information Collection Methods)</p>
            <p>
              ① The Company collects personal information via the following
              methods:{" "}
            </p>
            <p>
              1. Website, written document, fax, phone, customer message boards,
              email, collected at events or seminars{" "}
            </p>
            <p>2. Data received from partner organizations </p>
            <p>3. Collection of data via automated tools Chapter </p>
            <h4>2. Purposes for Collecting and Using Personal Information</h4>
            <p>
              Article 3 (Performance of Service Agreement and Settlement of Fees
              for Services Provided)
            </p>
            <p>
              ① The Company collects personal information to provide customers
              with contents and customized services, deliver goods, issue
              invoices, authenticate user identities, process purchases and fee
              payments, and to collect fees.{" "}
            </p>
            <p>Article 4 (Member Management) </p>
            <p>
              ① The Company collects personal information to verify user
              identities for using member services or to comply with identity
              check requirement under applicable laws, to confirm individual
              identification, to prevent illegal use and unauthorized use, to
              confirm account registrations, to prevent duplicate registrations,
              to validate identities of legal representatives, to archive
              records for dispute resolution, for civil complaint processing,
              and to provide notices.{" "}
            </p>
            <p>Article 5 (New Service Development and Marketing) </p>
            <p>
              ① The Company collects personnel information for purposes of
              developing new services and customizing services, providing
              existing services and posting advertising based on user
              demographics, validating services, providing event and advertising
              information, and providing opportunities for customer
              participation, understanding usage patterns, and developing
              statistics on customer use of services
            </p>
            <h4>Chapter 3. Period of Personal Information Retention and Use</h4>
            <p>
              In principle, the Company deletes or destroys customers’ personal
              information without delay once the purpose for collecting and
              using the personal information has been satisfied. However, the
              following information will be retained for a specified period of
              time due to the following reasons:{" "}
            </p>
            <p>
              Article 6 (Reason for Information Retention due to Company
              Policies){" "}
            </p>
            <p>
              The Record of illegal use retains 1 year for the reason of the
              prevention of illegal use. Inquiry/partnership details (only if
              customer gives express consent) retains 6 months for the reason of
              the customer consultation processing.
            </p>
            <p>
              Article 7 (Reasons for Retaining Information According to
              Applicable Laws and Regulations){" "}
            </p>
            <p>
              The Company retains users’ information for periods designated in
              applicable laws and regulations, when information retention is
              required thereunder, including, without limitation, the Commercial
              Law and Act on the Consumer Protection in Electronic Commerce. In
              this case, the Company will use the information for the purposes
              specified in the relevant acts and regulations. The retention
              periods for these purposes are as follows:
            </p>
            <table></table>
            <h4>
              Chapter 4. Personal Information Destruction Procedures and Methods
            </h4>
            <p>
              In principle, the Company deletes or destroys customers’ personal
              information without delay once the purpose for collecting and
              using the personal information has been satisfied. The following
              describes the procedures and methods used by the Company to
              destroy personal information.{" "}
            </p>
            <p>Article 8 (Destruction Procedure)</p>
            <p>
              ① The information that the user has submitted for account sign-up
              will be moved to a separate database (or a separate document
              container for paper files) and destroyed after retention
              requirements related to information security in accordance with
              Company policies and applicable laws are fulfilled (refer to the
              period of retention and use).{" "}
            </p>
            <p>
              ② The retained personal information will not be used for any
              purpose other than for retention as required by applicable laws
              and Company policies, unless otherwise required by law.{" "}
            </p>
            <p>
              ③ For reference, the personal information of the members who have
              not used the service for one year is separately stored and managed
              in accordance with the ‘Personal Information Validity Period
              Policy’.
            </p>
            <p>Article 9 (Destruction Method)</p>
            <p>
              ① Personal information printed on paper will be destroyed by
              shredding or incinerating.{" "}
            </p>
            <p>
              ② Personal information saved in electronic formats will be deleted
              via a technical deletion method that destroys data permanently.{" "}
            </p>
            <h4>Chapter 5. User and Legal Representative Rights </h4>
            <p>Article 10 (Rights and Methods of Exercising those Rights)</p>
            <p>
              ① Users and their legal representatives can retrieve or modify
              personal information at any time or request cancellation of
              membership.{" "}
            </p>
            <p>
              ② To retrieve or modify personal information, the user must
              complete the identity verification procedure on the relevant page
              of the Company’s website. To cancel membership (withdraw consent),
              the user must complete the identity verification procedure on the
              relevant page of the Company’s website.{" "}
            </p>
            <p>
              ③ In the event that a user has requested to correct personal
              information, the relevant personal information will not be used
              until it is updated. In addition, if incorrect personal
              information is already provided to a third party, the change will
              be notified to the third party without delay.{" "}
            </p>
            <p>
              ④ The Company will process deletion without delay if a user
              contacts the Company’s personal information manager via writing,
              phone, or email.{" "}
            </p>
            <p>
              ⑤ The Company handles the personal information canceled or deleted
              by the user or a legal representative as specified under Chapter 4
              above to prevent viewing or using information for other purposes.{" "}
            </p>
            <h4>
              Chapter 6. Installation, Operation, and Refusal of Automatic
              Personal Information Collection Devices
            </h4>
            <p>Article 11 (Definition of Cookie)</p>
            <p>
              ① The Company uses digital files called ‘cookie’ to save and
              retrieve data for the purpose of providing personalized and
              customized services.{" "}
            </p>
            <p>
              ② Cookie is a small text file that is sent to the user’s browser
              by the server that hosts the Company website and stored to the
              hard disk of the user’s computer. When the user visits the Company
              website again, the website server reads the contents of the cookie
              and uses it to provide customized services.{" "}
            </p>
            <p>
              ③ The cookie does not collect personally identifiable information
              automatically or actively and the user can reject the use of
              cookies or delete saved cookies at any time.{" "}
            </p>
            <p>Article 12 (Purpose of Using Cookies) </p>
            <p>
              Cookies are used to provide optimized and customized information,
              including advertising, to the user by collecting data about the
              user’s usage patterns, web searches, and time spent on various
              activities.{" "}
            </p>
            <p>Article 13 (Cookie Installation, Operation, and Rejection) </p>
            <p>
              ① Users have the right to choose whether to install cookies or
              not. Users can allow use of all cookies, opt to provide
              confirmation each time a cookie is saved, or reject the use of
              cookies entirely by specifying options in the web browser.{" "}
            </p>
            <p>
              ② However, if the user rejects cookie usage, the user may
              experience less than optimal performance for services requiring
              login.{" "}
            </p>
            <p>
              ③ Follow the steps below to allow or disable cookie installation
              in Internet Explorer:{" "}
            </p>
            <p>1. Select Tools Internet Options menu. </p>
            <p>2. Click the Privacy tab. </p>
            <p>3. Change privacy settings. </p>
            <h4>
              Chapter 7. Technical and Administrative Protection of the Personal
              Information
            </h4>
            <p>
              The Company takes the following technical and administrative
              measures to secure the safety of user’s personal information and
              prevent loss, theft, disclosure, alteration, or distortion.{" "}
            </p>
            <p>Article 14 (Encrypting Passwords)</p>
            <p>
              Member passwords are encrypted for storage and management and only
              the user knows the password. As a result, only the user who knows
              the password can access the account to view or modify the personal
              information.{" "}
            </p>
            <p>Article 15 (Measures against Hacking or Other Attacks) </p>
            <p>
              The Company takes reasonable precautions to prevent any leak or
              damage to user’s personal information due to hacking attacks or
              computer viruses. The Company backs up data frequently to prevent
              damage to personal information. The Company uses up-to-date
              vaccine programs to prevent leakage or damage to personal
              information or data. The Company uses encrypted networks to allow
              safe transmission of personal information on the network. The
              Company also controls unauthorized access using an intrusion
              prevention system. The Company takes reasonable precautions
              operate and maintain all possible technical systems and equipment
              regularly to run systems securely.{" "}
            </p>
            <p>
              Article 16 (Minimization of Personal Information Handling
              Employees and Their Training){" "}
            </p>
            <p>
              Personal information handling is limited to the persons in charge
              only. For this purpose, a separate password is given and updated
              on a regular basis. Compliance with the privacy policy is always
              emphasized through frequent training of the persons in charge.{" "}
            </p>
            <p>Article 17 (Operation of the Dedicated Privacy Organization</p>
            <p>
              We are making efforts to make corrections and adjustments
              immediately if a problem is discovered by checking the fulfillment
              of privacy policy and compliance of the persons in charge through
              an in-house privacy task force, etc. However, the Company shall
              not bear responsibility for damages that are not caused by the
              negligence of the Company, such as the user’s own negligence or
              accidents in areas not managed by the Company when the Company has
              fulfilled the obligations to protect personal information.{" "}
            </p>
            <h4>Chapter 9. Other</h4>
            <p>Article 18 (Other) </p>
            <p>
              If you need to report on other infringements of personal
              information or if you require consultation, please contact the
              agencies below.{" "}
            </p>
            <p>
              - Personal Information Infringement Report Center
              (http://privacy.kisa.or.kr / 118 without area code)
            </p>
            <p>
              - Cyber Investigation Bureau of the Supreme Public Prosecutor’s
              Office (http://www.spo.go.kr / 1301 without area code){" "}
            </p>
            <p>
              - Cyber Safety Bureau of the National Policy Agency
              (http://www.ctrc.go.kr / 182 without area code)
            </p>
            <p>Article 19 (Exceptions) </p>
            <p>
              Please be noted that this privacy policy is not applicable to
              personal information collected by the website linked to the
              Company’s Internet service.{" "}
            </p>
            <p>Article 22 (Obligation to Notify) </p>
            <p>
              If any details are added, deleted, or corrected in the privacy
              policy, we will notify users through the relevant menu of the
              website at least seven days prior to the amendment. However, we
              will make an announcement at least thirty days in advance if there
              is an important change in user’s rights, such as collection and
              use of personal information or providing to a third party.{" "}
            </p>
            <p>Article 23 (Language) </p>
            <p>
              All communications and notices made or given pursuant to the Terms
              shall be in the Korean language. Provided, that, the Company may
              provide English translation of the communications and notices for
              convenience purpose only. In case the Company provides an English
              language translation of the notices and/or the Terms, the Korean
              text of such notices and/or the Terms will control in case of any
              conflict. Notwithstanding the foregoing, however, the Customer may
              communicate or give notice to the Company in either the Korean or
              English language.{" "}
            </p>
            <p tyle={{ fontStyle: "italic" }}>
              This Privacy Policy shall take effect from September06, 2019.
            </p>
          </div>
        </section>
      </>
    );
  }
}

export default PrivatePolicy;
