import styled from "styled-components";
import React, { useEffect } from "react";
import Text from "./Text";
import { useTranslation } from "react-i18next";

export default function Tab({
  list,
  current,
  callback,
  color = "gray",
  active,
  activeCallback,
}) {
  const { t } = useTranslation();

  return (
    <Container>
      <F1 color={color} active={active}>
        {list.map((v) => (
          <li
            //seelcted만 active 주면 됌
            className={v.key === current.key && `active`}
            onClick={() => {
              callback(v);
            }}
            onMouseOver={activeCallback}
          >
            <Text key={v.key} maxContent color={color}>
              {t(v.value)}
            </Text>
          </li>
        ))}
      </F1>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  overflow: auto;
`;

const F1 = styled.ul`
  display: flex;
  padding: 1rem 0;
  /* border-bottom: 0.1rem solid #ddd; */
  list-style-type: none;
  margin: 0.5rem;
  width: fit-content;

  li {
    position: relative;
    &.active {
      * {
        &,
        &:focus,
        &:hover {
          border-width: 0;
          cursor: pointer;
          border: none;
          color: ${(props) => props.color};
          background: transparent;
        }
        &:after {
          transform: scale(1);
        }
      }
    }
    &:hover {
      * {
        &:after {
          transform: scale(1);
        }
      }
    }

    * {
      border: none;

      margin: 0px 10px;

      &:hover {
        cursor: pointer;
        border: none;
        color: ${(props) => props.color};
        background: transparent;
      }

      &:after {
        content: "";
        background: ${(props) => props.color};
        height: ${(props) => props.active && `0.2rem`};
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -1rem;
        transition: all 250ms ease 0s;
        transform: scale(0);
      }
    }
  }
`;
