import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { SERVER_URI, header } from "../../assets/utils/HttpHandler";
import styled from "styled-components";
import {
  Button,
  Basement,
  useSelect,
  Select,
  Text,
  delay,
} from "../../assets/common";
import { useDispatch } from "react-redux";
import { configActions } from "../../assets/common/reducer/config";
import { Input, useInput } from "../../assets/common";
import { ruleSchema } from "../../assets/common/util/ruleSchema";
import RequestPay from "../../assets/utils/RequestPay";
import useMeasure from "react-use-measure";
import { useHistory } from "react-router-dom";
import { ResizeObserver } from "@juggle/resize-observer";

export default function PaymentInformation({
  setStep,
  setPaymentInfo,
  setSuccess,
  paymentInfo,
  paymentInfo: {
    wishPlan,
    wishPriceRestCode,
    currentPlan,
    currentMember,
    currentPeriod,
    maxMember,
    totalPrice,
    currentMemberPrice, // 인당 잔여금액
    currentStorage,
    licenseType,
    workspaceName,
    workspaceRestCode,
  },
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [nomalPrice, setNomalPrice] = useState(0); // 결제 금액
  const [additionalStoragePrice, setAdditionalStoragePrice] = useState(0); // 추가
  const dispatch = useDispatch();
  const [initialKey, periodList] = createPeriodList(currentPeriod);
  const [period, setPeriod] = useSelect(initialKey, periodList);
  const [wishMember, setWishMember] = useState(currentMember + 1);
  const isUpgrade =
    licenseType !== "New" && licenseType !== "Free" ? true : false;
  const deductionPrice =
    currentMember && currentMemberPrice
      ? currentMember * currentMemberPrice * 1000
      : 0; // 차감
  const currentStorageBlock = isUpgrade
    ? currentStorage.additionalStorageSize / 10
    : 0; // 추가 스토리지 블럭갯수
  // 스토리지 잔여금 = 남은 기간동안의 블럭당 가격 * 블럭 수
  const currentStoragePrice = isUpgrade
    ? currentStorage.pricePerBlock * currentStorageBlock
    : 0;
  const [adminNameValid, setAdminNameValid, adminName, setAdminName] = useInput(
    paymentInfo.adminName,
    ruleSchema.adminName.rule,
    null
  );
  const [
    adminEmailValid,
    setAdminEmailValid,
    adminEmail,
    setAdminEmail,
  ] = useInput(paymentInfo.adminEmail, ruleSchema.adminEmail.rule, null);

  const [ref, bounds] = useMeasure({ polyfill: ResizeObserver });

  useEffect(() => {
    dispatch(configActions.config.expand.on(bounds.height));

    return () => {
      dispatch(configActions.config.expand.off());
    };
  }, [bounds.height]);

  // radio value(0, 1, 2)가 바뀔때마다 결제 기간 변동
  useEffect(() => {
    setPaymentInfo((prev) => ({
      ...prev,
      wishPeriod: period.key,
      wishPriceRestCode: currentPlan.priceList.filter(
        (v) => v.cycle === Number(period.key)
      )[0].restCode,
    }));

    calcPrice();
  }, [period]);

  useEffect(() => {
    setPaymentInfo((prev) => ({
      ...prev,
      wishMember,
    }));

    calcPrice();
  }, [wishMember]);

  const calcPrice = () => {
    // 스토리지 금액 - 스토리지 잔여금
    const storagePrice =
      (currentStorageBlock * 2 * period.key - currentStoragePrice) * 1000;

    setAdditionalStoragePrice(storagePrice);
    // 정가 : 인당월사용금액 * 멤버수 * 사용개월 * 금액단위
    setNomalPrice(
      currentPlan.priceList[0].price * wishMember * period.key * 1000
    ); //인당 할인금액 * 인원수 * 개월

    // 할인이 포함된 정가 : 인당 할인된 월사용금액 * 멤버수 * 사용개월 * 금액단위
    let amount =
      currentPlan.priceList[Math.floor(Number(period.key / 6))].price *
      wishMember *
      1000 *
      period.key;

    if (wishPlan === "Enterprise") {
      amount = 0;
    }

    if (wishPlan === "Enterprise") {
      setPaymentInfo((prev) => ({
        ...prev,
        totalPrice: amount + storagePrice, // 할인포함 정가 - 차감액
      }));
    } else {
      setPaymentInfo((prev) => ({
        ...prev,
        totalPrice: amount - deductionPrice + storagePrice, // 할인포함 정가 - 차감액
      }));
    }

  };

  const pay = (param) => {
    setLoading(true);

    RequestPay(param, async (res, data = undefined) => {
      if (res) {
        try {
          await axios.post(`${SERVER_URI}/api/order/license`, data, header());
          setSuccess(true);
          setStep((prev) => prev + 1);
        } catch (error) {
          setStep((prev) => prev + 1);
        }
      } else {
        setStep((prev) => prev + 1);
      }
    });
  };

  const handleSubmit = () => {
    let valid = true;
    if (!adminNameValid || !adminName.length) {
      setAdminNameValid(false);
      valid = false;
    }
    if (!adminEmailValid || !adminEmail.length) {
      setAdminEmailValid(false);
      valid = false;
    }

    if (valid) {
      console.log('paymentInfo', paymentInfo)
      const skip = async () => {
          let variables;
          if(wishPlan === `Free`) {
            variables= {
              priceRestCode: `${paymentInfo.freePriceResetCode}`,
              workspaceRestCode: `${workspaceRestCode}`,
              orderFlag: "Y",
              count: wishMember,
            };
          } else {
            variables= {
              priceRestCode: `${paymentInfo.wishPriceRestCode}`,
              workspaceRestCode: `${workspaceRestCode}`,
              orderFlag: "Y",
            };
          }

          setPaymentInfo((prev) => ({
            ...prev,
            wishPlan: wishPlan,
            wishPeriod: 1,
            wishMember,
          }));
          try {
            await delay();
            axios.post(`${SERVER_URI}/api/order/license`, variables, header());
            setSuccess(true);
            setStep(2);
          } catch (error) {
            setSuccess(false);
            setStep(2);
          }
        };
      if (wishPlan === `Free`) {
        skip();
      } 
      else if (wishPlan === `Enterprise`) {
        skip();
      }
      else {
        pay(param);
      }
    }
  };

  const handleCancle = () => {
    history.push(`/workspace/detail/${workspaceRestCode}`);
  };

  const param = {
    imp: {
      pg: "html5_inicis",
      pay_method: "card",
      name: `${wishPlan}`,
      amount: Number(totalPrice), // 0 으로 설정하여 빌링키 발급만 진행합니다.
      buyer_email: `${adminEmail}`,
      buyer_name: `${adminName.trim()}`,
      currency: `KRW`,
      language: `ko`,
      m_redirect_url: `${SERVER_URI}/payment/license/complete`,
    },
    portal: {
      price: Number(totalPrice),
      orderFlag: "N",
      count: Number(`${wishMember}`),
      workspaceRestCode: `${workspaceRestCode}`,
      priceRestCode: `${wishPriceRestCode}`,
      userOrderPg: {
        name: `${wishPlan}`,
        price: Number(totalPrice),
        currency: `KRW`,
        buyerName: `${adminName}`,
        buyerEmail: `${adminEmail.trim()}`,
      },
    },
  };

  return (
    <>
      <Basement>
        <Text big color="black">
          {t("paymentInfo.information")}
        </Text>
        <Input
          type="text"
          label={t("paymentInfo.teamName")}
          current={workspaceName}
          disabled
        />
        <Input
          type="text"
          label={t("paymentInfo.planInfo")}
          current={wishPlan}
          disabled
        />

        <Input
          type="text"
          label={t(ruleSchema.adminName.label)}
          errorMsg={t(ruleSchema.adminName.errorMsg)}
          current={adminName}
          callback={(next) => {
            setAdminName(next);
          }}
          valid={adminNameValid}
        />
        <Input
          type="text"
          label={t(ruleSchema.adminEmail.label)}
          errorMsg={t(ruleSchema.adminEmail.errorMsg)}
          current={adminEmail}
          callback={(next) => {
            setAdminEmail(next);
          }}
          valid={adminEmailValid}
        />
        {console.log('wishMember', wishMember)}
        {console.log('wishPlan', wishPlan === "Enterprise")}

        <Input
          disabled={wishPlan === "Enterprise" ? true : false}
          type="number"
          label={`${t("paymentInfo.wishMemberNumber")} (${t(
            "paymentInfo.current"
          )}: ${currentMember})`}
          current={wishMember}
          callback={(next) => setWishMember(next)}
          valid={true}
          min={currentMember}
          max={maxMember}
          rangeSensitive={true}
        />

        <Select
          list={wishPlan === `Free` ? [periodList[0]] : periodList}
          label={t("_form.paymentPeriod")}
          current={period}
          callback={(next) => {
            setPeriod(next);
          }}
        />
      </Basement>
      <F1 ref={ref}>
        <F2>
          <F3>
            {wishPlan !== "Enterprise" &&

              <>
                {isUpgrade ? (
                  <>
                    <F4>
                      <Text small textAlign="right">
                        {t("paymentInfo.price")}
                      </Text>
                      <TextExtended mid color="black">{`₩${Number(
                        nomalPrice
                      ).toLocaleString()}`}</TextExtended>
                    </F4>
                    <F4>
                      <Text small textAlign="right">
                        {t("paymentInfo.deductPrice")}
                      </Text>
                      <TextExtended mid color="black">{`-₩${Number(
                        deductionPrice
                      ).toLocaleString()}`}</TextExtended>
                    </F4>
                    <F4>
                      <Text small textAlign="right">
                        {t("paymentInfo.additionalStoragePrice")}
                      </Text>
                      <TextExtended mid color="black">
                        {`₩${Number(additionalStoragePrice).toLocaleString()}`}
                      </TextExtended>
                    </F4>
                  </>
                ) : (
                  <>
                    <F4>
                      <Text small textAlign="right">
                        {t("paymentInfo.price")}
                      </Text>
                      <TextExtended mid color="black">{`₩${Number(
                        nomalPrice
                      ).toLocaleString()}`}</TextExtended>
                    </F4>

                    <F4>
                      <Text small textAlign="right">
                        {t("paymentInfo.discountPrice")}
                      </Text>
                      <TextExtended mid color="black">
                        {`₩${Number(totalPrice - nomalPrice).toLocaleString()}`}
                      </TextExtended>
                    </F4>
                    <F4>
                      <Text small textAlign="right">
                        {t("paymentInfo.additionalStoragePrice")}
                      </Text>
                      <TextExtended mid color="black">
                        {`₩${Number(additionalStoragePrice).toLocaleString()}`}
                      </TextExtended>
                    </F4>
                  </>
                )}
              </>
            }

            <F4>
              <Text small textAlign="right">
                {t("paymentInfo.payPrice")}
              </Text>
              <TextExtended mid color="black">{`₩${Number(
                totalPrice
              ).toLocaleString()}`}</TextExtended>
            </F4>
          </F3>
          <F3_1>
            <Button onClick={handleSubmit} loading={loading}>
              {t("paymentInfo.checkOut")}
            </Button>
            <Button onClick={handleCancle}>{t("_common.cancel")}</Button>
          </F3_1>
        </F2>
      </F1>
    </>
  );
}

const createPeriodList = (currentPeriod) => {
  let list = [];
  let initialKey = 1;

  if (currentPeriod) {
    if (currentPeriod <= 1) {
      list.push({ key: `1`, value: `1 month` });
      initialKey = 1;
    }
    if (currentPeriod <= 6) {
      list.push({ key: `6`, value: `6 months` });
      initialKey = 6;
    }
    if (currentPeriod <= 12) {
      list.push({ key: `12`, value: `1 year` });
      initialKey = 12;
    }
  } else {
    initialKey = 1;
    list.push({ key: `1`, value: `1 month` });
    list.push({ key: `6`, value: `6 months` });
    list.push({ key: `12`, value: `1 year` });
  }
  return [initialKey, list];
};

const F1 = styled.div`
  /* height: 23rem; */
  /* height: 230px; */
  bottom: 0;
  width: 100%;
  position: fixed;
  background-color: #fff;
  border-top: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const F2 = styled.div`
  width: 970px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const F3 = styled.div`
  /* width: 90%; */
  display: flex;
  flex-direction: column;
  justify-content: baseline;
`;

const F3_1 = styled.div`
  margin: 1rem 1rem 0 1rem;
  padding: 0.5rem 0;
`;

const F4 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const TextExtended = styled(Text)`
  padding: 0 0.5rem;
`;
