import React, { useEffect } from "react";
import {
  sortableContainer,
  sortableElement,
  arrayMove,
  sortableHandle,
} from "react-sortable-hoc";
import { MenuOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

export default function Table({
  payload,
  setPayload,
  column,
  draggable,
  small,
  transparent,
  shadow,
  ...rest
}) {
  const { t, i18n } = useTranslation();
  const SortableContainer = sortableContainer(({ children }) => {
    return children;
  });

  const DragHandle = sortableHandle(() => <MenuOutlined />);

  useEffect(() => {
    console.log(column);
  }, []);

  const renderCell = ([k, v], i, list) => {
    const currentColumn = column[i];
    const label = currentColumn.label;
    const type = currentColumn.type;
    const callback = () => {
      if (typeof currentColumn.callback === `function` && v) {
        currentColumn.callback([k, v], i, list);
      }
    };

    if (i === 4 || i === 3) {
      const planValue = list[0][1];

      console.log(`planvalue: `, planValue);

      if (planValue === `Enterprise`) {
        return (<Cell >  
          <CellValue
          >
          </CellValue>
        </Cell>)
      }
    }
    return (
      <>
        {type !== `hidden` && (
          <Cell label={label} empty={!v && true}>
            {i === 0 && draggable && <DragHandle />}

            <CellValue
              onClick={callback}
              small={small}
              button={type === `button` && true}
            >
              {i18n.exists(v) ? t(v) : v}
            </CellValue>


          </Cell>
        )}
      </>
    );
  };

  const SortableItem = sortableElement(({ v }) => (
    <Row small={small} transparent={transparent}>
      {Object.entries(v).map(([k, v], i, list) => renderCell([k, v], i, list))}
    </Row>
  ));

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    setPayload((prev) => arrayMove(prev, oldIndex, newIndex));
  };

  return (
    <SortableContainer useDragHandle lockAxis="y">
      <Wrapper shadow={shadow}>
        <Container>
          <Header transparent={transparent} small={small}>
            {column
              .filter((v) => v.type !== `hidden`)
              .map((v, i) => (
                <Cell>
                  <CellValue small={small}>
                    {i === 0 && draggable && <span role="img" />}

                    {v.label}
                  </CellValue>
                </Cell>
              ))}
          </Header>

          {payload.map((v, i) => (
            <SortableItem key={i} index={i} v={v} />
          ))}
        </Container>
      </Wrapper>
    </SortableContainer>
  );
}

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: ${(props) =>
    props.shadow &&
    `0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)`};
`;

const Container = styled.div`
  width: 100% !important;
  display: table;
  margin: 0;
  table-layout: fixed;

  @media screen and (max-width: 768px) {
    display: block;
  }
`;

const Cell = styled.div`
  display: ${(props) => (props.empty ? `none` : `table-cell`)};
  width: 100% !important;
  text-align: center;
  vertical-align: center;

  @media screen and (max-width: 768px) {
    display: ${(props) => (props.empty ? `none` : `block`)};

    &:not([label=""]):before {
      font-family: Poppins-Bold;

      color: #808080;
      line-height: 1.2;
      text-transform: uppercase;
      font-weight: unset !important;

      margin-bottom: 13px;
      content: attr(label);
      min-width: 98px;
      display: block;
    }
  }
`;

const CellValue = styled.div`
  background-color: ${(props) => props.button && `#42a0eb`};
  color: ${(props) => props.button && `#fff`};
  padding: ${(props) => props.button && `6px 15px`};
  border-radius: ${(props) => props.button && `50px`};

  font-size: ${(props) => (props.small ? `1.2rem` : `1.6rem`)};
  &:hover {
    cursor: ${(props) => props.button && `pointer`};
  }
`;

const Row = styled.div`
  width: 100% !important;
  display: table-row;
  background-color: ${(props) => (props.transparent ? `transparent` : `#fff`)};

  @media screen and (max-width: 768px) {
    display: block;
    padding: ${(props) =>
    props.small ? `5px 15px 5px 0px` : `30px 15px 18px 0px`};
    margin: 0;
    border-bottom: 1px solid #f2f2f2;
  }

  ${Cell} {
    font-family: Poppins-Regular;
    color: #666666;
    line-height: 1.2;
    font-weight: unset !important;
    padding: ${(props) => (props.small ? `5px` : `20px`)};
    border-bottom: 1px solid #f2f2f2;

    @media screen and (max-width: 768px) {
      border: none;
      padding-left: 30px;
      padding: ${(props) => (props.small ? `5px 30px` : `16px 30px`)};
      font-family: Poppins-Regular;
      color: #555555;
      line-height: 1.2;
      font-weight: unset !important;

      &:first-child {
        padding-left: 30px;
      }
    }
  }

  &:last-child {
    ${Cell} {
      border-bottom: 0px;
    }
  }
`;

const Header = styled.div`
  color: #ffffff;

  background-color: ${(props) =>
    props.transparent ? `transparent` : `#42A0EB`};
  display: table-row;
  @media screen and (max-width: 768px) {
    padding: 0;
    height: 0px;
  }

  ${Cell} {
    font-family: Poppins-Regular;
    color: #fff;
    line-height: 1.2;
    font-weight: unset !important;
    padding: ${(props) => (props.small ? `5px` : `20px`)};

    @media screen and (max-width: 768px) {
      display: none;
    }
  }
`;
