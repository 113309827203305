import React from "react";
import styled from "styled-components";
import banner2 from "../../assets/images/new/banner2.jpg";
import mbanner1 from "../../assets/images/new/mbanner1.png";
import { Text } from "../../assets/common";
import { useMediaQuery } from "@react-hook/media-query";

export default function BlockOne() {
  const match = useMediaQuery(`(max-width: 768px)`);
  return (
    <>
      <Container>
        <F1>
          <Text
            super={match ? false : true}
            big={match ? true : false}
            color="#fff"
            textAlign={match ? `center` : `left`}
            fontWeight="500"
          >{`Global No.1
            UC & C SW Provider
            Lasts 100-Years`}</Text>

          <Text
            color="#fff"
            fontWeight="500"
          >{`Based on 100-years lasting technology`}</Text>
          <Text
            color="#fff"
            fontWeight="500"
          >{`Our members gathered with strong solidarity`}</Text>
          <Text
            color="#fff"
            fontWeight="500"
          >{`To make clean and safe UC & C world.`}</Text>
        </F1>
      </Container>
    </>
  );
}

const Container = styled.div`
  background-image: url(${banner2});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  height: 100vh;
  color: white;

  @media (max-width: 768px) {
    background-image: url(${mbanner1});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    height: 100vh;
  }
`;

const F1 = styled.div`
  @media (min-width: 768px) {
    padding: 10vh 0 0 10vh;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5vh 0;
  }
`;
