import React from "react";
import { Text } from "../../../assets/common";
import { useMediaQuery } from "@react-hook/media-query";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

export default function Title(props) {
  const { title, src } = props;
  const { t } = useTranslation();
  const match = useMediaQuery(`(max-width: 768px)`);

  return (
    <Background src={src}>
      <Text
        super={match ? false : true}
        big={match ? true : false}
        color="#fff"
      >
        {title}
      </Text>
    </Background>
  );
}

const Background = styled.div`
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
