import React from "react";
import styled from "styled-components";

export default function Text({ children, ...rest }) {
  return <Container {...rest}>{children}</Container>;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  user-select: none;
  white-space: pre-line;
  letter-spacing: ${(props) =>
    props.letterSpacing && `${props.letterSpacing}rem`};
  text-align: ${(props) => (props.textAlign ? props.textAlign : `center`)};
  width: ${(props) => props.maxContent && `max-content`};
  text-decoration: ${(props) => props.textDecoration && props.textDecoration};
  max-width: ${(props) => props.maxWidth && props.maxWidth};

  font-size: ${(props) =>
    props.big
      ? `3rem`
      : props.small
      ? `1.2rem`
      : props.mid
      ? `2.2rem`
      : props.super
      ? `5rem`
      : `1.6rem`};

  font-style: ${(props) => props.fontStyle && props.fontStyle};
  font-weight: ${(props) => props.fontWeight && props.fontWeight};

  color: ${(props) => (props.color ? props.color : `gray`)};

  cursor: ${(props) => props.hover && `pointer`};

  & a {
    /* color: ${(props) => props.color && props.color}; */
    color: ${(props) => (props.color ? props.color : `gray`)};

    &:hover {
      color: ${(props) => (props.color ? props.color : `gray`)};
      text-decoration: underline;
    }
  }
`;
