import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { message } from "antd";
import styled from "styled-components";
import {
  Input,
  Button,
  Basement,
  Text,
  delay,
  useInput,
  HistoryNavigation,
} from "../assets/common";
import axios from "axios";
import { SERVER_URI, header } from "../assets/utils/HttpHandler";
import { ruleSchema } from "../assets/common/util/ruleSchema";

export default function Login(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const [rememberMe, setRememberMe] = useState(
    localStorage.getItem("rememberMe") ? true : false
  );
  const [idValid, setIdValid, id, setId] = useInput(
    ``,
    ruleSchema.idLogin.rule,
    null
  );
  const [pwValid, setPwValid, pw, setPw] = useInput(
    ``,
    ruleSchema.pwLogin.rule,
    null
  );
  const [loading, setLoading] = useState(false);

  const handleRememberMe = () => {
    setRememberMe((prev) => !prev);
  };

  const handleSubmit = () => {
    let valid = true;
    if (!idValid || !id.length) {
      setIdValid(false);
      valid = false;
    }
    if (!pwValid || !pw.length) {
      setPwValid(false);
      valid = false;
    }

    if (valid) {
      const payload = {
        id,
        pw,
      };
      login(payload);
    }
  };

  const login = async (payload) => {
    setLoading(true);

    await delay();
    try {
      const response = await axios.post(
        `${SERVER_URI}/api/auth/login`,
        payload,
        header()
      );

      if (rememberMe === true) {
        localStorage.setItem("rememberMe", payload.id);
      } else {
        localStorage.removeItem("rememberMe");
      }

      localStorage.setItem("accessToken", response.data.accessToken);
      window.location.href = "/workspace";
    } catch (error) {
      console.log(`login failed: `, error);
      message.error(t(`_message.incorrectIdOrPw`));
      setLoading(false);
    }
  };

  return (
    <>
      <HistoryNavigation history={history} />
      <Basement>
        <Input
          type="text"
          label={t(ruleSchema.idLogin.label)}
          errorMsg={t(ruleSchema.idLogin.errorMsg)}
          current={id}
          callback={(next) => setId(next)}
          valid={idValid}
        />
        <Input
          type="password"
          label={t(ruleSchema.pwLogin.label)}
          errorMsg={t(ruleSchema.pwLogin.errorMsg)}
          current={pw}
          callback={(next) => setPw(next)}
          valid={pwValid}
        />
        <Button big onClick={handleSubmit} loading={loading}>
          {t("_logIn.logInBtn")}
        </Button>

        <F1>
          {/* <input
          type="checkbox"
          name="rememberMe"
          id="rememberMe"
          checked={rememberMe}
          onClick={handleRememberMe}
        /> */}
          {/* <Text>
          <label class htmlFor="rememberMe">
            {t("_logIn.rememberId")}
          </label>
        </Text> */}
        </F1>
        <F2>
          <Text textAlign="left">
            <Link to="/reset_user">{t("_logIn.forgotPw")}</Link>
          </Text>
        </F2>
        <Text textAlign="left">
          <Link to="/register">{t("_logIn.newTo")}</Link>
        </Text>
      </Basement>
    </>
  );
}

const F1 = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
`;

const F2 = styled.div`
  display: flex;
  flex-direction: space-around;
`;
