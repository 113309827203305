import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { FaYoutube } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { Text, Basement, useSelect, Select } from "../assets/common";
import styled from "styled-components";
import { useSelector } from "react-redux";

const { Option } = Select;

export default function Footer(props) {
  const { t } = useTranslation();
  const languageList = [
    { key: `en`, value: `ENGLISH` },
    { key: `ko`, value: `KOREAN` },
    { key: `thai`, value: `THAI` },
    { key: `jp`, value: `JAPAN` },
  ];
  const [current, setCurrent] = useSelect(
    window.localStorage.i18nextLng,
    languageList
  );
  const { expand, expandHeight } = useSelector((store) => store.config);

  const languageClickCallback = (next) => {
    setCurrent(next);
  };

  useEffect(() => {
    i18next.changeLanguage(current.key);
  }, [current]);

  return (
    <>
      <Container>
        <F0>
          <F1>
            <F2>
              <Text color="black" small>
                Company Info
              </Text>

              <Text small>{t("footer.23")}</Text>
              <Text small>{t("footer.24")}</Text>
              <Text small>{t("footer.25")}</Text>
              <Text small>{t("footer.21")}</Text>
              <Text small>{t("footer.20")}</Text>
              <Text small>{t("footer.19")}</Text>
              <br />
            </F2>
            <F2>
              <Text color="black" small>
                {t("footer.5")}
              </Text>

              <Text small>
                <a href="/resources/download">{t("footer.6")}</a>
              </Text>
              <Text small>
                <a href="/resources/major_customer">{t("footer.7")}</a>
              </Text>
              <Text small>
                <a href="/resources/customer_stories">{t("footer.8")}</a>
              </Text>
              <Text small>
                <a href="/resources/call_center">{t("footer.10")}</a>
              </Text>
            </F2>
          </F1>

          <F1>
            <Text small textAlign="left">
              Copyright © 2020 UCWare Co.,LTD. All rights reserved.
            </Text>
            <F2_2>
              <Text small>
                <a href="/private_policy">Private Policy</a>
              </Text>
              <Text small>
                <a href="/terms_conditions">Terms & Conditions</a>
              </Text>
            </F2_2>
            <F2_2>
              <a href="https://fb.me/ucmbest" target="_black">
                <FaFacebookF style={{ fontSize: `1.5rem` }} />
              </a>
              <a
                href="https://twitter.com/search?q=ucware&src=typd&lang=ko"
                target="_black"
              >
                <FaTwitter style={{ fontSize: `1.5rem` }} />
              </a>
              <a href="https://youtu.be/POPMqzfiDxs" target="_black">
                <FaYoutube style={{ fontSize: `1.5rem` }} />
              </a>
            </F2_2>
            <F2_2>
              <Select
                list={languageList}
                current={current}
                callback={languageClickCallback}
                small
              />
            </F2_2>
          </F1>
        </F0>
      </Container>
      <Sticky expand={expand} expandHeight={expandHeight} />
    </>
  );
}

const Container = styled.div`
  display: flex;
  background-color: #f5f5f7;
  justify-content: center;
`;

const Sticky = styled.div`
  height: ${(props) => props.expand && `${props.expandHeight}px`};
`;

const F0 = styled.div`
  width: 970px;
  display: flex;
  flex-direction: column;
  margin: 0px 20px;
`;

const F1 = styled.div`
  display: flex;
  padding: 0.5rem 0;
  width: 100%;
  flex-wrap: wrap;

  &:nth-child(2) {
    border-top: 0.1rem solid #d2d2d7;
  }
`;

const F2 = styled.div`
  margin-right: 3rem;
`;

const F2_2 = styled.div`
  display: flex;
  align-items: center;
  & > *:not(:nth-child(1)) {
    margin: 0 1rem;
  }
`;
