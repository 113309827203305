import React, { useEffect, useState, useRef } from "react";

export default function useInput(initialValue, rule, equalTo) {
  const [current, setCurrent] = useState(initialValue);
  const [valid, setValid] = useState(true);
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      if (validate(current, rule, equalTo)) {
        setValid(true);
      } else {
        setValid(false);
      }
    } else {
      didMountRef.current = true;
    }
  }, [current, equalTo]);

  return [valid, setValid, current, setCurrent];
}

export const validate = (current, rule, equalTo) => {
  if (equalTo) {
    return current === equalTo;
  } else if (rule) {
    return rule.test(current);
  } else {
    return true;
  }
};
