import axios from "axios";
import { SERVER_URI, header } from "./HttpHandler";

function RequestPay(param, callBack) {
  // 각 서버에 전달할 파라미터 분리
  let { imp, portal } = param;

  imp.merchant_uid = "merchant_" + new Date().getTime(); // 빌링키 발급용 주문번호

  const { IMP } = window;
  IMP.init(process.env.REACT_APP_IMP_CODE); // 가맹점 식별코드

  // TEST
  // Object.assign(imp, {
  //   amount: 100,
  // });
  ////

  IMP.request_pay(imp, (res) => {
    console.log(`=====================================iamport response: `, res);

    ///////////////////////////////////////////
    //test
    /*
    Object.assign(portal, {
      orderFlag: "Y",
    });
    callBack(true,portal)
    return;
    */
    ///////////////////////////////////////////

    const { success } = res;

    if (success) {
      Object.assign(portal, {
        orderFlag: "Y",
      });

      Object.assign(portal.userOrderPg, {
        status: "paid",
        impUid: res.imp_uid,
        merchantUid: res.merchant_uid,
        paidAt: res.paid_at,
        pgTid: res.pg_tid,
        pgProvider: res.pg_provider,
        receiptUrl: res.receipt_url,
        payMethod: res.pay_method,
        userOrderPgCard: {
          cardName: res.card_name,
          cardQuota: res.card_quota,
          applyNum: res.apply_num,
        },
      });
      callBack(success, portal);
    } else {
      callBack(success);
    }
  });
}

// ****************************************************************************************
// (!!! 확인 !!!)
// 결제 프로세스 변경 중 작업이 홀딩됨.
// 추후 아임포트 API 문서 및 아래 함수를 참고하여, 결제 프로세스 변경 필요.
// ****************************************************************************************
// function RequestPay(param, callBack) {
//     // 각 서버에 전달할 파라미터 분리
//     const {imp, portal} = param;

//     // 결제 상태 정의
//     portal.userOrderPg.status = "ready";

//     /** 1. Client -> Portal (결제 정보 등록 및 unique한 merchant_uid 생성 API)
//      *  - Request : 결제 정보
//      *  - Response : merchant_uid */
//     axios.post(`${SERVER_URI}/api/order/ready`, portal, header())
//     .then((response) => {
//         const { IMP } = window;
//         IMP.init(process.env.REACT_APP_IMP_CODE); // 가맹점 식별코드

//         // 결제 요청 데이터 정의
//         imp.merchant_uid = response.data.merchantUid;

//         /** 2. Client -> IMP (결제 요청)
//          *  - Request : 결제 정보, merchant_uid
//          *  - Response : 결제 결과, imp_uid, Error Message */
//         IMP.request_pay(imp, (res) => {
//             const {success} = res;

//             portal.userOrderPg.merchantUid = response.data.restCode;
//             portal.userOrderPg.restCode = response.data.restCode;
//             portal.userOrderPg.impUid = res.imp_uid;
//             portal.userOrderPg.payMethod = res.pay_method;

//             /** 3. Client -> Portal (결제 결과에 따라 분기처리) */
//             if(success) {
//                /** 3-1. 결제 성공 => 서비스 이용 가능 */

//                return callBack(success);
//             }else {
//                /** 3-2. 결제 실패 => 결제 환불, 라이선스 구매 취소
//                 *  - Request : 결제 결과, imp_uid, Error Message */

//             }
//         });
//     })
//     .catch(err => {callBack(false);})
// }

export default RequestPay;
