import React from "react";
import styled from "styled-components";

export default function Select({ label, list, current, callback, small }) {
  const handleChange = (e) => {
    const target = e.target;
    const [next] = list.filter((v) => v.key === target.value);

    callback(next);
  };

  return (
    <Container small={small}>
      <select onChange={handleChange}>
        {list.map((v, i) => (
          <option key={v.key} value={v.key} selected={current.key === v.key}>
            {v.value}
          </option>
        ))}
      </select>
      <label>{label}</label>
    </Container>
  );
}

export const Container = styled.div`
  position: relative;
  margin: 0.5rem;

  & select {
    background: none;
    color: gray;
    font-size: ${(props) => (props.small ? `1.3rem` : `1.6rem`)};
    padding: ${(props) =>
      !props.small ? `2.5rem 3rem 0.5rem 1.5rem` : `0.2rem 1rem`};

    outline: none;
    display: inline-block;
    text-align-last: ${(props) => props.small && `center`};
    width: ${(props) => !props.small && `40rem`};
    border-radius: 0.8rem;
    border: 1px solid #d2d2d7;
    appearance: none;

    @media (max-width: 768px) {
      width: ${(props) => !props.small && `90vw`};
    }

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 4px rgba(0, 125, 250, 0.6);
    }
  }

  & label {
    font-size: 1rem;
    font-weight: normal;
    pointer-events: none;
    position: absolute;
    left: 1.5rem;
    top: 1rem;

    color: gray;
  }
`;
