import React from "react";
import { useTranslation } from "react-i18next";
import { Text, Table, Title } from "../assets/common";
import styled from "styled-components";
import { useMediaQuery } from "@react-hook/media-query";
import Fade from "react-reveal/Fade";
import { BackTop } from "antd";

export default function CustomerStories() {
  const { t } = useTranslation();
  const match = useMediaQuery(`(max-width: 768px)`);
  const Case1 = require("../assets/images/case1.png");
  const Case2 = require("../assets/images/case2.png");
  const Case3 = require("../assets/images/case3.png");
  const Case4 = require("../assets/images/case4.png");
  const Case5 = require("../assets/images/case5.png");
  const Case6 = require("../assets/images/case6.png");
  const Case7 = require("../assets/images/case7.png");
  const Case9 = require("../assets/images/case9.png");

  const commonColumn = [
    { label: t("customerStories1.6"), type: `text` },
    { label: t("customerStories1.8"), type: `text` },
    { label: t("customerStories1.11"), type: `text` },
    { label: t("customerStories1.17"), type: `text` },
  ];
  const bnk = [
    {
      serviceName: t("customerStories1.7"),
      numberOfUsers: `${t("customerStories1.9")}  
      ${t("customerStories1.10")}`,
      structure: `${t("customerStories1.12")}
      ${t("customerStories1.13")}
      ${t("customerStories1.14")}
      ${t("customerStories1.15")}
      ${t("customerStories1.16")}`,
      effect: `${t("customerStories1.18")}
      ${t("customerStories1.19")}
      ${t("customerStories1.20")}`,
    },
  ];

  const mirae = [
    {
      serviceName: t("customerStories2.3"),
      numberOfUsers: `${t("customerStories2.5")}
      ${t("customerStories2.6")}`,
      structure: `${t("customerStories2.8")}
      ${t("customerStories2.9")}
      ${t("customerStories2.10")}
      ${t("customerStories2.11")}
      ${t("customerStories2.12")}
      ${t("customerStories2.13")}
      ${t("customerStories2.14")}`,
      effect: `${t("customerStories2.16")}
      ${t("customerStories2.17")}
      ${t("customerStories2.18")}`,
    },
  ];

  const hankook = [
    {
      serviceName: t("customerStories3.3"),
      numberOfUsers: `${t("customerStories3.5")}
      ${t("customerStories3.6")}
      ${t("customerStories3.7")}`,
      structure: `${t("customerStories3.9")}
      ${t("customerStories3.13")}
      ${t("customerStories3.14")}`,
      effect: `${t("customerStories3.16")}
      ${t("customerStories3.17")}
      ${t("customerStories3.18")}
      ${t("customerStories3.19")}`,
    },
  ];

  const aekyung = [
    {
      serviceName: `-`,
      numberOfUsers: t("customerStories4.4"),
      structure: `${t("customerStories4.6")}
      ${t("customerStories4.7")}
      ${t("customerStories4.8")}`,
      effect: `${t("customerStories4.10")}
      ${t("customerStories4.11")}
      ${t("customerStories4.12")}
      ${t("customerStories4.13")}`,
    },
  ];

  const energy = [
    {
      serviceName: t("customerStories5.3"),
      numberOfUsers: t("customerStories5.5"),
      structure: `${t("customerStories5.7")}
      ${t("customerStories5.8")}
      ${t("customerStories5.9")}`,
      effect: `${t("customerStories5.11")}
      ${t("customerStories5.12")}
      ${t("customerStories5.13")}`,
    },
  ];

  const daejeon = [
    {
      serviceName: t("customerStories6.3"),
      numberOfUsers: t("customerStories6.5"),
      structure: `${t("customerStories6.7")}
      ${t("customerStories6.8")}
      ${t("customerStories6.9")}
      ${t("customerStories6.10")}`,
      effect: `${t("customerStories6.12")}
      ${t("customerStories6.13")}
      ${t("customerStories6.14")}`,
    },
  ];

  return (
    <>
      <Container>
        <Title title={t(`_title.customerStories`)} src="/images/about2.jpg" />
        <F0>
          <Text
            super={match ? false : true}
            big={match ? true : false}
            color="black"
          >
            {t(`_customerStories.title`)}
          </Text>
        </F0>

        <F1>
          <F2>
            <Text super={match ? false : true} big={match ? true : false}>
              {`UC&C Platform`}
            </Text>
            <Text>
              {t("customerStories1.1")}
              {t("customerStories1.2")}
              {t("customerStories1.3")}
              {t("customerStories1.4")}
            </Text>
          </F2>
          <F2>
            <img style={{ width: "100%" }} src={Case1} alt="Case1" />
          </F2>
        </F1>
        <F1>
          <F2>
            <Text super={match ? false : true} big={match ? true : false}>
              {t("customerStories7.1")}
            </Text>
            <Text>
              {t("customerStories7.2")}

              {t("customerStories7.3")}
              {t("customerStories7.4")}

              {t("customerStories7.5")}
            </Text>
          </F2>
          <F2>
            <img style={{ width: "100%" }} src={Case9} alt="Case9" />
          </F2>
        </F1>
        <F1>
          <F2>
            <img
              style={match ? { width: `100%` } : { width: "60%" }}
              src={Case2}
              alt="Case2"
            />
          </F2>
          <F2>
            <Table column={commonColumn} payload={bnk} shadow />
          </F2>
        </F1>
        <F1>
          <F2>
            <img
              style={match ? { width: `100%` } : { width: "60%" }}
              src={Case3}
              alt="case3"
            />
          </F2>
          <F2>
            <Table column={commonColumn} payload={mirae} shadow />
          </F2>
        </F1>
        <F1>
          <F2>
            <img
              style={match ? { width: `100%` } : { width: "60%" }}
              src={Case4}
              alt="case4"
            />
          </F2>
          <F2>
            <Table column={commonColumn} payload={hankook} shadow />
          </F2>
        </F1>
        <F1>
          <F2>
            <img
              style={match ? { width: `100%` } : { width: "40%" }}
              src={Case5}
              alt="Case5"
            />
          </F2>
          <F2>
            <Table column={commonColumn} payload={aekyung} shadow />
          </F2>
        </F1>
        <F1>
          <F2>
            <img
              style={match ? { width: `100%` } : { width: "60%" }}
              src={Case6}
              alt="Case6"
            />
          </F2>
          <F2>
            <Table column={commonColumn} payload={energy} shadow />
          </F2>
        </F1>
        <F1>
          <F2>
            <img
              style={match ? { width: `100%` } : { width: "50%" }}
              src={Case7}
              alt="Case7"
            />
          </F2>
          <F2>
            <Table column={commonColumn} payload={daejeon} shadow />
          </F2>
        </F1>
      </Container>
      <BackTop />
    </>
  );
}

const Container = styled.div``;

const F0 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 5vw 0;
`;

const F1 = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  padding: 5vw 0;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const F2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5vw;
  width: 50vw;

  @media (max-width: 768px) {
    width: 100vw;
  }
`;
