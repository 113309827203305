import React, { useEffect } from "react";

function TermsConditions() {
  const currentLanguage = window.localStorage.i18nextLng === "ko" ? "ko" : "en";

  if (currentLanguage === "ko") {
    return (
      <>
        <section>
          <div
            style={{
              marginBottom: "100px",
              lineHeight: "26px",
              maxWidth: "1020px",
              margin: "0 auto",
              padding: "0 20px",
            }}
          >
            <p
              style={{
                fontSize: "3rem",
                marginBottom: "40px",
                textAlign: "center",
                paddingTop: "100px",
                lineHeight: "66px",
              }}
            >
              UCWORKS 이용 약관
            </p>
            <div>
              <br />
              UCWORKS 및 UCWORKS 관련 제반 서비스의 이용과 관련하여 필요한
              사항을 규정합니다. 시행일자:2020년 4월9일
              <br />
              <br />
              <br />
              <h2>제1장 총 칙</h2>
              <h3>제1조 (목적)</h3>
              이 약관은 (주)유씨웨어(이하 ‘회사’)가 제공하는 UCWORKS 서비스(이하
              ‘UCWORKS’)의 이용과 관련하여 ‘회사’와 ‘회원’ 간 권리와 의무 및
              기타 필요한 사항을 규정하는데 그 목적이 있습니다.
              <br />
              <br />
              <h3>제2조 (용어의 정의)</h3>
              이 약관에서 사용하는 용어의 정의는 아래와 같습니다：
              <br />
              ‘UCWORKS’라 함은 ‘회사’가 ‘회원’에게 제공하는 인스턴트
              메시징(인스턴트 메시징 서비스), 조직도, (인사관리 데이터베이스),
              캘린더(일정 관리 서비스), 주소록(주소록 관리 서비스),
              스토리지(파일 관리 서비스), 쪽지 혹은 메시지, UCWORKS App 등 관련
              제반 서비스를 의미합니다.
              <br />
              ‘회원’이라 함은 ‘UCWORKS’에 접속하여 본 약관에 따라 ‘회사’와
              이용계약을 체결하고 ‘회사’가 제공하는 ‘UCWORKS’를 이용하는 자를
              의미합니다.
              <br />
              ‘구성원’이라 함은 ‘회원’으로부터 ‘UCWORKS’ 이용권한을 부여받아
              ‘회원’의 관리하에 ‘UCWORKS’를 이용하는 자를 의미하며,
              ‘회원’으로부터부여받는 권한에 따라 ‘관리자’ 및 ‘일반 구성원’으로
              구분됩니다.
              <br />
              ‘관리자’라 함은 ‘UCWORKS’의 모든 관리 기능을 이용할 수 있고,
              ‘UCWORKS’ 이용계약을 해지할 수 있는 ‘회원’ 본인 또는 ‘회원’을
              대표・대리하는 ‘구성원’을 의미합니다.
              <br />
              ‘일반 구성원’이라 함은 별다른 관리 권한 없이 ‘UCWORKS’을 이용하는
              ‘구성원’을 의미합니다.
              <br />
              ‘그룹사’는 ‘회원’의 한 유형으로 본 서비스에서 ‘회원’의 동의 및
              설정에 따라 허용되는 범위 내에서 ‘UCWORKS’의 관리 기능을 이용할 수
              있는 다른 법인 또는 기타 단체들로 이루어진 그룹을 말합니다.
              <br />
              ‘도메인(domain)’이라 함은 ‘UCWORKS’ 이용을 위하여 필요한
              인터넷상의 주소를 말합니다.
              <br />
              ‘외부 그룹’이라 함은 같은 도메인 또는 그룹사에 포함되어 있지
              아니한 개인 및 회사 사이에 메시지, 노트, 일정, 폴더 기능을 함께
              사용할 수 있는 그룹을 의미합니다.
              <br />
              ‘관리자 웹콘솔’이란 ‘회사’가 ‘UCWORKS’ 이용을 위하여 ‘회원’에게
              제공하는 온라인 도구로서 ‘회원’은 ‘관리자 웹콘솔’을 사용하여
              ‘구성원’의 구분 및 각 ‘구성원’의 ‘UCWORKS’ 이용범위 등 ‘UCWORKS’
              이용에 필요한 사항을 ‘회사’가 허락하는 범위 내에서 직접
              설정합니다.
              <br />
              ‘아이디(ID)’라 함은 ‘회원’ 및 ‘구성원’의 식별과 ‘UCWORKS’ 이용을
              위하여 ‘회원’ 및 ‘구성원’이 정하는 도메인 혹은 그룹명을
              의미합니다.
              <br />
              ‘비밀번호’라 함은 ‘회원’이 설정한 ‘아이디’와 일치되는 ‘회원’임을
              확인하고 비밀보호를 위해 ‘회원’ 자신이 정한 문자, 숫자 또는 부호의
              조합을 의미합니다.
              <br />
              ‘Trial 서비스’라 함은 ‘회원’이 ‘UCWORKS’의 ‘유료서비스’를 이용하기
              앞서 별도의 비용을 ‘회사’에 지급하지 않고 ‘유료서비스’의 기능을
              ‘Trial서비스’ 가입 시 동의한 특정 기간 동안 체험해 보는 것을
              의미합니다.
              <br />
              ‘유료서비스’라 함은 ‘회원’이 ‘UCWORKS’의 서비스를 이용하는데
              있어서 별도의 비용을 지급하고 사용하는 것을 의미합니다.
              <br />
              ‘결제’라 함은 ‘회원’이 ‘UCWORKS’의 ‘유료서비스’를 이용하기 위하여
              비용을 ‘회사’에 지급하는 것을 의미합니다.
              <br />
              ‘월간 요금제’라 함은 ‘회원’이 본 서비스의 이용과 관련하여 회사가
              상품과 월별 사용 구성원 수 기반해 해당하는 비용을 청구하는
              요금제를 의미합니다.
              <br />
              ‘연간 요금제’라 함은 ‘회원’이 본 서비스의 이용과 관련하여 ‘회원’이
              년 단위의 사용을 약속하고 ‘회사’가 일정 할인율을 적용하여 월별로
              청구하는 요금제를 의미합니다.
              <br />
              ‘파트너’라 함은 ‘회사’로부터 ‘UCWORKS’에 대한 판매 및 재판매
              권한을 위임받아 수행할 수 있는 자 또는 ‘UCWORKS’와 다른 서비스를
              결합하여 서비스를 제공할 수 있도록 ‘회사’로부터 허락을 받은 솔루션
              개발사나 서비스 제공사를 의미합니다.
              <br />
              <br />
              <h3>제3조 (약관의 게시와 개정)</h3>
              ‘회사’는 이 약관의 내용을 ‘회원’이 쉽게 알 수 있도록 ‘UCWORKS’
              홈페이지(이하 ‘서비스 홈페이지’) 초기 화면에 게시합니다. 또한
              ‘회사’는 이 약관을 ‘회원’이 그 전부를 쉽게 확인할 수 있도록 기술적
              조치를 취합니다.
              <br />
              ‘회사’는 약관의 규제에 관한 법률, 정보통신망 이용촉진 및 정보보호
              등에 관한 법률 등 관련 법령을 위배하지 않는 범위에서 본 약관을
              개정할 수 있습니다.
              <br />
              ‘회사’가 이 약관을 개정하고자 할 경우에는 적용일자 및 개정사유를
              명시하여 현행약관과 함께 제1항의 방식에 따라 그 개정약관의
              적용일자 7일전부터 적용일자 전일까지 공지합니다. 다만, ‘회원’에게
              불리한 약관 개정의 경우에는 30일 전부터 공지를 진행함과 아울러
              일정기간 ‘아이디’로 등록된 전자우편주소, 회원가입신청 시 등록한
              보조전자우편주소, ‘UCWORKS’ 내 전자우편, 로그인시동의창 등 1개
              이상의 전자적 수단을 통해 ‘회원’에게 따로 명확히 통지합니다.
              <br />
              ‘회사’가 전항에 따라 개정약관을 공지 또는 통지하면서 ‘회원’에게
              공지 기간 내에 거부의 의사표지를 하지 않으면 약관 개정에 동의한
              것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도 ‘회원’이
              명시적으로 거부의 의사표시를 하지 아니한 경우 ‘회원’이 개정약관에
              동의한 것으로 봅니다.
              <br />
              ‘회원’이 개정약관의 적용에 동의하지 않는 경우 ‘회사’는 개정약관의
              내용을 적용할 수 없으며, 이 경우 ‘회원’은 이용계약을 해지할 수
              있습니다. 다만, 기존약관을 적용할 수 없는 특별한 사정이 있는
              경우에는 ‘회사’가 ‘회원’과의 이용계약을 해지할 수 있습니다.
              <br />
              <br />
              <h3>제4조(약관의 해석)</h3>
              ‘회사’는 ‘UCWORKS’를 위하여 별도의 정책을 둘 수 있으며, 이를
              ‘회원’이 확인할 수 있도록 ‘서비스 홈페이지’ 등에 게시합니다.
              ‘회사’가 게시한 정책 내용이 이 약관과 상충될 경우 별도의 정책이
              우선하여 적용됩니다.
              <br />이 약관에서 정하지 아니한 사항이나 해석에 대해서는 전항의
              정책, 관련 법령 또는 상관례에 따릅니다.
              <br />
              <br />
              <h3>제5조 (‘UCWORKS’ 이용계약 체결 등)</h3>
              ‘UCWORKS’ 이용계약은 ‘회원’이 되고자 하는 자(이하 ‘가입신청자’)가
              이 약관의 내용에 대하여 동의를 한 다음 회원가입신청을 하고
              ‘회사’가 이러한 신청에 대하여 승낙함으로써 체결됩니다.
              <br />
              ‘회사’는 ‘가입신청자’의 신청에 대하여 ‘UCWORKS’ 이용을 승낙함을
              원칙으로 합니다. 다만, ‘회사’는 아래 각 호에 해당하는 신청에
              대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다.
              <br />
              1. ‘가입신청자’가 이 약관에 의하여 이전에 ‘회원’ 자격을 상실한
              적이 있는 경우. 단 ‘회사’의 ‘회원’ 재가입 승낙을 얻은 경우에는
              예외로 함
              <br />
              2. 실명이 아니거나 타인의 명의를 이용한 경우
              <br />
              3. 허위의 정보를 기재하거나, ‘회사’가 요청하는 내용을 기재하지
              않은 경우
              <br />
              4. 위법, 불법행위 등 부정한 용도로 ‘UCWORKS’를 이용하고자 하는
              경우
              <br />
              5. ‘가입신청자’가 미성년자인 경우
              <br />
              6. ‘가입신청자’의 귀책사유로 인하여 승인이 불가능하거나 기타
              규정한 제반 사항을 위반하여 신청하는 경우
              <br />
              제1항의 회원가입신청에 있어 ‘회사’는 전문기관을 통한 실명확인 및
              본인인증을 요청할 수 있습니다.
              <br />
              ‘회사’는 ‘UCWORKS’ 관련 설비의 여유가 없거나, 기술상 또는 업무상
              문제가 있는 경우에는 승낙을 유보할 수 있습니다.
              <br />본 조에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한
              경우, ‘회사’는 원칙적으로 이를 ‘가입신청자’에게 알립니다.
              <br />
              ‘UCWORKS’ 이용계약의 성립 시기는 ‘회사’가 회원가입신청에 대한
              완료를 신청절차 상에서 표시한 시점으로 합니다.
              <br />
              ‘회원’이 회원가입신청 시 제공한 개인정보는 관련 법령 및 ‘회사’의
              개인정보취급방침의 적용을 받습니다.
              <br />
              ‘서비스’의 유료 서비스는 Lite, Basic과 Premium 상품으로 구분되며,
              상세한 사항은 홈페이지를 통해서 확인할 수 있습니다. ‘회원’의 상품
              선택에 따라 ‘회사’가 ‘회원’에게 제공하는 서비스와 제공 수준이
              결정됩니다.
              <br />
              ‘회원’이 ‘유료서비스’의 이용을 위해서는 ‘관리자’ 등 ‘회원’을
              대표하는 ‘구성원’이 ‘관리자웹콘솔’에서 사업자의 경우 사업자등록증,
              공공기관의 경우 공공기관등록증을 제출하고 유료결제를 위한 결제
              정보를 입력하거나 ‘파트너’를 통해 ‘유료 서비스’이용에 대한 계약을
              체결해야 합니다.
              <br />
              ‘파트너’를 통해 ‘유료 서비스’ 이용에 대한 계약을 체결한 자에
              대해서는 ‘파트너’와 체결한 별도의 ‘UCWORKS’ 이용계약(이하 ‘유료
              이용계약’)과 이 약관이 적용이 됩니다. 단 ‘유료 이용계약’과 이
              약관의 내용이 다를 경우 ‘유료 이용계약’의 내용이 우선하여
              적용됩니다.
              <br />
              ‘회원’이 ‘UCWORKS’ 중 아카이빙(Archiving)서비스를 사용하는 경우에
              아래 각호가 적용됩니다.
              <br />
              1. ‘회원’이 아카이빙(Archiving) 기능을 특정 ‘구성원’에 적용하는
              경우 적용 시점 기준으로 보관기간이 적용됩니다. 단, ‘구성원’이 이미
              삭제하여 확인할 수 없는 데이터는 소급되어 반영되지 않습니다.
              <br />
              2. ‘회원’이 ‘UCWORKS’ 또는 아카이빙(Archiving) 서비스 이용을
              갱신하지 않거나 법적으로 ‘회원’의 데이터 보관이 의무사항이 아닌
              경우 ‘회사’는 (가)’UCWORKS’ 이용계약이 종료되거나, (나)’회원’이
              아카이빙(Archiving) 서비스를 갱신하지 않거나 (다)’회원’이 지정한
              보관 기간이 지난 경우 아카이빙(Archiving) 서비스 내 ‘회원’의
              데이터를 보관할 의무가 없습니다.
              <br />
              3. 아카이빙(Archiving) 대상 서비스는 쪽지와 메시지로 한정합니다.
              <br />
              <br />
              <h3>제6조 (정보의 변경)</h3>
              ‘회원’은 개인정보관리 화면을 통하여 언제든지 본인의 정보를
              열람하고 수정할 수 있습니다. 다만, ‘UCWORKS’ 관리를 위해 필요한
              실명, ‘아이디’, ‘도메인’ 등 ‘회사’가 수정을 불허하는 항목의 수정은
              ‘회사’와 사전 합의가 필요합니다.
              <br />
              ‘구성원’은 ‘UCWORKS’ 내 개인정보관리 화면을 통하여 자신의 정보를
              수정할 수 있습니다. 다만 ‘회사’ 또는 ‘회원’이 허용하지 않는 정보의
              경우 수정할 수 없습니다.
              <br />
              ‘회원’은 회원가입신청 시 기재한 사항이 변경되었을 경우 온라인으로
              수정을 하거나 전자우편 기타 방법으로 ‘회사’에 대하여 그 변경사항을
              알려야 합니다.
              <br />
              ‘회원’ 또는 ‘구성원’이 자신의 정보에 대한 변경사항을 적절히
              수정하지 않아 발생한 불이익에 대하여 ‘회사’는 책임을 부담하지
              않습니다.
              <br />
              <br />
              <h3>제7조 (개인정보 보호 의무)</h3>
              ‘회사’는 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 관련
              법령이 정하는 바에 따라 ‘회원’의 개인정보를 보호하기 위해
              노력합니다.
              <br />
              개인정보의 보호 및 사용에 대해서는 관련 법령 및 ‘회사’의
              개인정보처리방침이 적용됩니다. 다만, ‘회사’의 공식 사이트 이외의
              링크된 사이트에서는 ‘회사’의 개인정보처리방침이 적용되지 않습니다.
              <br />
              ‘회원’은 ‘구성원’의 개인정보처리자로서 개인정보보호법 등 관련
              법령이 정한 바에 따라 ‘구성원’의 개인정보를 보호하여야 합니다.
              <br />
              <br />
              <h3>제8조 ( ‘아이디’ 및 ‘비밀번호’의 관리에 대한 의무)</h3>
              ‘회원’ 및 ‘구성원’의 ‘아이디’와 ‘비밀번호’에 관한 관리책임은
              ‘회원’ 및 ‘구성원’에게 있으며, 이를 제3자가 이용하도록 하여서는 안
              됩니다.
              <br />
              ‘회사’는 ‘회원’ 또는 ‘구성원’의 ‘아이디’ 또는 개인정보가 유출
              우려가 있거나, 반사회적 또는 미풍양속에 어긋나거나 ‘회사’ 및
              ‘회사’의 운영자로 오인될 우려가 있는 경우, 해당 ‘아이디’의 이용을
              제한할 수 있습니다.
              <br />
              ‘회원’은 ‘아이디’ 및 ‘비밀번호’가 도용되거나 제3자가 사용하고
              있음을 인지한 경우에는 이를 즉시 ‘회사’에 통지하고 ‘회사’의 안내에
              따라야 합니다.
              <br />
              제3항의 경우에 해당 ‘회원’이 ‘회사’에 그 사실을 통지하지 않거나,
              통지 후 ‘회사’의 안내에 따르지 않아 발생한 불이익에 대하여
              ‘회사’는 책임을 부담하지 않습니다.
              <br />
              제3항의 경우에 해당 ‘회원’이 ‘회사’에 그 사실을 통지하지 않거나,
              통지 후 ‘회사’의 안내에 따르지 않아 발생한 불이익에 대하여
              ‘회사’는 책임을 부담하지 않습니다.
              <br />
              <br />
              <h3>제9조 (‘회원’에 대한 통지)</h3>
              ‘회사’가 ‘회원’에 대한 통지를 하는 경우 이 약관에 별도 규정이 없는
              한 ‘아이디’로 등록한 전자우편주소 또는 회원가입신청 시 등록한
              보조전자우편주소로 할 수 있습니다.
              <br />
              ‘회사’가 ‘관리자’에게 통지한 경우 이는 ‘회원’에게 통지한 것으로
              봅니다. ‘회원’은 ‘관리자’ 정보에 대한 관리 및 적절한 정보로의 변경
              책임을 부담합니다.
              <br />
              ‘회원’ 전체에 대한 통지의 경우 ‘회사’는 7일 이상 ‘서비스 홈페이지’
              게시판에 해당 내용을 게시함으로써 전항의 통지를 갈음할 수
              있습니다.
              <br />
              ‘회원’은 ‘회사’가 ‘회원’에게 통지한 사항에 대하여 ‘구성원’에게
              통지할 책임을 부담합니다.
              <br />
              <br />
              <h3>제10조 (‘회사’의 의무)</h3>
              ‘회사’는 관련 법령과 이 약관이 금지하거나 미풍양속에 반하는 행위를
              하지 않으며, 계속적이고 안정적으로 ‘UCWORKS’를 제공하기 위하여
              최선을 다하여 노력합니다.
              <br />
              ‘회사’는 ‘회원’이 안전하게 ‘UCWORKS’를 이용할 수 있도록 개인정보
              보호를 위해 보안시스템을 갖추어야 하며 개인정보취급방침을 공시하고
              준수합니다.
              <br />
              ‘회사’는 ‘UCWORKS’ 이용과 관련하여 발생하는 ‘회원’의 불만 또는
              피해구제요청을 적절하게 처리할 수 있도록 필요한 인력 및 시스템을
              구비합니다.
              <br />
              ‘회사’는 ‘UCWORKS’ 이용과 관련하여 ‘회원’으로부터 제기된 의견이나
              불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다. ‘회원’이
              제기한 의견이나 불만사항에 대해서는 게시판을 활용하거나 전자우편
              등을 통하여 ‘회원’에게 처리과정 및 결과를 전달할 수 있습니다.
              <br />
              ‘회사’가 ‘회원’에 대한 ‘UCWORKS’의 제공이 불가능해지는 경우,
              ‘회사’는 ‘UCWORKS’의 제공 중지일자의 3개월 전까지 제9조에서 정한
              방법으로 ‘회원’에게 통지합니다. 다만, ‘회사’가 3개월 전에 통지할
              수 없는 부득이한 사유가 있는 경우 ‘회사’는 ‘UCWORKS’를 제공할 수
              없게 된 날까지 ‘회원’에게 통지하는 것으로 3개월 전 통지의무를
              갈음할 수 있습니다.
              <br />
              ‘회사’가 ‘회원’에 대한 ‘UCWORKS’의 제공이 불가능해지는 경우,
              ‘회사’는 ‘UCWORKS’의 제공 중지일자의 3개월 전까지 제9조에서 정한
              방법으로 ‘회원’에게 통지합니다. 다만, ‘회사’가 3개월 전에 통지할
              수 없는 부득이한 사유가 있는 경우 ‘회사’는 ‘UCWORKS’를 제공할 수
              없게 된 날까지 ‘회원’에게 통지하는 것으로 3개월 전 통지의무를
              갈음할 수 있습니다.
              <br />
              <br />
              <h3>제11조 (‘회원’의 의무)</h3>
              ‘회원’은 아래 각 호의 행위를 하여서는 안 됩니다.
              <br />
              1. 회원가입신청 또는 기입정보의 변경 시 허위내용의 등록
              <br />
              2. 타인의 정보도용
              <br />
              3. ‘회사’가 게시한 정보의 변경
              <br />
              4. 불법소프트웨어 등 법령에서 금지하거나 ‘회사’가 공지사항 등을
              통해 금지하는 소프트웨어 등 정보의 복제, 전송, 공유하는 행위
              <br />
              5. ‘회사’ 및 기타 제3자의 저작권 등 지적재산권에 대한 침해
              <br />
              6. ‘회사’ 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
              <br />
              7. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는
              정보를 ‘UCWORKS’를 이용하여 복제, 전송, 방송, 공유하는 행위
              <br />
              8. ‘회사’의 동의 없이 영리를 목적으로 ‘UCWORKS’를 사용하는 행위
              <br />
              9. 기타 불법적이거나 부당한 행위
              <br />
              ‘회원’은 관련 법령, 이 약관, ‘UCWORKS’ 운영정책, ‘UCWORKS’
              고객센터 내 도움말, ‘UCWORKS’와 관련하여 ‘회사’가 공지한 주의사항
              및 통지사항 등을 준수하여야 하며, 기타 ‘회사’의 업무에 방해되는
              행위를 하여서는 안 됩니다.
              <br />
              ‘회원’은 ‘구성원’이 전항의 의무를 준수하도록 조치하여야 합니다.
              ‘구성원’이 전항의 의무를 위반할 경우 ‘회사’는 해당 ‘구성원’에 대한
              제재 등 ‘관리자’의 권한 일부를 직접 행사할 수 있으며, ‘구성원’의
              위반행위는 ‘회원’의 위반행위로 봅니다.
              <br />
              ‘회원’은 ‘유료서비스’를 신청하기 전에 반드시 ‘회사’가 서비스
              홈페이지를 통해 제공하는 상품의 이용조건과 거래조건을 확인한 후
              신청하여야 합니다. 이를 확인하지 않고 ‘유료서비스’를 신청하여
              발생한 모든 손실, 손해에 대한 책임은 ‘회사’의 귀책사유가 인정되지
              않는 한 ‘회원’이 부담합니다.
              <br />
              제2장 ‘UCWORKS’ 이용 일반
              <br />
              ‘회사’는 ‘UCWORKS’의 연중무휴, 1일 24시간 제공함을 원칙으로
              합니다.
              <br />
              ‘회사’는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절
              또는 운영상 상당한 이유가 있는 경우 ‘UCWORKS’의 제공을 일시적으로
              중단할 수 있습니다. 이 경우 ‘회사’는 ‘UCWORKS’의 제공이 중단되기
              7일전에 제9조에서 정한 방법으로 ‘회원’에게 통지합니다. 다만,
              ‘회사’가 사전에
              <br />
              통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수
              있습니다.
              <br />
              ‘회사’는 ‘UCWORKS’의 제공에 필요한 경우 정기점검을 실시할 수
              있으며, 정기점검시간은 ‘서비스 홈페이지’에 공지한 바에 따릅니다.
              <br />
              <br />
              <h3>제13조 (‘UCWORKS’의 변경)</h3>
              ‘회사’는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라
              ‘UCWORKS’의 전부 또는 일부를 변경할 수 있습니다.
              <br />
              ‘UCWORKS’의 내용, 이용방법, 이용시간, 이용금액에 대하여 변경이
              있는 경우에는 변경사유, 변경될 내용 및 제공일자 등은 그 변경 전에
              ‘서비스 홈페이지’에 게시하여야 합니다.
              <br />
              ‘회원’은 본 조에 의한 ‘UCWORKS’의 변경에 동의하지 않을 경우
              ‘UCWORKS’ 이용계약을 해지할 수 있습니다.
              <br />
              <br />
              <h3>제14조 (‘UCWORKS’ 정보 제공 및 안내)</h3>
              ‘회사’는 ‘회원’이 ‘UCWORKS’ 이용 중 필요하다고 인정되는 다양한
              정보를 공지사항이나 전자우편 등의 방법으로 ‘회원’에게 제공할 수
              있습니다.
              <br />
              제1항의 정보를 전화 및 모사전송기기에 의하여 전송하려고 하는
              경우에는 ‘회원’의 사전 동의를 받아서 전송합니다. 다만, ‘회원’의
              거래관련 정보 및 고객문의 등에 대한 회신에 있어서는 제외됩니다.
              <br />
              ‘회사’는 ‘UCWORKS’의 운영과 관련하여 ‘UCWORKS’ 화면, ‘서비스
              홈페이지’, ‘구성원’의 전자우편 등에 서비스 이용과 관련된 안내
              내용을 게재할 수 있습니다. 안내 내용이 게재된 전자우편을 수신한
              ‘회원’은 ‘회사’에 대하여 그 수신을 거절할 수 있습니다.
              <br />
              ‘회원’은 ‘UCWORKS’와 관련하여 ‘회사’가 게시・제공하는 게시물 또는
              기타 정보를 변경, 수정, 제한하는 등의 조치를 취하지 않습니다.
              <br />
              <br />
              <h3>제15조 (‘UCWORKS’ 생성)</h3>
              ‘회원’은 ‘UCWORKS’ 생성 시 보유하고 있는 ‘도메인’을 입력해야 하며,
              ‘회사’는 ‘UCWORKS’에서 등록되어 사용 중인 ‘도메인’에 대한 소유권을
              확인하기 위해 가입 후 TXT, CNAME입력을 통해 확인을 진행합니다.
              <br />
              ‘회원’은 ‘UCWORKS’ 생성 시 ‘그룹명’을 사용할 경우 ‘도메인’에 대한
              소유권 확인을 별도로 진행하지 진행하지 않습니다.
              <br />
              <br />
              <h3>제16조 (결제등)</h3>
              ‘회사’는 ‘회원’이 이용 중인 ‘UCWORKS’의 ‘유료서비스’의 전월 요금을
              매월 1일에 청구하고, 이때 청구된 금액의 유효일은 청구일이 속한 월
              말일까지입니다.
              <br />
              ‘회원’은 ‘UCWORKS’의 ‘유료서비스’를 신청할 때 ‘월간 요금제’와
              ‘연간 요금제’ 중 하나를 선택할 수 있습니다. 다음 각 호의 사항을
              유의하여야 합니다.
              <br />
              1. ‘월간요금제’는 ‘회원’ 또는 ‘관리자’가 ‘연간 요금제’로 전환할 수
              있으며 ‘연간 요금제’에서 ‘월간 요금제’로도 전환할 수 있습니다. 단,
              계약 기간 중에는 변경이 불가능합니다.
              <br />
              2. ‘회원’ 또는 ‘관리자’가 ‘구성원’을 삭제하는 경우, 삭제한
              ‘구성원’을 삭제일부터 ‘관리자 웹콘솔’에서 안내되는 일정기간 내에
              복직처리가 가능합니다. 복직처리 가능 기간 동안 해당 ‘구성원’의
              비용은 비과금 처리됩니다.
              <br />
              3. ‘회원’은 계약된 기간 종료 이후에 계약의 종료, 계약의 자동갱신,
              계약의 조건변경, 요금제 전환 등을 직접 설정할 수 있으며, ‘회원’이
              설정을 변경하지 않으면 계약의 자동갱신으로 처리됩니다.
              <br />
              4. ‘유료서비스’를 사용 중인 경우 ‘Trial 서비스’로 전환할 수
              없습니다.
              <br />
              ‘UCWORKS’의 ‘유료서비스’에 대한 ‘결제’는 신용카드를 통해
              이루어집니다.
              <br />
              ‘회원’은 ‘유료서비스’ 결제와 관련하여 ‘회원’이 입력한 정보가
              정확한지 여부를 확인해야 하며, 그 정보와 관련하여 발생한 책임과
              불이익을 전적으로 부담합니다.
              <br />
              ‘회원’은 ‘UCWORKS’의 ‘유료서비스’에 대해 세금을 포함하여 지불해야
              합니다. ‘세금’은 ‘회원’이 계약하는 ‘회사’의 주소지 국가 세법에
              따라서 지정됩니다.
              <br />
              ‘회원’이 청구요금을 미결제하여 발생한 ‘연체 정지’ 기간에도
              데이터가 보관되고, 메일수신이 가능하기 때문에 요금청구는
              이뤄집니다.
              <br />
              ‘회원’의 ‘연체’기간에는 월 2%의 연체 가산금이 추가 부과됩니다.
              <br />
              <br />
              <h3>제17조 (이용제한 등)</h3>
              ‘회사’는 ‘회원’ 또는 ‘구성원’이 이 약관을 위반하거나 ‘회원’ 또는
              ‘구성원’이 ‘UCWORKS’의 정상적인 운영을 방해한 경우, 경고,
              일시정지, 영구이용정지 등으로 ‘회원’의 ‘UCWORKS’ 이용을 단계적으로
              제한할 수 있습니다.
              <br />
              ‘회사’는 전항에도 불구하고 명의도용 및 결제도용, 저작권법을 위반한
              불법프로그램의 제공 및 운영방해, 정보통신망 이용촉진 및 정보보호
              등에 관한 법률을 위반한 불법통신 및 해킹, 악성프로그램의 배포,
              접속권한 초과행위 등과 같이 관련 법령을 위반한 경우에는 즉시
              영구이용정지를 할 수 있습니다. 본 항에 따른 영구이용정지 시
              ‘UCWORKS’ 이용을 통해 획득한 기타 혜택 등도 모두 소멸되며,
              ‘회사’는 이에 대해 별도로 보상하지 않습니다.
              <br />
              ‘회사’는 본 조의 이용제한 범위 내에서 제한의 조건 및 세부내용을
              개별 운영정책으로 정할 수 있습니다.
              <br />본 조에 따라 ‘UCWORKS’ 이용을 제한하거나 계약을 해지하는
              경우에는 ‘회사’는 이의신청 방법과 함께 제9조에 따라 통지합니다.
              <br />
              ‘회원’은 본 조에 따른 이용제한 등에 대해 ‘회사’가 정한 절차에 따라
              이의신청을 할 수 있습니다. 이 때 이의가 정당하다고 ‘회사’가
              인정하는 경우 ‘회사’는 즉시 ‘UCWORKS’의 이용을 재개합니다.
              <br />
              ‘회원’이 ‘유료서비스’를 이용 중이고, ‘결제’가 정상적으로 이뤄지지
              않을 때 ‘연체 정지’로 처리되는 사유와 관련 조치는 다음과 같습니다.
              <br />
              1. ‘연체 정지’라 함은 ‘회원’이 ‘유료 서비스’를 이용하고 있고
              이용한 요금을 납부 기한 내 납부하지 않은 경우 전환되는 상태를
              말합니다. ‘연체 정지’가 되는 경우 서비스 이용이 제한될 수
              있습니다. 관리자는 ‘관리자 웹콘솔’에 접근 가능하며 연체금액에 대한
              납부를 통해 ‘연체 정지’ 상태의 해소가 가능합니다.
              <br />
              2. ‘회원’이 이용중인 ‘유료서비스’에 대한 청구요금을 납부완료일
              이내에 납부하지 않는 경우 요금 연체로 판단하고, 자동으로 ‘회사’는
              ‘회원’의 ‘UCWORKS’를 ‘연체 정지’ 상태로 전환시킵니다. ‘연체 정지’
              상태는 ‘회원’이 2달 이내 결제 처리할 때까지 지속합니다. 또한,
              ‘연체 정지’ 상태인 경우에도 요금은 청구됩니다. Trial 서비스의
              경우, 해당 기간 안에 유료 전환을 하지 않을 경우 이후 추가 30일
              동안까지만 유예 기간을 주고 이후에는 데이터를 삭제합니다.
              <br />
              <br />
              <h3>제18조 (청약철회, 계약해지 등)</h3>
              ‘회원’은 ‘유료서비스’의 이용에 관한 계약을 체결한 날로부터 7일
              이내에 청약의 철회를 할 수 있으며, ‘유료서비스’의 내용이
              표시•광고의 내용과 다르거나 계약내용과 다르게 이행된 경우에는
              ‘유료서비스’를 공급받은 날로부터 3개월 이내 또는 그 사실을 안 날
              또는 알 수 있었던 날부터 30일 이내에 청약을 철회할 수 있습니다. 이
              경우 ‘회원’은 청약철회를 위해 ‘유료서비스’의 이용으로부터 얻은
              이익에 해당하는 금액을 ‘회사’에 지불하여야 합니다.
              <br />
              ‘회원’은 언제든지 ‘웹사이트’ 등을 통하여 ‘UCWORKS’ 이용계약 해지
              신청을 할 수 있으며, ‘회사’는 관련 법령 등이 정하는 바에 따라 이를
              즉시 처리하여야 합니다.
              <br />
              ‘회원’이’ 유료서비스’를 ‘월간 요금제’로 이용하고 있고, 이를 계약
              해지하는 경우 ‘회사’는 연체 금액 및 당월 사용금액을 계산하여
              청구합니다.
              <br />
              ‘회원’이 ‘유료서비스’를 ‘연간 요금제’로 이용하고 있고, 잔여
              사용기간이 있음에도 불구하고 계약 해지하는 경우 ‘회사’는 미납 금액
              및 당월 사용금액을 계산하여 청구하고, 추가적으로 해지 신청일까지
              할인 적용 받은 금액과 잔여기간의 사용 금액의 10%를 위약금으로
              청구합니다.
              <br />
              ‘회원’이 ‘UCWORKS’ 이용계약을 해지할 경우, 해지 후 ‘UCWORKS’를
              계속 이용하고자 할 때, 또는 계정 도용 등 권한없는 타인에 의한
              이용계약 해지가 발생했을 때에 회원의 요청에 따라 ‘회원’ 또는
              ‘구성원’의 데이터를 복구하기 위해 ‘회원’을 포함한 ‘구성원’의 모든
              데이터는 이용계약의 해지 후 7일간 보관되며, 7일이 지난 후 즉시
              삭제됩니다. 다만, ‘회사’는 관련 법령 및 개인정보처리방침에 따라
              ‘회원’을 포함한 ‘구성원’의 데이터를 관련 법령 및
              개인정보처리방침에서 정한 기간 동안 보유할 수 있습니다.
              <br />
              ‘회사’는 아래 각호의 경우에 ‘회원’과의 이용계약을 해지할 수
              있습니다.
              <br />
              1. ‘회원’이 회생절차 또는 파산을 신청하였거나, 신청한 경우
              <br />
              2. ‘회원’ 또는 ‘구성원’이 ‘UCWORKS’을 불법적인 용도로
              사용하였거나, 불법정보를 유통한 경우
              <br />
              3. ‘회원’이 이 약관을 위반하고 ‘회사’의 시정요청을7영업일 이내에
              이행하지 않은 경우
              <br />
              4. ‘회원’이 이용금액을 납부하지 않아 ‘연체 정지’ 상태로 전환된
              이후 2달이 경과한 경우
              <br />
              ‘회원’의 ‘UCWORKS’ 이용계약 해지 시 ‘구성원’의 ‘UCWORKS’ 관련
              데이터는 제5항에서 정한 바에 따라 모두 삭제됩니다. ‘회원’은
              ‘UCWORKS’ 이용계약 해지 전 ‘회원’ 및 ‘구성원’의 데이터를 스스로
              백업하여야 하며, ‘회원’이 데이터를 백업하지 않아 발생한 문제에
              대해 ‘회사’는 책임을 부담하지 않습니다.
              <br />
              <br />
              <h3>제19조 (책임제한)</h3>
              ‘회사’는 천재지변 또는 이에 준하는 불가항력으로 인하여 ‘UCWORKS’를
              제공할 수 없는 경우에는 ‘UCWORKS’ 제공에 관한 책임이 면제됩니다.
              <br />
              ‘회사’는 ‘회원’ 및 ‘구성원’의 귀책사유로 인한 ‘UCWORKS’ 이용의
              장애에 대하여는 책임을 지지 않습니다.
              <br />
              ‘회사’는 ‘회원’과 ‘구성원’ 간, ‘구성원’ 상호간 또는 ‘구성원’과
              제3자 간에 ‘UCWORKS’를 매개로 이루어진 거래 등에 관하여는 책임을
              지지 않습니다.
              <br />
              ‘회사’는 ‘Trial 서비스’나 무료로 제공되는 ‘UCWORKS’ 이용과
              관련하여 관련 법령에 특별한 규정이 없는 한 보상 또는 배상 책임을
              지지 않습니다.
              <br />
              ‘파트너’를 통해 제5조의 ‘유료 서비스’ 이용에 대한 계약을 체결한
              경우, ‘회사’는 ‘파트너’와 ‘회원’ 간, ‘파트너’와 ‘구성원’ 간에
              발생한 분쟁에 대하여는 책임을 지지 않습니다.
              <br />
              ‘UCWORKS’와 결합하여 제공되는 상품의 경우 ‘회사’가 제공하는
              ‘UCWORKS’를 제외한 나머지 상품의 이용상 발생하는 문제에 대하여
              ‘회사’는 책임을 부담하지 않습니다.
              <br />
              제3장 기타
              <h3>제20조 (손해배상)</h3>
              ‘회사’ 또는 ‘회원’의 귀책사유로 인하여 상대방 또는 제3자에게
              손해가 발생할 경우 귀책당사자는 해당 손해를 배상하여야 합니다.
              ‘구성원’의 귀책사유는 ‘회원’의 귀책사유로 봅니다.
              <br />
              <br />
              <h3>제21조 (사용자 동의)</h3>
              ‘회원’은 본 이용 약관, 개인 정보 보호 정책 적용에 대해 ‘구성원’의
              동의를 얻고 있다는 것을 보장합니다.
              <br />
              ‘관리자’는 ‘관리자 웹콘솔’에서 ‘관리자’에게 허가된 권한의 범위
              안에서 구성원의 데이터에 접근할 수 있습니다. 단, ‘회사’는
              ‘구성원’이 ‘UCWORKS’ 이용을 시작하기 전에 ‘구성원’으로부터
              ‘관리자’가 위의 ‘구성원’의 데이터에 접근할 수 있고 데이터를
              모니터링, 이용 또는 공개 할 수 있다는 점에 대하여 동의를 받아야
              합니다.
              <br />제 2 항에 반하여 ‘구성원’의 일부 또는 전부가 동의를 하지
              않는 경우에도 이로 인한 ‘회원’과 ‘구성원’간 분쟁 발생 시 ‘회사’는
              책임을 지지 않습니다.
              <br />
              <br />
              <h3>제22조 (권리의 귀속)</h3>
              ‘UCWORKS’에 대한 저작권 등 지적재산권은 ‘회사’에 귀속됩니다.
              <br />
              ‘회사’는 ‘회원’에게 이 약관을 포함하여 ‘회사’가 사전에 정하여 공지
              또는 통지한 이용조건에 따라 ‘UCWORKS’를 이용할 수 있는 이용권만을
              부여하며, ‘회원’은 이를 양도 또는 판매하거나, 담보로 제공하는 등
              처분행위를 하여서는 안 됩니다.
              <br />
              <br />
              <h3>제23조 (준거법 및 재판관할)</h3>
              ‘회사’와 ‘회원’ 간 제기된 소송은 대한민국법을 준거법으로 합니다.
              <br />
              ‘회사’와 ‘회원’간 발생한 분쟁의 경우 관할법원은 민사소송법이 정한
              바에 따릅니다. 다만, 해외에 주소나 거소가 있는 ‘회원’의 경우
              ‘회사’와 ‘회원’ 간 발생한 분쟁에 관한 소송은 본문에도 불구하고
              서울중앙지방법원을 관할 법원으로 합니다.
              <br />
              <br />– 공고일자 : 2020년 4월 9일
              <br />– 시행일자 : 2020년 4월 9일
              <br />
            </div>
          </div>
        </section>
      </>
    );
  } else {
    return (
      <>
        <section class="contactsec privacy" style={{ padding: 0 }}>
          <div class="content" style={{ width: `100%`, maxWidth: "1020px" }}>
            <h5 style={{ fontSize: "2.5rem", marginBottom: "1.3rem" }}>
              Terms and Conditions
            </h5>

            <h4>Chapter 1. General Terms</h4>
            <p>Article 1 (Purpose)</p>
            <p>
              This service terms and conditions (the “Terms”) aims to stipulate
              rights and obligations of UCWARE Inc. (the “Company”) and any
              customer (the “Customer”) using or accessing the Company’s Service
              website or using the Service (as defined hereunder), together with
              other matters related thereto.
            </p>
            <p>Article 2 (Definition of Terms)</p>
            <p>
              The definitions of capitalized terms used in this terms and
              conditions are as follows:
            </p>
            <p>
              ① “Service” refers to ‘UCWORKS Service’, infrastructure service
              that provides server, storage, database, and network, as well as
              IT solution service freely accessible from the Customer’s device
              through a cloud computing method. The Service is composed of
              different individual services (each “Individual Service”) and the
              Customer may select and use any Individual Services upon the
              consent of the Company.
            </p>
            <p>
              ② “Customer” refers to an individual, a corporation, or a public
              institution that accesses the Service of the Company,agrees to
              accept the Terms and uses the Service provided by the Company in
              accordance with the Terms.
            </p>
            <p>
              ③ “ID” refers to a combination of letters and numbers or the
              Customer’s email address that is selected by the Customerand
              approved by the Company for the purpose of identifying the
              Customer requesting to use the Service.
            </p>
            <p>
              ④ “Password” refers to a combination of letters and numbers chosen
              by the Customer for the purpose of securingaccess to said
              Customer’s account.
            </p>
            <p>
              ⑤ “Customer Contents” refers to the information and data (as
              defined in Article 3 Section 1 of the Framework Act onNational
              Informatization, and includes personal information and credit
              information in case of individuals) theCustomer stores or manages
              through the Service
            </p>
            <p>
              ⑦ “Business Days” means any days commercial banks located in Korea
              are open for business.
            </p>
            <p>Article 3 (Posting and Amendment of the Terms)</p>
            <p>
              ① The Terms are available on the Company Service website. The
              Terms apply to theCustomer’s use and access of the Company Service
              website and to any Service provided by the Company uponformation
              of the Service Agreement (as defined in Article 5 paragraph ①
              hereunder). The Customer’s use or accessof the Company Service
              website constitutes the Customer’s consent to the Terms.
            </p>
            <p>
              ② To the extent permitted by applicable laws, including without
              limitation the Act on the Regulation of Terms andConditions, the
              Act on Promotion of Information and Communications Network
              Utilization and InformationProtection and the Act on the
              Development of Cloud Computing and Protection of its Users, the
              Company mayfreely amend the Terms as set forth herein.
            </p>
            <p>
              ③ The Company may amend the Terms by notifying of such change by
              any reasonable means, including by posting arevised version of the
              Terms through the Company Service website. The Company will post
              the revised version ofthe Terms through the Company Service
              website at least seven (7) days prior to the date any amendment
              entersinto effect specifying the effective date and explanation
              for the amendment. Provided, however, if any amendmentcauses
              adverse effects to the Customer’s rights or obligations, the
              Company shall give notice of such amendmentto each affected
              Customer as set forth in Article 7, Paragraph ① at least thirty
              (30) days prior to the effective date.
            </p>
            <p>
              ④ If the Company notifies an amendment to the Terms in accordance
              with the terms and conditions thereof togetherwith clear notice
              that acceptance of the amendments will be deemed to have been
              given by the Customer if suchCustomer continues to use the Service
              for a period of thirty (30) days or more since the notice; and the
              Customer
            </p>
            <p>
              ⑤ If any Customer does not agree to any proposed amendment of the
              Terms, either the Company or the Customermay terminate the Service
              Agreement. In such case, Company shall notify the Customer in
              writing, via email orother comparable methods, the cause for
              termination, termination date and any refundable amount.
            </p>
            <p>Article 4 (Matters Not Specified in the Terms) </p>
            <p>
              ① Any matters that are not specified in the Terms shall be
              determined by applicable laws, regulations, business practices,
              and (if applicable) Individual Service operation policies
              available at the Company Service website. For the avoidance of any
              doubt, the Company may maintain and apply separate operation
              policies for each Individual Service.{" "}
            </p>
            <p>
              ② The Company may define specific terms and conditions of use to
              apply to Individual Service. If the Individual Service’s specific
              terms and conditions are in conflict with the Terms, the specific
              terms and conditions of the respective Individual Service shall
              prevail over the Terms, unless otherwise set forth therein.{" "}
            </p>
            <p>
              ③ If the terms and conditions of the Individual Service is silent
              or unenforceable on any matter, the terms and conditions of the
              Terms shall apply.{" "}
            </p>
            <h4>Chapter 2. Use of the Service </h4>
            <p>Article 5 (Applying for Service Usage Agreement)</p>
            <p>
              ① A Service usage agreement (the “Service Agreement”) shall be
              formed when an applicant (the “Applicant”) applies for use of the
              Service after agreeing to the Terms, and the Company approves the
              application.{" "}
            </p>
            <p>
              ② The Applicant must provide its/his/her real name and true
              information when applying to use the Service. If the Applicant
              unlawfully uses a false name or provides false information, the
              Applicant may not enjoy or claim the rights stipulated in the
              Terms, and the Company may terminate the Service Agreement.{" "}
            </p>
            <p>
              ③ Any personal information provided by the Applicant to the
              Company when applying for the Service shall be protected in
              accordance with the applicable laws and regulations as well as the
              Company’s privacy policy.{" "}
            </p>
            <p>
              ④ If the Applicant is a minor (as defined in the Civil Act), the
              Applicant may not use the Service irrespective of consent of the
              minor’s legal guardian. The Company may reject a minor’s
              application and/or use of the Service. By accessing or using the
              Service, the Customer affirms that he or she is of legal age to
              enter into the Terms.{" "}
            </p>
            <p>Article 6 (Formation of Service Agreement) </p>
            <p>
              ① If the Applicant truthfully and accurately fills in the
              information as specified in Article 5 above, the Company will
              approve the application unless there are special circumstances.{" "}
            </p>
            <p>
              ② The Company may reject or delay the approval of any application
              to use the Service or terminate the Service Agreement after
              initial acceptance in the following instances: 1. If the Applicant
              did not used its/his/her real name or used another person’s name
              2. If the Applicant provided false information or did not submit
              any information requested by the Company 3. If the Applicant
              intends to use the Service for unlawful purposes, such as
              violations of law or other illegal actions 4. If the application
              cannot be approved due to a cause attributable to the Applicant or
              if the application violates Company policies 5. If the Applicant
              has any outstanding payment due to the Company 6. If the Applicant
              has a history of delayed payment for fees for using the Service
              (the “Fees”) or improper use of Service 7. If there are any other
              reasons, as exemplified in the foregoing paragraphs, that
              significantly indicate that approval of the application is
              inappropriate.{" "}
            </p>
            <p>
              ③ If the Company decides to reject or delay any application to use
              the Service, the Company will notify the Applicant.{" "}
            </p>
            <p>Article 7 (Notification to the Customer) </p>
            <p>
              ① If the Company needs to give notice to a Customer, the Company
              will use the email address the Customer registered at the time of
              the application to use the Service, unless specified otherwise in
              the Terms. However, when the Company gives notice of a change that
              affects Customer rights and obligations in a disadvantageous way,
              the Company shall send email to the Customer at the email address
              the Customer registered at the time of application to notify of
              the change.{" "}
            </p>
            <p>
              ② If the Company needs to notify all Customers, the Company may
              make an announcement on the Company Service website for more than
              7 days, instead of the method specified in paragraph ① above.
              Provided, however, this paragraph shall not apply to any
              notification of a change that affects the Customer’s rights and
              obligations in a disadvantageous way.{" "}
            </p>
            <p>
              ③ The Customer must keep contact information (e.g., email address,
              mobile phone number, and landline phone number) up to date at all
              times, so that Company notifications can be received. Please note
              that the Company will not be held responsible for any
              disadvantages caused by failure to update the contact information.{" "}
            </p>
            <p>Article 8 (Type of Individual Services and Changes) </p>
            <p>
              ① The Company shall post the type, details, and prices of
              Individual Services, as well as details related to Individual
              Services on the Company Service website, including without
              limitation, the Terms, separate terms and conditions of Individual
              Service, operation rules, etc. The Customer must fully understand
              the details posted on the Company Service website and apply for
              the Individual Service based on the pertinent information.{" "}
            </p>
            <p>
              ② The Company can add or change the type or price of any
              Individual Service, provided, that, the Company will post any new
              information on the Company Service website or notify the Customer
              according to Article 7. For the avoidance of any doubt, if any
              change affects Customer rights and obligations in a
              disadvantageous way, the Customer will be notified according to
              the conditions specified in Article 7, and the Customer holds the
              rights specified in Article 3 Paragraph ⑤.{" "}
            </p>
            <p>
              ③ If any Service is terminated, the Company will notify the
              discontinuation or termination of the Service more than sixty (60)
              days prior to such service termination.{" "}
            </p>
            <p>Article 9 (Providing the Service) </p>
            <p>
              ① The Company will, in principle, provide the Service 24 hours a
              day, 7 days a week. Notwithstanding the foregoing, however, the
              Company may make an exception and limit part or all of the Service
              as necessary, such as for the purpose of conducing equipment
              checks, or if the Service cannot be used properly due to force
              majeure events, including without limitation, equipment failure or
              a spike in Service use.{" "}
            </p>
            <p>
              ② The Company may conduct a routine check if necessary for
              providing the Service, and the routine schedule shall adhere to
              the announcement on the Company Service website.{" "}
            </p>
            <p>Article 10 (Management of Customer Account) </p>
            <p>
              ① The Customer shall be responsible for managing its/his/her own
              Customer ID and Password, and shall be responsible for preventing
              any third-party use.{" "}
            </p>
            <p>
              ② If a Customer becomes aware that its/his/her ID and Password has
              been stolen, or is being used by a third party, the Customer shall
              notify the Company immediately and follow the Company’s
              guidelines.{" "}
            </p>
            <p>
              ③ The Company may restrict the use of any ID in the event there is
              a risk of unauthorized disclosure or leakage of personal
              information, or the ID misleads third parties to believe the ID is
              associated with the Company or the Company’s operator.{" "}
            </p>
            <p>
              ④ The Customer shall be responsible for any liabilities that arise
              from violating the obligations of this Article. The Company shall
              not be liable for any disadvantages that occur from the Customer
              violating the obligations of this Article or from not following
              the Company’s instructions or guidelines.{" "}
            </p>
            <p>
              ⑤ The Company may limit the number of accounts an individual
              Customer can create and hold in accordance with the Company’s
              policies. Further information will be posted through the Company
              Service website.{" "}
            </p>
            <p>Article 11 (Change of Customer Information) </p>
            <p>
              ① The Customer can check and modify its/his/her own personal
              information at any time on the personal information management
              page at the Company Service website. However, information required
              for service management, including, without limitation, the
              Customer’s corporate name, individual’s real name, business
              registration number or unique identification number cannot be
              modified.{" "}
            </p>
            <p>
              ② If the Customer’s information changes after application to use
              the Service is submitted, the Customer must modify the information
              on the Service website or notify the Company of the change using
              email or other methods.{" "}
            </p>
            <p>
              ③ The Company is not liable for any disadvantages caused by
              incorrect personal information provided by the Customer or the
              Customer’s failure to notify the Company of any changes, as
              specified in paragraph ② above.{" "}
            </p>
            <p>
              Article 12 (Assignment and Related Customer Information Change){" "}
            </p>
            <p>
              ① No Customer may, without the Company’s prior consent, transfer
              or assign or otherwise dispose, including without limitation,
              creation of encumbrance or donation, its/his/her rights and
              obligations under the Service Agreement or other rights to use the
              Service to any other person.{" "}
            </p>
            <p>
              ② However, if an automatic succession of the Customer’s rights and
              obligation under the Service Agreement by a third party (the
              “Successor”) is to occur by way of inheritance, merger,
              divestiture or like events, the Customer and the Successor must
              immediately notify the Company in accordance with the procedures
              determined by the Company together with documentation that can
              verify the succession of legal standing due to inheritance,
              business mergers, divestitures, etc.{" "}
            </p>
            <p>
              ③ In case of any change to the Customer information pursuant to
              paragraph ② above, it is the Successor’s responsibility to fully
              understand the legal obligations, responsibilities, and terms and
              conditions of this Term and the Service Agreement prior to actual
              succession. In case any trouble or problem occurs with respect to
              or arising from the succession, the Customer and its Successor
              shall be jointly and severally liable.{" "}
            </p>
            <p>
              Article 13 (Termination or Suspension of Service by the Company){" "}
            </p>
            <p>
              ① The Company can terminate or suspend a Customer’s right to use
              the Service without prior notice, in the following instances:{" "}
            </p>
            <p>
              1. If the Customer has not paid their bill by the end of the
              payment month{" "}
            </p>
            <p>
              2. If the Customer has transmitted or mediated large quantity of
              information or advertising information that can deteriorate the
              stable operation of the Service, or if similar action has occurred
              3. If operation of the Customer’s program on the Company’s server
              interrupts (or there is a risk of interruption of) other
              Customer’s system operation or results in failure or suspension
              (or there is a risk of such failure or suspension) of the
              Company’s operation of the Service{" "}
            </p>
            <p>
              4. If the network that provides the Service is affected due to
              abnormal excessive traffic related to the system operated by the
              Customer through the Service{" "}
            </p>
            <p>
              5. If security updates are not installed on the Customer’s server
              properly, which acts (or may act in the Company’s judgment), as a
              risk factor when the Company operates the Service{" "}
            </p>
            <p>
              6. If the Customer uses the Service for the purpose of hindering
              national or public interests{" "}
            </p>
            <p>
              7. If the Customer’s use of the Service violates applicable laws
              or acts against morals or public order{" "}
            </p>
            <p>
              8. If the Customer’s behavior harms the reputation of or causes
              disadvantages to others{" "}
            </p>
            <p>
              9. If the server used by the Customer is, or is suspected to be,
              hacked or becomes, or is suspected to be, infected by a virus{" "}
            </p>
            <p>
              10.If there are legal grounds to discontinue the Customer’s use of
              the Service, including, without limitation, when a third party
              files a report on infringement by Customer Contents{" "}
            </p>
            <p>
              11.If a government agency requests or orders temporary shutdown of
              the Service in accordance with legal procedures{" "}
            </p>
            <p>
              12.If the Customer access or uses the Service by evading the
              conditions to use the Service, the Terms or other restrictions
              stipulated by the Company (including generating several accounts
              in order to enjoy special benefits or free trial service).{" "}
            </p>
            <p>
              13.If the Customer breaches any applicable laws, the Terms or
              other conditions to using the Service set by the Company.{" "}
            </p>
            <p>
              ② If the Company suspends the Customer’s use of the Service in
              accordance with paragraph ① above, the Company must notify the
              affected Customer of the reason and usage suspension period, and
              formal objection means to the affected Customer. In addition, the
              Company will reinstate the Customer’s rights to use the Service
              without delay when the reason related to paragraph ① has been
              resolved or is no longer in effect{" "}
            </p>
            <p>
              ③ The Company may terminate the Service Agreement if the cause for
              suspension continues not to be remedied. In such case, the Company
              will notify the Customer using the methods specified in Article 7.{" "}
            </p>
            <p>
              ④ The Company may invoice Fees accrued during the period of
              Service suspension occurring resulting from causes described in
              this Article 13.{" "}
            </p>
            <p>Article 14 (Temporary Suspension of Service) </p>
            <p>
              ① The Company can temporarily suspend the Service in the following
              instances:{" "}
            </p>
            <p>
              1. For unavoidable reasons, including without limitation
              termination/cancelation of the agreements with the third party
              related to the provision of the service, unavoidable equipment
              repair and maintenance reasons{" "}
            </p>
            <p>
              2. If there is a risk that normal service operation can be
              hindered, such as national emergency, failure of Service
              equipment, or a spike in service usage{" "}
            </p>
            <p>
              3. If the Company cannot in practice provide stable Service due to
              unavoidable reasons, such as natural disasters{" "}
            </p>
            <p>
              4. If the telecommunication service provider (as defined in the
              Telecommunications Business Act) has discontinued its
              telecommunications service.{" "}
            </p>
            <p>
              ② The Company must notify Customer of any Service suspension in
              case the event described in section 1. of paragraph ① above occurs
              in advance using the notification method registered by the
              Customer or by posting on the Service access screen or the Company
              Service website; provided, however, it is not the Company’s
              obligation to notify in advance in case it is impossible to notify
              due to the Service suspension occurred from the reasons the
              Company cannot control (such as problems in facilities and systems
              without the Company’s intentional or negligent acts, etc.).{" "}
            </p>
            <p>
              ③ The Company may temporarily suspend the Service without prior
              notice in case any events described in sections 2., 3. or 4. of
              paragraph ① above occurs.{" "}
            </p>
            <p>
              Article 15 (Suspension or Termination of Service by the Customer){" "}
            </p>
            <p>
              ① If a Customer desires to suspend or terminate the Service
              Agreement, the Customer must notify the Company via the procedures
              and methods posted on the Service website. The Company shall
              suspend the Service according to the suspension schedule or
              terminate the Service Agreement on the date requested by the
              Customer under normal conditions. However, if there are any unpaid
              Fees due on the day the Customer has requested termination, the
              termination procedure will be completed only after outstanding
              payment is made in full.{" "}
            </p>
            <p>
              ② In the event that the Customer temporarily suspends the Service
              Agreement during the use of the Service, the permissible
              suspension period, the Fee during the suspension period,
              conditions of suspension, and the effectiveness of suspension,
              etc. shall be determined according to the details posted on the
              website of the Service.{" "}
            </p>
            <p>
              ③ In the event that Customer stops the use of servers as part of
              the suspension of the Service usage set forth in paragraph ②
              above, (the “Server Suspension”), the Service may be suspended for
              up to a maximum of ninety (90) days at a time and up to one
              hundred and eighty (180) cumulative days within a twelve(12)-month
              period. If a Customer does not use the server and exceeds the
              maximum period without the express consent of the Company, the
              Company may delete (reclaim) the server after notifying the
              Customer. In this case, Customer’s data stored on the server shall
              be backed up by the Company for (30) days and then deleted. The
              Customer may request to reuse the server and data recovery only
              within the aforementioned storage period. Server types for which
              the Server Suspension is possible and the Fees accruing during the
              suspension period shall be according to the information posted on
              the website of the Service.{" "}
            </p>
            <p>
              ④ The Customer must manually back up their data saved in the
              server that they have used during the Service usage period before
              terminating the Service Agreement. The Company will delete all
              servers and resources of the Customer immediately upon termination
              of the Service Agreement. The deleted server and resources will
              not be restored for any reason.{" "}
            </p>
            <p>
              Article 16 (Termination of the Service Agreement at Company’s
              Discretion){" "}
            </p>
            <p>
              ① The Company may terminate the Service Agreement at its sole
              discretion, without further discussion with the Customer, in the
              following instances:{" "}
            </p>
            <p>
              1. If the Customer does not take proper actions within the period
              stipulated in the Service Agreement regarding outstanding Fees{" "}
            </p>
            <p>
              2. If the information in the application form to use the Service
              is found to be false{" "}
            </p>
            <p>
              3. If the Customer inflicts losses upon the Company by
              intentionally disturbing the Company’s Service operations{" "}
            </p>
            <p>
              4. If the Customer uses the Company's Services for the purpose of
              cryptocurrency mining{" "}
            </p>
            <p>
              5. If the Customer violates the Customer’s obligations stipulated
              in Article 26 (Customer’s Obligations) and fails to cure such
              breach within a reasonable period after the Company requests the
              Customer to cure such breach{" "}
            </p>
            <p>
              6. If the Customer fails to take appropriate actions within a
              reasonable period after requests are made by the Company under
              Article 13 (Termination or Suspension of Service){" "}
            </p>
            <p>
              7. If a government agency requests termination of the Service via
              due legal procedures because the Customer’s purpose and method of
              using the Service violate domestic or international laws{" "}
            </p>
            <p>
              8. If it is necessary, under Privacy and Information Processing
              Policy on Personal Information Retention Period, to remove
              personal information the Customers, who has not used the Service
              for one year or longer{" "}
            </p>
            <p>
              ② If an event described in paragraphs 1, 2, 4, or 5 occurs, the
              Company can request the Customer to resolve the problem within a
              specified period. Notwithstanding the foregoing, however, if the
              Customer fails to take necessary actions to resolve the problem,
              the Company may terminate the Service Agreement.{" "}
            </p>
            <p>
              ③ In case any Individual Service is not used for a period of one
              (1) year or longer since the signing of Service Agreement, the
              Company may terminate the service agreement regarding the
              Individual Service by giving prior notice in accordance with
              Article 7.{" "}
            </p>
            <p>Article 17 (Usage Limitations on a Spam Sender) </p>
            <p>
              ① The Company may restrict the Service or terminate the Service
              Agreement if the Customer using falls under the following
              categories, related to sending Spam mail (for the purpose of the
              Terms, the advertisement information for the purpose of making
              profit transmitted or posted unilaterally by contradicting the
              overt intention of the recipient to block the sender shall be
              referred to as the "Spam"):{" "}
            </p>
            <p>
              1. If the Korea Communications Commission or the Korea Internet &
              Security Agency has detected the Customer’s Spamming activities
              and requests usage suspension{" "}
            </p>
            <p>
              2. If the Customer has sent a large quantity of Spam and caused
              system failure of the Service, or if there is a risk of such
              failure{" "}
            </p>
            <p>
              3. If the receiver of the Spam requests to be unsubscribed but the
              Customer continues to send Spam.{" "}
            </p>
            <p>
              ② If the Customer does not respond to the Company’s request
              regarding actions against Spam complaint, the Customer shall be
              charged with civil and criminal liabilities for advertising
              information for commercial purposes sent or posted unilaterally
              against the receiver’s explicit expression of rejection.
            </p>
            <h4>Chapter 3. Fee and Management </h4>
            <p>Article 18 (Fees for Using the Service)</p>
            <p>
              ① The Company shall post the details of the Fees and its
              modification on the Service website.{" "}
            </p>
            <p>
              ② The Services are mainly composed of fixed term service and
              non-fixed term service.{" "}
            </p>
            <p>
              ③ Even if the Fees are changed during the term of the Service
              Agreement, fees are not applied retroactively to the entire term
              of the Service Agreement, unless a special reason requires
              otherwise.{" "}
            </p>
            <p>Article 19 (Calculation and Settlement of the Service Fee) </p>
            <p>
              ① The Service Fee will be billed by calculating on monthly basis,
              from the first day of every month to the end of the month. ② If
              the Customer’s use of the Service commences in the middle of any
              calendar month, Fees shall be calculated based on a per diem basis
              from the date Service commences until the last day of the
              applicable month.{" "}
            </p>
            <p>Article 20 (Termination Charge) </p>
            <p>
              ① The Customer must pay a termination charge to the Company, if
              the Service Agreement is terminated due to Article 13 paragraph 3,
              Article 15 or Article 16, or other reasons attributable to the
              Customer. The termination charge shall be calculated as follows:{" "}
            </p>
            <p>
              1. If terminated during the agreed upon term termination charge =
              [non-fixed term monthly Fee] x [period of use] x [(discount rate
              applied to fixed term agreement –discount rate of the usage
              period)].{" "}
            </p>
            <p>
              2. If changing to a shorter term contract during the term of
              agreed upon contract term termination charge = [non-fixed term
              monthly fee] x [period of use] x [(existing fixed term discount
              rate) (new fixed term discount rate)].{" "}
            </p>
            <p>
              ② The Customer can terminate the Service Agreement without paying
              the foregoing termination charge in the following circumstances:{" "}
            </p>
            <p>
              1. If the accumulated failure time of the service exceeds seventy
              (72) hours within a month due to reasons attributable to the
              Company{" "}
            </p>
            <p>
              2. If service failure lasting more than an hour has occurred more
              than five (5) times within a month due to reasons attributable to
              the Company.{" "}
            </p>
            <p>Article 21 (Payment of the Fee and Billing) </p>
            <p>
              ① The Company will issue an invoice to a Customer for the Fee for
              the current month in the following month and the Customer shall
              pay the invoiced amount on or before the payment due date
              specified by the Company in advance.{" "}
            </p>
            <p>② Payment methods for Fees are described below: </p>
            <p>
              1. Domestic (Korean) Corporate or Public Organization Customers:
              Credit card, bank account transfer, or virtual account transfer{" "}
            </p>
            <p>
              2. Customers not specified in paragraph 1. above: Credit card{" "}
            </p>
            <p>
              ③ The Company shall send an invoice for the Service fee in advance
              to ensure that the Customer receives it at least five (5) days
              prior to the payment due date.
            </p>
            <p>
              ④ If the Customer does not pay the Fee by the due date, the
              Company shall notify the Customer of the unpaid fee via email.
              Additional charges will be added on the unpaid Fee (2% of the
              unpaid amount every month) and invoiced again.
            </p>
            <p>
              ⑤ If the Customer does not pay the Fee by the end of the following
              month, the Company may terminate the Service according to the
              procedures and methods in the Terms and posted on the Service
              website.{" "}
            </p>
            <p>Article 22 (Responsibility for Paying the Fee) </p>
            <p>
              The Customer that is approved for the usage account will bear the
              responsibility for paying the Service Fee. However, if the
              Customer has designated a person responsible for paying the Fee,
              then the Customer and the designated person shall bear joint
              responsibility for paying the Fee. In such case the Customer shall
              be responsible for submitting the designated person’s written
              consent to the bear joint responsibility for the payment of the
              Fees
            </p>
            <p>Article 23 (Objections to Invoice) </p>
            <p>
              ① If the Customer has any disagrees with any invoiced Fee, the
              Customer can file a formal objection against the Company in
              writing (including via email).{" "}
            </p>
            <p>
              ② The Company must review the formal objection in paragraph ① and
              notify the Customer with the Company’s decision within seven (7)
              Business Days of receiving the formal objection. If the Company
              cannot notify the Customer of the decision regarding the objection
              above within the designated period, the Company must notify the
              Customer of the reason for the delay.{" "}
            </p>
            <p>Article 24 (Refunding of Fees) </p>
            <p>
              ① If the Company finds that the Customer has paid the Fee
              excessively or incorrectly, the Company can offset the excessive
              or incorrect payment amount against the next month’s Fee of the
              Customer.{" "}
            </p>
            <p>
              ② If the Customer requests a refund of the excessive or incorrect
              payment amount prior to the Fees being offset in accordance with
              paragraph ①, the Company shall return the amount to the Customer.{" "}
            </p>
            <h4>Chapter 4. Responsibilities of the Parties</h4>
            <p>Article 25 (Obligations of the Company) </p>
            <p>
              ① The Company strives to provide the Service requested by the
              Customer in a stable and continuous manner.{" "}
            </p>
            <p>
              ② If failure occurs that hinders the normal operation of the
              Service, the Company will repair or restore the Service as soon as
              practicably possible and aims to operate the Service stably.{" "}
            </p>
            <p>
              ③ The Company will process the opinions or complaints justly
              raised by the Customer immediately or within the period and
              according to the procedures set by the Company.{" "}
            </p>
            <p>
              ④ The Company can collect and retain the Customer’s personal
              information according to the privacy policy posted on the Service
              website in order to operate the Service smoothly. The Company may
              not provide the Customer’s personal information to a third party
              without the Customer’s consent. However, an exception can be made
              if the Company is requested to provide the Customer’s personal
              information if required under applicable laws, including without
              limitation, warrant issued by a court or other legal agency for
              the purpose of investigation in accordance with relevant laws and
              regulations.{" "}
            </p>
            <p>
              ⑤ The Company will not access Customer Contents or handle data for
              any purpose deviating from those set forth in the Terms. However,
              Customer Contents may be accessed and handled if it is required to
              provide a smooth service, such as handling errors and failures or
              protection of Customer Contents.{" "}
            </p>
            <p>
              ⑥ The Company will obtain Customer’s consent if the Company needs
              to delete or change Customer information obtained in accordance
              with paragraph ⑥ above in order to provide smooth Service. If
              there is no Customer consent or if the Customer refuses, the
              Company may suspend the Company’s operation of the Service or the
              Customer’s use of the Service in accordance with the procedures
              set forth in Article 13 paragraphs ② to ④ (inclusive) above if the
              operation of the Service is disrupted.{" "}
            </p>
            <p>Article 26 (Obligations of the Customer) </p>
            <p></p>
            <p>
              ① The Customer has an obligation to pay the Fees on or before the
              specified due date.{" "}
            </p>
            <p>
              ② The Customer may not disturb the Company’s operation of the
              Service, another Customer’s use of the Service or violate any
              third party’s rights. The Company may install and operate the menu
              on its Service website, receiving reports illegal acts of the
              Customer.{" "}
            </p>
            <p>
              ③ The Customer may not use the Service website or any bulletin
              board run by the Company to distribute, post, or link obscene
              information, illegal information, harmful information, or illegal
              gambling information. The Customer may not use the Service to
              commit acts in violation of domestic or international laws. In
              addition, the Customer shall be responsible for all matters
              related to the Customer’s use of the Service including without
              limitation, ownership and management of Customer’s service and
              products or Customer Contents.{" "}
            </p>
            <p>
              ④ In the event that the Customer processes, manages, uses or
              otherwise accesses any personal information of a third party using
              Service, the Customer shall be responsible for managing and
              protecting such personal information pursuant to relevant laws and
              regulations, and the Company shall bear no responsibilities or
              liabilities for any related consequences, losses, or damages
              including without limitation, leakage of the third-party’s
              information.{" "}
            </p>
            <p>
              ⑤ The Customer must perform periodic security updates related to
              system operation for the purpose of protecting servers operated by
              the Customer through the Service against intruders. However,
              unless the Customer has a separate agreement with the Company
              regarding system security service, the Company bears no
              responsibility for security accidents. The Company can perform
              security control to take security measures related to the
              Customer’s use of the Service and will notify the Customer of any
              such actions and their result, in addition to demanding additional
              measures on the part of the Customer as necessary to strengthen
              security. The Customer must conform to the Company’s request for
              measures to be taken to strengthen security.{" "}
            </p>
            <p>
              ⑥ The Customer must obtain and retain the rights related to its
              sever and software programs operated by the Customer through the
              Service and be fully responsible for any copyright issues related
              to servers operated through the Service.{" "}
            </p>
            <p>
              ⑦ If the Customer inflicts any damage to other Customers or a
              third party by distributing or sending illegal software or spam
              emails via the Service, the Company shall bear no responsibility
              and the Customer shall indemnify and hold the Company harmless
              against all obligation, losses, damages, claim raised against the
              Company.{" "}
            </p>
            <p>
              ⑧ The Customer may not send or post data containing software
              viruses designed to disturb or destroy the stable operation of the
              Service, other computer codes, files, or programs.{" "}
            </p>
            <p>
              ⑨ The Customer shall be responsible for fully understanding,
              familiarizing and adhering to applicable laws, the Terms,
              guidelines and precautions published on the Service website and
              instructions of the Company. The Customer may not otherwise
              interfere with any business of the Company.{" "}
            </p>
            <p>
              ⑩ The Customer has an obligation to back up and store data that
              the Customer is handling using the Service and the Customer must
              bear any losses caused by negligent data management. Provided,
              however, if the Customer applies for and uses a separate backup
              service offered by the Company, the Company may be liable for data
              mismanagement to the extent specified in the terms and conditions
              of the backup service.{" "}
            </p>
            <p>
              ⑪ The Customer may not re-sell or sublease the Service to any
              third party without the prior consent of the Company.{" "}
            </p>
            <h4>Chapter 5. Indemnification of Damages</h4>
            <p>Article 27 (Company’s Indemnification of Damages) </p>
            <p>
              ① If the Customer suffers any damages related to the operation of
              the Individual Service because the Company provided less than
              99.95% of the Monthly Availability Rate (defined below), due to
              any fault attributable to the Company, the Company shall be liable
              for damages in accordance with the following criteria, when the
              Customer claims indemnification.{" "}
            </p>
            <p>
              1. Monthly Availability Rate (%) = 100ⅹ[1- Total duration of
              interruption of each Service (in minutes) during one month of
              using each Service, resulting from faults attributable to the
              Company/1 month (in minutes) during which the Service was used]{" "}
            </p>
            <p>
              2. Duration of Interruption: The duration of interruption is
              measured from the moment the Customer notifies the Company of
              their inability to use the Service. If the Company becomes aware
              of an interruption before being notified by the Customer, the
              duration is measured from the moment the Company acknowledges the
              interruption.{" "}
            </p>
            <p>
              3. Monthly Fee: It is an individual service charge among prices
              subject to discount to which the monthly availability rate is
              applied due to the service failure caused by any fault
              attributable to the Company. The same shall apply in Article 27
              herein. Monthly Availability Rate per Individual Service Damages
              Factoring in Discount Rate (%) 99.0(or more)– (less than) 99.95%
              10% of the monthly Fee 95.0 (or more)–99.0% 25% of the monthly Fee
              (Less than) 95.0% 50% of the monthly Fee{" "}
            </p>
            <p>
              ② Notwithstanding paragraph ① above, in the event of damage to
              File Storage, Object Storage, NAS, CDN, and Global CDN services,
              the Company shall compensate for damages the Customers suffered in
              accordance with the table below. Monthly Availability Rate per
              Individual Service Damages Factoring in Discount Rate (%) 99.0 (or
              more) – (less than) 99.9% 10% of the monthly Fee (Less than) 99.0%
              25% of the monthly Fee{" "}
            </p>
            <p>
              ③ The Customer, when claiming indemnification of damages shall
              submit a written claim for damages to the Company that specifies
              the reason for the claim, the claimed amount, and the basis for
              the damage calculation. The Company may offset damages amount due
              to the Customer against the Customer’s monthly Fee.{" "}
            </p>
            <p>Article 28 (Customer’s Indemnification of Damages) </p>
            <p>
              ① The Customer agrees to indemnify, defend and hold harmless the
              Company and its affiliates, its respective directors, officers,
              employees, agents, contractors and licensors harmless against any
              damages or losses in relation to any claims arising out of:{" "}
            </p>
            <p>
              1. The Customer’s breach or failure to comply with the Terms, the
              applicable laws and regulations{" "}
            </p>
            <p>
              2. Any action taken by the Company as part of the Company’s
              investigation of a suspected breach of the Terms or as a result of
              a finding or decision that a breach of the Terms has occurred.{" "}
            </p>
            <p>
              ② The damages above shall include, but shall not be limited to,
              the Company’s legal costs, expenses and/or damages arising in
              respect of the abovementioned claims.{" "}
            </p>
            <p>
              ③ Article 27 shall apply to the process the Company claims
              indemnification of damages against the Customer.{" "}
            </p>
            <p>Article 29 (Limitation of Liabilities) </p>
            <p>
              ① Notwithstanding anything to the contrary, to the maximum extent
              permitted by applicable law, the Company shall not bear any
              responsibility for any loss or damage, whether in contract, tort
              (including negligence), even if foreseeable, caused by the
              following causes:{" "}
            </p>
            <p>1. Force majeure, such as a natural disaster or war </p>
            <p>2. Customer’s intentional act or omission and negligence </p>
            <p>
              3. Failure of telecommunication services provided by any service
              provider other than the Company{" "}
            </p>
            <p>
              4. Discontinuation of the Service after giving prior notice due to
              an unavoidable situation, including without limitation, check-up
              of the Service, to the extent the Company did not intentionally
              cause the discontinuation or the Company was not grossly negligent
              in causing the discontinuation{" "}
            </p>
            <p>
              5. If the Company has discontinued the Service due to an announced
              routine check, as stated under Article 9{" "}
            </p>
            <p>
              6. If the Customer’s use exceeds the scope of the Service defined
              in the Service Agreement{" "}
            </p>
            <p>
              7. If damages suffered by any third party are caused by inaccuracy
              or illegitimacy of information, materials or facts posted on the
              Customer’s service{" "}
            </p>
            <p>
              8. If an intrusion has occurred as the result of the Customer’s
              failure to adequately managed system security{" "}
            </p>
            <p>
              9. If the Service is discontinued due to national emergency,
              nationwide network failure, or an equivalent force majeure{" "}
            </p>
            <p>
              10.If the Service is stopped to prevent the spread of an incident
              that has occurred in the system of a Customer using the Service{" "}
            </p>
            <p>
              11.If a failure occurs on the Service due to an illegal intrusion
              from outside despite the Company taking protective measures
              pursuant to applicable laws{" "}
            </p>
            <p>
              12.If a failure occurs on the Service due to an illegal intrusion
              from outside despite the Company taking protective measures
              pursuant to applicable laws{" "}
            </p>
            <p>
              13.If a failure occurs due to equipment, software, application or
              OS randomly installed by the Customer on the Company’s service{" "}
            </p>
            <p>14.If the Customer is using the Service free of charge. </p>
            <p>
              ② THE MAXIMUM AGGREGATE LIABILITY OF THE COMPANY FOR ALL DAMAGES,
              LOSSES AND CAUSES OF ACTION, WHETHER IN CONTRACT, TORT (INCLUDING
              NEGLIGENCE) OR OTHERWISE, WILL BE GREATER OF (A) THE TOTAL AMOUNT,
              IF ANY, PAID BY THE CUSTOMER TO THE COMPANY TO USE THE SERVICE IN
              THE SIX (6) MONTH PERIOD IMMEDIATELY PRIOR TO THE DATE OF THE
              EVENT GIVING RISE TO THE LAST CAUSE OF ACTION TO ARISE UNDER THESE
              TERMS AND (B) ONE THOUSAND DOLLARS (USD $1000). ALL LIMITATIONS OF
              LIABILITY OF ANY KIND (INCLUDING IN THIS PROVISION AND ELSEWHERE
              IN THESE TERMS) ARE MADE FOR THE BENEFIT OF BOTH THE COMPANY AND
              ITS AFFILIATES, AND THEIR RESPECTIVE SUCCESSORS AND ASSIGNS. SOME
              JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
              INCIDENTAL OR CONSEQUENTIAL DAMAGES FOR SOME PRODUCTS, SO THE
              EXCLUSIONS OR LIMITATIONS MAY NOT APPLY TO THE CUSTOMER.{" "}
            </p>
            <p>Article 30 (Governing Law and Jurisdiction) </p>
            <p>
              ① The Terms will be governed by the laws of the Republic of Korea,
              without regard to its principles of conflicts of law and
              regardless of the Customer’s location.{" "}
            </p>
            <p>
              ② Each of Customer and Company hereby irrevocably consents to the
              exclusive jurisdiction and venue in the Seoul Central District
              Court, and waives any jurisdictional, venue or inconvenient forum
              objection to such court. Notwithstanding the foregoing, the Seoul
              Central District Court’s decision and holding shall be enforceable
              in any court at Customer’s or Company’s location.{" "}
            </p>
            <p>Article 31 (Disclaimer) </p>
            <p>
              ① TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE SERVICES
              ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, AND THE
              COMPANY HEREBY EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES, IMPLIED
              OR STATUTORY, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
              CONDITION, QUALITY, DURABILITY, PERFORMANCE, ACCURACY,
              RELIABILITY, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE,
              AND NON-INFRINGEMENT, OR AS TO THE SERVICES BEING UNINTERRUPTED,
              ERROR FREE, FREE OF HARMFUL COMPONENTS, SECURE, OR NOT OTHERWISE
              CAUSING DAMAGE OR LOSS OF FUNCTIONALITY OR DATA.{" "}
            </p>
            <p>
              ② TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE COMPANY
              MAKES NO REPRESENTATIONS OR WARRANTIES ABOUT THE VALIDITY,
              ACCURACY, RELIABILITY, QUALITY, STABILITY, COMPLETENESS OR
              CURRENTNESS OF ANY INFORMATION PROVIDED ON OR THROUGH THE SERVICE.
              SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED
              WARRANTIES OR LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS,
              SO THE ABOVE EXCLUSIONS OR LIMITATIONS MAY NOT APPLY TO THE
              CUSTOMER.{" "}
            </p>
            <p>Article 32 (Severability) </p>
            <p>
              If any term herein is adjudicated by a court or tribunal of
              competent jurisdiction to be void or unenforceable, the validity
              or enforceability of the remainder of the terms herein shall
              remain in full force and effect.
            </p>
            <p>Article 33 (No Waiver) </p>
            <p>
              The Company’s failure to enforce any right or failure to act with
              respect to any breach by the Customer under the Terms will not
              waive that right nor waive the Company’s right to act with respect
              with subsequent or similar breaches.{" "}
            </p>
            <p>Article 34 (Language) </p>
            <p>
              All communications and notices made or given pursuant to the Terms
              shall be in the Korean language. Provided, that, the Company may
              provide English translation of the communications and notices for
              convenience purpose only. In case the Company provides an English
              language translation of the notices and/or the Terms, the Korean
              text of such notices and/or the Terms will control in case of any
              conflict. Notwithstanding the foregoing, however, the Customer may
              communicate or give notice to the Company in either the Korean or
              English language.
            </p>
            <p style={{ fontStyle: "italic" }}>
              These terms and conditions shall take effect from Sep 06, 2019.
            </p>
          </div>
        </section>
      </>
    );
  }
}

export default TermsConditions;
