import React, { Component } from "react";
import CountUp from "react-countup";
import styled from "styled-components";
import { Row, Col } from "antd";
import Fade from "react-reveal/Fade";
import { Text } from "../../assets/common";

export default function Counter(props) {
  return (
    <Container>
      <F1>
        <F2>
          <Text big color="#fff" fontWeight={600}>
            <CountUp end={750} duration={4} suffix="K+" />
          </Text>
          <Text color="#fff" fontWeight="500">{`Daily Active Users`}</Text>
        </F2>
        <F2>
          <Text big color="#fff" fontWeight={600}>
            <CountUp end={800} duration={4} suffix="+" />
          </Text>
          <Text color="#fff" fontWeight="500">{`Enterprise Customers`}</Text>
        </F2>
      </F1>
      <F1>
        <F2>
          <Text big color="#fff" fontWeight={600}>
            {`No.1`}
          </Text>
          <Text
            color="#fff"
            fontWeight="500"
          >{`Market share In\nBusiness IM Korea`}</Text>
        </F2>
        <F2>
          <Text big color="#fff" fontWeight={600}>
            <CountUp end={6} duration={10} suffix="+" />
          </Text>
          <Text
            color="#fff"
            fontWeight="500"
          >{`ASIA countries Use\n UCWORKS`}</Text>
        </F2>
      </F1>
      <F1>
        <F2>
          <Text big color="#fff" fontWeight={600}>
            <CountUp end={100} duration={4} suffix="%" />
          </Text>
          <Text
            color="#fff"
            fontWeight="500"
          >{`Interfaced with\n Major Global IP-PBX`}</Text>
        </F2>
      </F1>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  background-color: orange;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const F1 = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  padding: 5vw 0;
`;

const F2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5vw;
  width: 20vw;

  @media (max-width: 768px) {
    width: 50vw;
  }
`;
