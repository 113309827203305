import React, { useState, useEffect } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button, Input, Basement, useInput, Text } from "../../assets/common";
import { useHistory } from "react-router-dom";
import { ruleSchema } from "../../assets/common/util/ruleSchema";
import { useDispatch } from "react-redux";
import { configActions } from "../../assets/common/reducer/config";
import useMeasure from "react-use-measure";
import RequestPay from "../../assets/utils/RequestPay";
import axios from "axios";
import { SERVER_URI, header } from "../../assets/utils/HttpHandler";
import { ResizeObserver } from "@juggle/resize-observer";

export default function PaymentInfomation(props) {
  const {
    setSuccess,
    paymentInfo,
    paymentInfo: {
      workspaceRestCode,
      currentMember,
      currentMemberPrice,
      price_rest_code,
      teamName,
      systemLicense,
      validEndDate,
      maxMember,
      memberCount,
    },
    setPaymentInfo,
    setStep,
    isFree,
  } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [wishMember, setWishMember] = useState(currentMember + 1);
  const dispatch = useDispatch();
  const [adminNameValid, setAdminNameValid, adminName, setAdminName] = useInput(
    paymentInfo.adminName,
    ruleSchema.adminName.rule,
    null
  );
  const [
    adminEmailValid,
    setAdminEmailValid,
    adminEmail,
    setAdminEmail,
  ] = useInput(paymentInfo.adminEmail, ruleSchema.adminEmail.rule, null);

  const [ref, bounds] = useMeasure({ polyfill: ResizeObserver });
  useEffect(() => {
    dispatch(configActions.config.expand.on(bounds.height));

    return () => {
      dispatch(configActions.config.expand.off());
    };
  }, [bounds.height]);

  useEffect(() => {
    setPaymentInfo((prev) => ({
      ...prev,
      wishMember,
      totalPrice: (wishMember - currentMember) * currentMemberPrice * 1000,
    }));
  }, [wishMember]);

  const pay = (param) => {
    setLoading(true);

    RequestPay(param, async (res, data = undefined) => {
      if (res) {
        try {
          await axios.post(`${SERVER_URI}/api/order/license`, data, header());
          setSuccess(true);
          setStep((prev) => prev + 1);
        } catch (error) {
          setStep((prev) => prev + 1);
        }
      } else {
        setStep((prev) => prev + 1);
      }
    });
  };

  const handleSubmit = () => {
    let valid = true;
    if (!adminNameValid || !adminName.length) {
      setAdminNameValid(false);
      valid = false;
    }
    if (!adminEmailValid || !adminEmail.length) {
      setAdminEmailValid(false);
      valid = false;
    }

    if (valid) {
      if (isFree) {
        const skip = async () => {
          const payload = {
            count: Number(`${wishMember}`),
            workspaceRestCode: `${workspaceRestCode}`,
          };

          const response = await axios.post(
            `${SERVER_URI}/api/user/license/addFreeMember`,
            payload,
            header()
          );

          if (response.data) {
            setSuccess(true);
            setStep((prev) => prev + 1);
          } else {
            setStep((prev) => prev + 1);
          }
        };

        skip();
      } else {
        pay(param);
      }
    }
  };

  const handleCancel = () => {
    history.push(`/workspace/detail/${workspaceRestCode}`);
  };

  const param = {
    imp: {
      pg: "html5_inicis",
      pay_method: "card",
      name: `${systemLicense}`,
      amount: Number(paymentInfo.totalPrice),
      buyer_email: `${adminEmail.trim()}`,
      buyer_name: `${adminName}`,
      currency: `KRW`,
      language: `ko`,
      m_redirect_url: `${SERVER_URI}/payment/license/complete`,
    },
    portal: {
      price: Number(paymentInfo.totalPrice),
      orderFlag: "N",
      count: Number(`${wishMember - currentMember}`),
      workspaceRestCode: `${workspaceRestCode}`,
      priceRestCode: `${paymentInfo.wishPriceRestCode}`,
      targetLicenseRestCode: "",
      userOrderPg: {
        name: `Add Member (${systemLicense})`,
        price: Number(paymentInfo.totalPrice),
        currency: `KRW`,
        buyerName: `${adminName}`,
        buyerEmail: `${adminEmail.trim()}`,
      },
    },
  };

  return (
    <>
      <Basement>
        <Text big color="black">
          {t("paymentInfo.information")}
        </Text>
        <Input
          type="text"
          label={t("paymentInfo.teamName")}
          current={teamName}
          disabled
        />
        <Input
          type="text"
          label={t("paymentInfo.planInfo")}
          current={systemLicense}
          disabled
        />
        <Input
          type="text"
          label={t("paymentInfo.date")}
          current={moment(Number(validEndDate)).format("YYYY-MM-DD")}
          disabled
        />
        <Input
          type="text"
          label={t("paymentInfo.pricePerMember")}
          current={`₩${Number(currentMemberPrice * 1000).toLocaleString()}`}
          disabled
        />
        <Input
          type="number"
          label={`${t("paymentInfo.wishMemberNumber")} (${t(
            "paymentInfo.current"
          )}: ${currentMember})`}
          current={wishMember}
          callback={(next) => setWishMember(next)}
          valid={true}
          min={currentMember}
          max={maxMember}
          rangeSensitive={true}
        />
        <Input
          type="text"
          label={t(ruleSchema.adminName.label)}
          errorMsg={t(ruleSchema.adminName.errorMsg)}
          current={adminName}
          callback={(next) => {
            setAdminName(next);
          }}
          valid={adminNameValid}
        />
        <Input
          type="text"
          label={t(ruleSchema.adminEmail.label)}
          errorMsg={t(ruleSchema.adminEmail.errorMsg)}
          current={adminEmail}
          callback={(next) => {
            setAdminEmail(next);
          }}
          valid={adminEmailValid}
        />
      </Basement>
      <F1 ref={ref}>
        <F2>
          <F3>
            <F4>
              <Text small textAlign="right">
                {t("paymentInfo.payPrice")}
              </Text>
              <TextExtended mid color="black">{`₩${Number(
                paymentInfo.totalPrice
              ).toLocaleString()}`}</TextExtended>
            </F4>
          </F3>
          <F3_1>
            <Button onClick={handleSubmit} loading={loading}>
              {t("_common.checkout")}
            </Button>
            <Button onClick={handleCancel}>{t("_common.cancel")}</Button>
          </F3_1>
        </F2>
      </F1>
    </>
  );
}

const F1 = styled.div`
  height: 10rem;
  bottom: 0;
  width: 100%;
  position: fixed;
  background-color: #fff;
  border-top: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const F2 = styled.div`
  width: 970px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const F3 = styled.div`
  /* width: 90%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const F3_1 = styled.div`
  margin: 1rem 1rem 0 1rem;
  padding: 0.5rem 0;
`;

const F4 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: auto 0;
`;

const TextExtended = styled(Text)`
  padding: 0 0.5rem;
`;
