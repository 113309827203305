import React from "react";
import { useTranslation } from "react-i18next";
import { MessageOutlined, PhoneOutlined } from "@ant-design/icons";
import { Basement, Text, Table, Title } from "../assets/common";

export default function CallCenter() {
  const { t } = useTranslation();

  const column = [
    { label: t(`_common.dept`), type: `text` },
    { label: t(`_common.name`), type: `text` },
    { label: t(`_common.email`), type: `text` },
    { label: t(`_common.phone2`), type: `text` },
    { label: t(`_common.phone`), type: `text` },
  ];

  const p1 = [
    {
      dept: t("callCenter.3"),
      name: `Lim Eunyeong`,
      email: `cs@ucware.net`,
      phone: `031-525-3657`,
      phone2: `-`,
    },
    {
      dept: t("callCenter.4"),
      name: `Gwon Sanghyeon`,
      email: `cs@ucware.net`,
      phone: `031-525-3641`,
      phone2: `010-3996-0213`,
    },
    {
      dept: t("callCenter.5"),
      name: `Ryu Hyanggeol`,
      email: `cs@ucware.net`,
      phone: `031-525-3640`,
      phone2: `010-9682-0468`,
    },
    {
      dept: t("callCenter.6"),
      name: `Lee Jiho`,
      email: `cs@ucware.net`,
      phone: `031-525-3659`,
      phone2: `010-9463-0894`,
    },
  ];

  return (
    <>
      <Title title={t(`_title.helpCenter`)} src="/images/serBg.jpg" />
      <Basement>
        {/* <Text big color="black" textAlign="left">
          {t("callCenter.1")}
        </Text> */}
        <Table column={column} payload={p1} shadow />
      </Basement>
    </>
  );
}
