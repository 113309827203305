/* eslint no-restricted-globals: ["off"] */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { SERVER_URI, header } from "../assets/utils/HttpHandler";
import { Link, useParams, useHistory } from "react-router-dom";
import { Spin } from "antd";
import { Modal, Checkbox, message } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import WorkspaceBillingHistory from "../components/Workspace/WorkspaceBillingHistory";
import styled from "styled-components";
import {
  Button,
  Text,
  Basement,
  Tab,
  useTab,
  Progress,
  delay,
  HistoryNavigation,
} from "../assets/common";
import PageLoading from "../assets/utils/PageLoading";

export default function WorkspaceDetail() {
  const { t } = useTranslation();

  const [loadingPage, setLoadingPage] = useState(true);
  const [isNoLicense, setIsNoLicense] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [serverPublicIp, setServerPublicIp] = useState('');

  const [generalInfo, setGeneralInfo] = useState({});
  const [licenseInfo, setLicenseInfo] = useState({});
  const [syncUp, setSyncUp] = useState(false);
  const { workspaceRestCode } = useParams();
  const history = useHistory();
  const [tabBoxIdx, setTabBoxIdx] = useState(0);
  const { confirm } = Modal;

  useEffect(() => {
    setLoadingPage(true);

    axios
      .get(`${SERVER_URI}/api/workspace/${workspaceRestCode}`, header())
      .then((res) => {
        const data = res.data;

        console.log("data:", data);
        setGeneralInfo({
          workspaceName: data.teamName,
          workspaceCode: data.teamCode,
          workspaceDescription: data.teamDescription,
          workspaceRegion: data.region,
        });

        if (data.userLicense.length === 0) setIsNoLicense(true);

        const [license] = data.userLicense.filter(
          (v) => v.serviceStatus === "Y" && v.systemLicense.name !== "Storage"
        );

        setLicenseInfo({
          license: license.systemLicense.name,
          maxMemberNuber: license.count,
          memberNumber: data.userWorkspaceUser.length,
          storageCurrent: data.storageCurrent.toFixed(1),
          storageMax: data.storageMax,
          validEndDate: license.validEndDate,
          validStartDate: license.validStartDate,
          payDate: license.payDate,
        });
      })
      .catch((error) => {
        history.push("/workspace");
      })
      .finally(() => {
        setLoadingPage(false);
      });
  }, []);



  useEffect(() => {

    if (licenseInfo.license === "Enterprise") {

      const interval = setInterval(() => {
        axios.get(`${SERVER_URI}/api/workspace/${workspaceRestCode}`, header())
          .then(res => {
            const data = res.data;

            console.log('data', data)
            if (data.ncpCustomerInstance.serverInstanceStatusName === "run") {
              setIsCompleted(true);
              setServerPublicIp(data.ncpCustomerInstance.serverPublicIp);
            } else {
              setIsCompleted(false);
            }
          })
      }, 5000);

      return () => clearInterval(interval);

    }

  }, [licenseInfo.license])







  const calculateDaysLeft = (startDate, endDate) => {
    if (!moment.isMoment(startDate)) startDate = moment(startDate);
    if (!moment.isMoment(endDate)) endDate = moment(endDate);

    return endDate.diff(startDate, "days");
  };

  const handleSyncUp = async () => {
    try {
      setSyncUp(true);
      const response = await axios.get(
        `${SERVER_URI}/api/workspace/${workspaceRestCode}/sync/storage`,
        header()
      );
      await delay();
      setLicenseInfo((pre) => ({
        ...pre,
        storageCurrent: response?.data?.toFixed(1),
      }));
      message.success(t("common.syncUpSuccess"));
    } catch (error) {
      message.error(t("common.syncUpFail"));
    } finally {
      setSyncUp(false); //sync up ico spin stop
    }
  };

  const handleMemberManage = () => {
    history.push(`/workspace/organization/${workspaceRestCode}`);
  };

  const handlePlanUpgrade = () => {
    history.push(
      `/payment/license/${generalInfo.workspaceName}/${workspaceRestCode}/${licenseInfo.license}`
    );
  };

  const handleMemberUpgrade = () => {
    history.push(
      `/payment/member/${generalInfo.workspaceName}/${workspaceRestCode}/${licenseInfo.license}`
    );
  };

  const handleStorageUpgrade = () => {
    history.push(
      `/payment/storage/${generalInfo.workspaceName}/${workspaceRestCode}/ucm`
    );
  };

  function showDeleteConfirm() {
    const modal = confirm({
      title: `${t("workspace.workspaceDelete")}`,
      content: (
        <div>
          {t("workspace.workspaceDeleteInfo.1")}
          <br />
          {t("workspace.workspaceDeleteInfo.2")}
          <br />
          <br />
          <Checkbox
            onClick={(e) => {
              modal.update({
                okButtonProps: { disabled: !e.target.checked },
              });
            }}
          >
            {t("workspace.workspaceDeleteInfo.yes")}
          </Checkbox>
        </div>
      ),
      okText: `${t("common.delete")}`,
      okType: "danger",
      cancelText: `${t("common.cancel")}`,
      okButtonProps: {
        disabled: true,
      },
      onOk() {
        axios
          .delete(`${SERVER_URI}/api/workspace/${workspaceRestCode}`, header())
          .then((res) => {
            if (res) {
              message.success(t("workspaceDeleteCompleted"));
              setTimeout(() => {
                history.push("/workspace");
              }, 1500);
            }
          })
          .catch((err) => message.success(t("workspaceDeleteFailed")));
      },
    });
  }

  const tabList = [
    { key: 1, value: `_workspaceDetail.workspaceInfo` }, // t(`_workspaceDetail.workspaceInfo`)
    { key: 2, value: `_workspaceDetail.historyTab` }, // t( `_workspaceDetail.historyTab`)
    { key: 3, value: `_workspaceDetail.modifyTab` }, // t(`_workspaceDetail.modifyTab`)
  ];
  const [current, setCurrent] = useTab(1, tabList);
  const [active, setActive] = useState(true);

  const tabClickCallback = (next) => {
    setCurrent(next);
  };

  const tabMouseOver = () => {
    setActive(true);
  };

  useEffect(() => {
    console.log(current);
  }, [current]);

  return (
    <>
      <HistoryNavigation history={history} />
      <Basement>
        {loadingPage ? (
          <PageLoading />
        ) : (
          <>
            <F1>
              <img src={`/images/clogo.png`} style={{ width: `140px` }} />

              <F2_1>
                <TextExtended mid color="black">
                  {`${t("_workspaceDetail.workspaceName")}: ${generalInfo.workspaceName
                    }`}
                </TextExtended>
                <TextExtended>{`${t("_workspaceDetail.workspaceCode")}: @${generalInfo.workspaceCode
                  }`}</TextExtended>
                <TextExtended>{`${t("workspaceDetail.workspaceDescription")}: ${generalInfo.workspaceDescription
                  }`}</TextExtended>
                <TextExtended>{`${t("region")}: ${generalInfo.workspaceRegion
                  }`}</TextExtended>
              </F2_1>
              {/* <F2_1>
                <TextExtended big color="black">
                  {generalInfo.workspaceName}
                </TextExtended>
                <TextExtended
                  mid
                >{`@${generalInfo.workspaceCode}`}</TextExtended>
                <TextExtended>{generalInfo.workspaceDescription}</TextExtended>
              </F2_1> */}
            </F1>
            <Tab
              list={tabList}
              current={current}
              callback={tabClickCallback}
              active={active}
              activeCallback={tabMouseOver}
            />

            {current?.value === `_workspaceDetail.historyTab` ? (
              <WorkspaceBillingHistory workspaceRestCode={workspaceRestCode} />
            ) : current?.value === `_workspaceDetail.modifyTab` ? (
              <Button onClick={showDeleteConfirm}>
                {t("_workspaceDetail.deleteWorkspace")}
              </Button>
            ) : (
              <F1_1>
                <F2>
                  <F3_1>
                    <Text mid color="#42A0EB">
                      {t("_workspaceDetail.plan.title")}
                    </Text>
                  </F3_1>
                  <F3>
                    <F4>
                      <Text
                        mid
                        color="black"
                      >{`${licenseInfo.license} Plan`}</Text>
                      <Text textAlign="left">
                        {`${new Date(licenseInfo.validStartDate)
                          .toLocaleDateString()
                          .slice(0, -1)} ~ ${new Date(licenseInfo.validEndDate)
                            .toLocaleDateString()
                            .slice(0, -1)}`}
                      </Text>

                      <Button onClick={handlePlanUpgrade}>
                        {t("_workspaceDetail.plan.button")}
                      </Button>
                    </F4>
                    <Progress
                      value={(
                        (calculateDaysLeft(
                          licenseInfo.validStartDate,
                          new Date()
                        ) /
                          calculateDaysLeft(
                            licenseInfo.validStartDate,
                            licenseInfo.validEndDate
                          )) *
                        100
                      ).toFixed(1)}
                    />
                  </F3>
                </F2>


                {licenseInfo.license !== "Enterprise" &&

                  <F2>
                    <F3_1>
                      <Text mid color="#42A0EB">
                        {t("_workspaceDetail.member.title")}
                      </Text>
                    </F3_1>
                    <F3>
                      <F4>
                        <Text mid textAlign="left" color="black">
                          {`${licenseInfo.memberNumber}/${licenseInfo.maxMemberNuber
                            } (${t("_workspaceDetail.member.member")})`}
                        </Text>
                        <Button onClick={handleMemberUpgrade}>
                          {t("_workspaceDetail.member.addMemberButton")}
                        </Button>
                        <Button onClick={handleMemberManage}>
                          {t(`_common.memberManage`)}
                        </Button>
                      </F4>
                      <Progress
                        value={(
                          (licenseInfo.memberNumber /
                            licenseInfo.maxMemberNuber) *
                          100
                        ).toFixed(1)}
                      />
                    </F3>
                  </F2>
                }

                {licenseInfo.license === "Enterprise" &&
                  <>
                    {isCompleted ?
                      <F2>
                        <F3_1>
                          <Text mid color="#42A0EB">

                            <a href={`http://${serverPublicIp}:8090/talkmgr/msg_atart.html`} target="_blank" rel="noopener noreferrer" >
                              {serverPublicIp}
                            </a>
                          </Text>
                        </F3_1>
                        <F3_1>
                          <Text mid color="#42A0EB">
                            서버가 생성되었습니다. 하지만 서버 설정 시간이 5분 더 걸리기 때문에
                            만약 접속이 안된다면 5분 후에 다시 접속해주시거나 그 후에도 안되면
                            유씨웨어에 문의 부탁드립니다.
                      </Text>
                        </F3_1>
                      </F2>
                      :
                      <F2>
                        <F3_1>
                          <Text mid color="#42A0EB">
                            현재 서버가 생성중입니다...
                          </Text>
                        </F3_1>
                      </F2>
                    }
                  </>
                }
                
                <F2>
                  <F3_1>
                    <Text mid color="#42A0EB">
                      {t("_workspaceDetail.storage.title")}
                    </Text>
                  </F3_1>
                  <F3>
                    <F4>
                      <Text mid textAlign="left" color="black">{`${
                        licenseInfo.storageCurrent
                      }/${licenseInfo.storageMax} (${t(
                        "_workspaceDetail.storage.GB"
                      )})`}</Text>
                      <Button onClick={handleStorageUpgrade}>
                        {t("_workspaceDetail.storage.button")}
                      </Button>
                      <Button onClick={handleSyncUp} loading={syncUp}>
                        {t(`_common.syncronize`)}
                      </Button>
                    </F4>
                    <Progress
                      value={(
                        (licenseInfo.storageCurrent / licenseInfo.storageMax) *
                        100
                      ).toFixed(1)}
                    />
                  </F3>
                </F2>
              </F1_1>
              // {/* <Text>{`*${t("_workspaceHistory.contactInfo")}`}</Text> */}
            )}
          </>
        )}
      </Basement>
    </>
  );
}

const F1 = styled.div`
  display: flex;
  height: 15vh;
  align-items: center;
  width: 100%;
`;

const F1_1 = styled.div`
  width: 80%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const F2 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  padding: 20px;
  margin: 30px 0;
  position: relative;

  &:before {
    width: 100%;
    position: absolute;
    content: "";
    border-bottom: 1px solid #f2f2f2;
    top: 70px;
    left: 0;
  }
`;

const F2_1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;
  margin-left: 1vw;
`;

const F3_1 = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 40px 0;
`;

const F3 = styled.div`
  display: flex;
  justify-content: space-between;
`;

const F4 = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextExtended = styled(Text)`
  padding: 0 0.5rem;
`;
