import React from "react";
import styled from "styled-components";

export default function Image({ children, ...rest }) {
  return <Container {...rest}>{children}</Container>;
}

const Container = styled.img`
  width: auto;
  height: auto;
  max-width: ${(props) => props.width};
  max-height: ${(props) => props.height};
  margin: 0.5rem;
  &:hover {
    cursor: ${(props) => (props.hoverable ? `pointer` : `default`)};
  }
`;
