const BEARER_TOKEN_PREFIX = "Bearer ";

export const SERVER_URI = process.env.REACT_APP_SERVER_URI;

const token = localStorage.getItem("accessToken")
  ? localStorage.getItem("accessToken")
  : undefined;

export const HEADER = {
  headers: {
    Authorization: `${BEARER_TOKEN_PREFIX} ${token}`,
    "Content-Type": "application/json",
    locale: window.localStorage.i18nextLng,
  },
};

export const header = () => {
  const accessToken = localStorage.getItem("accessToken")
    ? localStorage.getItem("accessToken")
    : undefined;
  return {
    headers: {
      Authorization: `${BEARER_TOKEN_PREFIX} ${accessToken}`,
      "Content-Type": "application/json",
      locale: window.localStorage.i18nextLng,
    },
  };
};

export function httpRequestExternalLink(url, type, body = undefined) {
  const token = localStorage.getItem("accessToken")
    ? localStorage.getItem("accessToken")
    : undefined;
  const headers = new Headers();
  if (token) {
    headers.append("Authorization", `${BEARER_TOKEN_PREFIX} ${token}`);
  }
  headers.append("pragma", "no-cache");
  headers.append("cache-control", "no-cache");
  headers.append("Content-Type", "application/json");

  const requestOptions = {
    crossDomain: true,
    method: type,
    headers: headers,
    body: body,
  };

  console.log(`[REQ][${type.toUpperCase()}]${url}`);

  return fetch(url, requestOptions).then(handleExternalLinkResponse);
}

// export function httpRequest(url, method, type, body) {
//     const token = localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : undefined;

//     const headers = new Headers();
//     if (token) {
//         headers.append("Authorization", `${BEARER_TOKEN_PREFIX} ${token}`);
//     }
//     headers.append('pragma', 'no-cache');
//     headers.append('cache-control', 'no-cache');

//     let data;
//     switch (type) {
//         case BODY_JSON:
//             headers.append("Content-Type", "application/json");
//             if (body && body !== null) {
//                 data = JSON.stringify(body);
//             }
//             break;
//         case BODY_FILE:
//             data = new FormData();
//             if (body && body !== null) {
//                 data.append('file', body);
//             }
//             break;
//         default:
//             break;
//     }

//     const requestOptions = {
//         method: method,
//         headers: headers,
//         body: data
//     };

//     console.log(`[REQ][${method}]${url}`);

//     return fetch(url, requestOptions)
//         .then(handleResponse);
// }

export function transferFile(url, method) {
  const token = localStorage.getItem("accessToken")
    ? localStorage.getItem("accessToken")
    : undefined;

  const headers = new Headers();
  if (token) {
    headers.append("Authorization", `Bearer ${token}`);
  }

  const requestOptions = {
    method: method,
    headers: headers,
    body: undefined,
  };

  console.log(`[REQ][${method}]${url}`);

  return fetch(`${url}`, requestOptions).then(handleBlobResponse);
}

function handleExternalLinkResponse(response) {
  return response.text().then((text) => {
    if (!response.ok) {
      const error = (text && text.message) || response.statusText;
      return Promise.reject(error);
    }
    return text;
  });
}

function handleResponse(response) {
  if (response.ok) {
    return response.text().then((text) => text && JSON.parse(text));
  } else {
    const error = `[${response.status}] ${response.statusText}`;
    return Promise.reject(error);
  }
  // return response.text()
  //     .then(text => {
  //         const data = text && JSON.parse(text);
  //         if (!response.ok) {
  //             const error = (data && data.message) || response.statusText;
  //             return Promise.reject(error);
  //         }
  //         return response.status === 204 ? undefined : data;
  //     });
}

function handleBlobResponse(response) {
  return response
    .blob()
    .then((blob) => URL.createObjectURL(blob))
    .catch((error) => {
      response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
        }
      });
    });
}
