import React, { useState, useEffect } from "react";

import SelectPlan from "../components/LicensePayment/SelectPlan";
import PaymentInformation from "../components/LicensePayment/PaymentInformation";
import Result from "../components/common/Result";
import axios from "axios";
import { SERVER_URI, header } from "../assets/utils/HttpHandler";
import { delay, HistoryNavigation } from "../assets/common";
import { useParams, useHistory } from "react-router-dom";
import PageLoading from "../assets/utils/PageLoading";

export default function LicensePayment(props) {
  const history = useHistory();
  const params = useParams();
  const { workspaceName, workspaceRestCode, licenseType } = params;
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(0);
  const [success, setSuccess] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState({
    adminName: "",
    adminEmail: "",
    currentMember: 1,
    wishPlan: "",
    wishPriceRestCode: "",
    wishMember: 0,
    wishPeriod: 0,
    freePriceResetCode: "",
    licenseList: [],
    maxMember: 1,
    totalPrice: 0,
    licenseType,
    workspaceName,
    workspaceRestCode,
  });

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data: licenseList } = await axios.get(
          `${SERVER_URI}/api/license/list?type=L`,
          header()
        );
        const { data: workspaceData } = await axios.get(
          `${SERVER_URI}/api/workspace/${workspaceRestCode}`,
          header()
        );

        const [userLicense] = workspaceData.userLicense.filter(
          (v) => v.serviceStatus === "Y" && v.systemLicense.name !== "Storage"
        );

        const { data: user } = await axios.get(
          `${SERVER_URI}/api/user`,
          header()
        );

        console.log(`licenseList: `, licenseList);

        setPaymentInfo((prev) => ({
          ...prev,
          freePriceResetCode: licenseList.find(v => v.name === `Free`).priceList[0].restCode,
          currentMember: userLicense ? userLicense.count : 10,
          licenseList: licenseList,
          adminName: user.id,
          adminEmail: user.email,
        }));

        if (licenseType !== "New") {
          console.log(`workspaceRestCode: `, workspaceRestCode);
          // 현재 사용중인 라이선스에 적용된 가격과 현재 일자부터 라이선스 종료일까지 멤버 1인당 금액 측정 API -> system_price에서 멤버 추가 비용 가져오는 로직으로 변경 필요
          const { data: leftPrice } = await axios.get(
            `${SERVER_URI}/api/user/license/current-price?workspaceRestCode=${workspaceRestCode}`,
            header()
          );

          const { data: leftStorage } = await axios.get(
            `${SERVER_URI}/api/user/license/additionalStrageSizeWithLeftPrice?workspaceRestCode=${workspaceRestCode}`,
            header()
          );

          setPaymentInfo((prev) => ({
            ...prev,
            currentMemberPrice: leftPrice.price,
            currentPeriod: leftPrice.cycle,
            currentStorage: leftStorage,
          }));
        }
        setLoading(false);
      } catch (error) {
        console.log(`================error: `, error);
      }
    };

    fetch();

    return () => {
      setPaymentInfo({});
    };
  }, []);

  const skip = async () => {
    const variables = {
      priceRestCode: `${paymentInfo.freePriceResetCode}`,
      workspaceRestCode: `${workspaceRestCode}`,
      orderFlag: "Y",
    };
    setPaymentInfo((prev) => ({
      ...prev,
      wishPlan: `Free`,
      wishPeriod: 1,
      wishMember: 9999,
    }));
    try {
      await delay();
      axios.post(`${SERVER_URI}/api/order/license`, variables, header());
      setSuccess(true);
      setStep(2);
    } catch (error) {
      setSuccess(false);
      setStep(2);
    }
  };

  const paymentPayload = [
    { label: `confirm.planInfo`, value: paymentInfo.wishPlan },
    {
      label: `confirm.paymentPeriod`,
      value:
        Number(paymentInfo.wishPeriod) === 1
          ? "1 Month"
          : Number(paymentInfo.wishPeriod) === 6
          ? "6 Months"
          : Number(paymentInfo.wishPeriod) === 12
          ? "1 Year"
          : "",
    },
    { label: `confirm.memberNumber`, value: paymentInfo.wishMember },
    {
      label: `confirm.price`,
      value: `₩${Number(paymentInfo.totalPrice).toLocaleString()}`,
    },
    { label: `paymentInfo.adminName`, value: paymentInfo.adminName },
    { label: `paymentInfo.adminEmail`, value: paymentInfo.adminEmail },
  ];

  return (
    <>
      <HistoryNavigation history={history} params={params} />
      {step === 0 && (
        <>
          {loading ? (
            <PageLoading />
          ) : (
            <SelectPlan
              setStep={setStep}
              paymentInfo={paymentInfo}
              setPaymentInfo={setPaymentInfo}
              skip={skip}
            />
          )}
        </>
      )}
      {step === 1 && (
        <PaymentInformation
          setStep={setStep}
          paymentInfo={paymentInfo}
          setPaymentInfo={setPaymentInfo}
          setSuccess={setSuccess}
        />
      )}
      {step === 2 && (
        <Result
          success={success}
          workspaceRestCode={paymentInfo.workspaceRestCode}
          paymentInfo={paymentPayload}
        />
      )}
    </>
  );
}
