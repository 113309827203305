import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { SERVER_URI, header } from "../assets/utils/HttpHandler";
import { useTranslation } from "react-i18next";
import {
  Modal,
  Basement,
  Table,
  Button,
  Text,
  Image,
  HistoryNavigation,
} from "../assets/common";
import { useModal } from "../assets/common";
import styled from "styled-components";
import { PlusOutlined, PlusCircleFilled } from "@ant-design/icons";
import { message } from "antd";

// PULL REQUEST TEST

export default function () {
  const { t } = useTranslation();
  const history = useHistory();

  const [workspaceLists, setWorkspaceLists] = useState([]);
  const [sync, setSync] = useState(false);
  const [visible, setVisible] = useModal();
  const { innerHeight: height, innerWidth: width } = window;
  const [userInfo, setUserInfo] = useState({});
  // column: [{label: `name`, type: `text`},
  //          {label: `age`, type: `link`, callback: ([cellKey, cellValue], cellIndex, rowList) => {}}}]
  // data: [{name: `anax`, age: 14},
  //        {name: `bucky`, age: 21}]
  //
  // when click row 2 of column `age`
  //
  // cellKey: age
  // cellValue: 21
  // cellIndex: 1
  // rowList: [[`name`, `bucky`], [`age`, 21]]
  const column = [
    {
      label: t("_workspace.plan"),
      type: `button`,
      callback: ([cellKey, cellValue], cellIndex, rowList) => {
        if (cellValue !== `_workspace.choosePlan`) {
          if (cellValue === `_workspace.createWorkspace`) {
            history.push(`/workspace/create`);
          }
          return false;
        }

        const [[__, workspaceName]] = rowList.filter(
          ([key]) => key === `workspaceName`
        );
        const [[___, workspaceRestCode]] = rowList.filter(
          ([key]) => key === `workspaceRestCode`
        );
        history.push(
          `/payment/license/${workspaceName}/${workspaceRestCode}/New`
        );
      },
    },
    {
      label: t("_workspace.workspaceName"),
      type: `button`,
      callback: ([cellKey, cellValue], cellIndex, rowList) => {
        const [[_, license]] = rowList.filter(([key]) => key === `license`);

        if (license === `_workspace.choosePlan`) {
          message.info(t(`_info.chooseFirst`));
          return false;
        }

        const [[key, value]] = rowList.filter(
          ([key]) => key === `workspaceRestCode`
        );
        history.push(`/workspace/detail/${value}`);
      },
    },
    { label: t("_workspace.workspaceCode"), type: `text` },
    { label: t("_workspace.member"), type: `text` },
    // { label: t("_workspace.storage"), type: `text` },
    { label: `workspaceRestCode`, type: `hidden` },
    {
      label: t(`_workspace.option`),
      type: `button`,
      callback: ([cellKey, cellValue], cellIndex, rowList) => {
        const [[_, license]] = rowList.filter(([key]) => key === `license`);

        if (license === `_workspace.choosePlan`) {
          message.info(t(`_info.chooseFirst`));
          return false;
        }

        const [[key, value]] = rowList.filter(
          ([key]) => key === `workspaceRestCode`
        );
        history.push(`/workspace/organization/${value}`);
      },
    },
  ];

  useEffect(() => {
    const inspect = async () => {
      try {
        const response = await axios.get(`${SERVER_URI}/api/user`, header());
        setUserInfo(response.data);
      } catch (error) {
        console.log(`inspect error: `, error);
      }
    };
    const getWorkspaceList = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URI}/api/workspace/list`,
          header()
        );
        const workspaceLists = response.data.map((v, i) => ({
          license: v.userLicense.length
            ? v.userLicense[0].systemLicense.name
            : `_workspace.choosePlan`,
          workspaceName: v.teamName,
          workspaceCode: v.teamCode,
          memberNumber: `${v.userWorkspaceUser.length}/${v.userLicense.length ? v.userLicense[0].count : 0
            }`,
          // storageCurrent: `${v.storageCurrent}/${v.storageMax}GB`,
          workspaceRestCode: v.restCode,
          option: `_workspace.option`, // t(`_workspace.option`)
        }));

        console.log(`workspaceList: `, workspaceLists)
        setWorkspaceLists([
          ...workspaceLists,
          {
            license: `_workspace.createWorkspace`, // t(`_workspace.createWorkspace`)
            workspaceName: ``,
            workspaceCode: ``,
            memberNumber: ``,
            // storageCurrent: ``,
            workspaceRestCode: ``,
            option: ``,
          },
        ]);
      } catch (error) {
        console.log("getWorkspace fail!", error);
        localStorage.removeItem("accessToken");
      }
    };
    inspect();
    getWorkspaceList();
  }, []);

  useEffect(() => {
    if (userInfo.id) {
      localStorage.getItem("neverSeeAgain_" + userInfo.id)
        ? setVisible(false)
        : setVisible(true);
    }
  }, [userInfo]);

  useEffect(() => {
    console.log(`userInfo`, userInfo.id);
    console.log(`visible: `, visible);
  });

  const handleGuide = () => {
    setVisible((prev) => !prev);
  };

  const handleNeverSeeAgain = () => {
    localStorage.setItem("neverSeeAgain_" + userInfo.id, true);
    setVisible((prev) => !prev);
  };

  return (
    <>
      <HistoryNavigation history={history} />


      <Basement width="1250px">
        <Table
          payload={workspaceLists}
          setPayload={setWorkspaceLists}
          column={column}
          shadow
        />

        <Modal visible={visible} setVisible={setVisible}>
          <Basement>
            <F0>
              <F1>
                <F2>
                  <Text super color="black" textAlign="left">
                    {userInfo.name}
                  </Text>
                  <Text big>{t("workspaceGuid.1-1")}</Text>
                </F2>

                <Text super color="#black" textAlign="left">
                  {t("workspaceGuid.1-2")}
                </Text>
              </F1>
              <F1_2>
                {localStorage.getItem("i18nextLng") === "ko" ? (
                  <img
                    src={`/images/imageGuide_ko.png`}
                    style={{ width: `100%` }}
                  />
                ) : (
                  <img
                    src={`/images/imageGuide_en.png`}
                    style={{ width: `100%` }}
                  />
                )}
              </F1_2>
            </F0>
            <F0_1>
              <Text big color="black" textAlign="left">
                {t("workspaceGuid.2")}
              </Text>
              <Text textAlign="left">{t("workspaceGuid.2-1")}</Text>

              <Text big color="black" textAlign="left">
                {t("workspaceGuid.0")}
              </Text>
              <Text textAlign="left">{t("workspaceGuid.0-1")}</Text>

              <Text big color="black" textAlign="left">
                {t("workspaceGuid.3")}
              </Text>
              <Text textAlign="left">{t("workspaceGuid.3-1")}</Text>

              <Text big color="black" textAlign="left">
                {t("workspaceGuid.4")}
              </Text>
              <Text textAlign="left">{t("workspaceGuid.4-1")}</Text>
            </F0_1>
            <Button onClick={handleGuide}>{t("workspaceGuid.5")}</Button>

            <Text>
              <a onClick={handleNeverSeeAgain}>{t("workspaceGuid.6")}</a>
            </Text>
          </Basement>
        </Modal>
      </Basement>
    </>
  );
}

const F0 = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const F0_1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > div:before {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    border-radius: 12px;
    background-color: #e63b7a;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  & > div:nth-child(1):before {
    content: "1";
  }

  & > div:nth-child(3):before {
    content: "2";
  }

  & > div:nth-child(5):before {
    content: "3";
  }

  & > div:nth-child(7):before {
    content: "4";
  }
`;

const F1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 2rem;
`;

const F1_1 = styled.div`
  display: flex;
  align-items: center;
`;

const F1_2 = styled.div`
  width: 100%;
  overflow: auto;
`;

const F2 = styled.div`
  display: flex;
  align-items: baseline;
`;

const Hover = styled.div`
  &:hover {
    cursor: pointer;
  }
`;
