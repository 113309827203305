import React, { useState, useEffect } from "react";
import axios from "axios";
import { SERVER_URI, header } from "../../utils/HttpHandler";
import { useHistory } from "react-router-dom";

export default function useVerify(restCode) {
  const history = useHistory();
  const [verified, setVerified] = useState(false);
  const [loading, setLoading] = useState(true);

  const verify = async () => {
    try {
      axios.post(
        `${SERVER_URI}/api/auth/verify-account`,
        { restCode },
        header()
      );
      setVerified(true);
    } catch (error) {
      setVerified(false);
      console.log(error);
      history.push(`/notFound`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    verify();
  }, []);

  return [verified, loading];
}
