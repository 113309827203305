import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useHistory, Link } from "react-router-dom";
import { Basement, Input, Text, Button } from "../../assets/common";

export default function Result(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const { success, paymentInfo, workspaceRestCode } = props;

  return (
    <Basement>
      <F1>
        <Text big color="black">
          {success ? t("_info.paymentSuccess") : t("_info.paymentFail")}
        </Text>
        <Text>{!success && t("_info.paymentFailDetail")}</Text>
        <Text>
          <Link to={`/workspace/detail/${workspaceRestCode}`}>
            {t("success.goBack")}
          </Link>
        </Text>
      </F1>
      {success && (
        <Text big color="black">
          {t("confirm.paymentInformation")}
        </Text>
      )}
      {success && (
        <>
          {paymentInfo.map((v) => (
            <Input
              type="text"
              label={t(v.label)}
              current={t(v.value)}
              disabled
            />
          ))}
        </>
      )}
    </Basement>
  );
}

const F1 = styled.div`
  margin-bottom: 2rem;
`;
