import React, { useState, useEffect } from "react";
import axios from "axios";
import { SERVER_URI, header } from "../assets/utils/HttpHandler";
import PageLoading from "../assets/utils/PageLoading";
import OrgnzChart from "../components/MemberManagement/OrgnzChart";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { message } from "antd";
import {
  Button,
  Basement,
  Text,
  delay,
  HistoryNavigation,
} from "../assets/common";
import { useParams, useHistory } from "react-router-dom";

/**
 * 워크스페이스 > 멤버 관리
 */
function MemberManagement(props) {
  const params = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  // URL Parameter
  const { restCode } = params;
  const [pageLoading, setPageLoading] = useState(true);
  const [teamName, setTeamName] = useState("");
  const [teamCode, setTeamCode] = useState("");
  const [licenseCount, setLicenseCount] = useState(0); // 라이선스 멤버 수
  const [syncUpLoading, setSyncUpLoading] = useState(false); // sync up 버튼
  const [treeData, setTreeData] = useState([]); // 조직도 데이터
  const [memberData, setMemberData] = useState([]); // 멤버 데이터

  useEffect(() => {
    // 워크스페이스 기본 정보 요청 API
    axios
      .get(`${SERVER_URI}/api/workspace/${restCode}`, header())
      .then((res) => {
        const data = res.data;

        setPageLoading(false);
        setTeamCode(data.teamCode);
        setTeamName(data.teamName);

        // 보유한 라이선스가 없으면 초기 페이지로 이동
        if (data.userLicense.length === 0) {
          props.history.push("/workspace");
          return false;
        }

        // 라이선스 리스트 중 스토리지가 아니며, 서비스 상태인 라이선스가 현재 적용된 라이선스임
        data.userLicense.forEach((val, i) => {
          if (
            val.serviceStatus === "Y" &&
            val.systemLicense.name !== "Storage"
          ) {
            setLicenseCount(val.count);
          }
        });

        // 조직도 데이터 요청 API
        getTreeData();

        // 멤버 데이터 요청 API
        getMemberData();
      })
      .catch((err) => {
        console.log(err);
        props.history.push("/workspace");
      });
  }, []);

  const getTreeData = () => {
    // 조직도 데이터 요청 API
    axios
      //.get(`${SERVER_URI}/api/workspace/${restCode}/tree/list`, header())
      .get(`${SERVER_URI}/api/workspace/${restCode}/tree_node`, header())
      .then((res) => {
        const data = res.data;
        setTreeData(data);
      })
      .catch((err) => {
        alert(err);
      });
  };

  const getMemberData = () => {
    axios
      .get(`${SERVER_URI}/api/workspace/${restCode}/user/list`, header())
      .then((res) => {
        const data = res.data;
        console.log(`member data: `, data);
        setMemberData(data);
      })
      .catch((err) => {
        alert(err);
      });
  };

  // 메신저 동기화 버튼 클릭시
  const handleSyncUp = async () => {
    try {
      setSyncUpLoading(true);
      const portalResponse = await axios.get(
        `${SERVER_URI}/api/workspace/${restCode}/user/sync`,
        header()
      );
      await delay();
      portalResponse.data
        ? message.success(t("common.syncUpSuccess"))
        : message.error(t("common.syncUpFail"));
    } catch (error) {
      message.error(t("common.syncUpFail"));
    } finally {
      setSyncUpLoading(false); //sync up ico spin stop
    }
  };

  // Rendering...
  return pageLoading ? (
    <PageLoading />
  ) : (
    <>
      <HistoryNavigation history={history} params={params} />
      <Basement>
        <F1>
          <Text big color="black">
            {teamName}
          </Text>
          <Text>@{teamCode}</Text>
        </F1>

        <OrgnzChart
          workspaceRestCode={restCode}
          workspaceTeamCode={teamCode}
          workspaceTeamName={teamName}
          treeData={treeData}
          getTreeData={getTreeData}
          memberData={memberData}
          licenseCount={licenseCount}
          getMemberData={getMemberData}
        />

        <Button onClick={handleSyncUp} big loading={syncUpLoading}>
          {t("common.syncUp")}
        </Button>
      </Basement>
    </>
  );
}

const F1 = styled.div`
  display: flex;
  justify-content: baseline;
`;

export default MemberManagement;
