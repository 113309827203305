/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint no-restricted-globals: ["off"] */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { message as antMessage, Modal } from "antd";
import { Link, useHistory } from "react-router-dom";
import { Basement, Text, Button } from "../assets/common";

export default function RegisterComplete(props) {
  const { t } = useTranslation();

  const email = props.match.params.email;
  const history = useHistory();

  return (
    <Basement>
      <Text big color="black" textAlign="left">
        {t("userRegisterCompleted.1")}
      </Text>

      <Text textAlign="left">
        {t("userRegisterCompleted.2", { email: email })}
      </Text>

      <Text textAlign="left">{t("userRegisterCompleted.4")}</Text>

      <Text>
        <Link to="/">{t("common.goHome")}</Link>
      </Text>
    </Basement>
  );
}
