import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@react-hook/media-query";
import { Text } from "../../assets/common";
import Fade from "react-reveal/Fade";

export default function Slick() {
  const { t } = useTranslation();
  const match = useMediaQuery(`(max-width: 768px)`);

  return (
    <Container>
      <F1>
        <F2>
          <Text
            color="#fff"
            super={match ? false : true}
            big={match ? true : false}
          >
            {t("home.slick.1")}
          </Text>
          <Text color="#fff">{t("home.slick.2")}</Text>
        </F2>
        <F2>
          <img src="/images/bslider1.jpg" style={{ width: `100%` }} />
        </F2>
      </F1>
      <F1>
        <F2>
          <Text
            color="#fff"
            super={match ? false : true}
            big={match ? true : false}
          >
            {t("home.slick.3")}
          </Text>
          <Text color="#fff">{t("home.slick.4")}</Text>
        </F2>
        <F2>
          <img src="/images/bslider2.jpg" style={{ width: `100%` }} />
        </F2>
      </F1>
      <F1>
        <F2>
          <Text
            color="#fff"
            super={match ? false : true}
            big={match ? true : false}
          >
            {t("home.slick.5")}
          </Text>
          <Text color="#fff">{t("home.slick.6")}</Text>
        </F2>
        <F2>
          <img src="/images/bslider3.jpg" style={{ width: `100%` }} />
        </F2>
      </F1>
      <F1>
        <F2>
          <Text
            color="#fff"
            super={match ? false : true}
            big={match ? true : false}
          >
            {t("home.slick.7")}
          </Text>
          <Text color="#fff">{t("home.slick.8")}</Text>
        </F2>
        <F2>
          <img src="/images/bslider4.jpg" style={{ width: `100%` }} />
        </F2>
      </F1>
      <F1>
        <F2>
          <Text
            color="#fff"
            super={match ? false : true}
            big={match ? true : false}
          >
            {" "}
            {t("home.slick.9")}
          </Text>
          <Text color="#fff">{t("home.slick.10")}</Text>
        </F2>
        <F2>
          <img src="/images/bslider5.jpg" style={{ width: `100%` }} />
        </F2>
      </F1>
    </Container>
  );
}

const Container = styled.div`
  background-color: #07c9ff;
`;

const F1 = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  padding: 5vw 0;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const F2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5vw;
  width: 50vw;

  @media (max-width: 768px) {
    width: 100vw;
  }
`;
