import React, { useState } from "react";

export default function useTab(initialKey, tabList) {
  const [current, setCurrent] = useState(() => {
    if (initialKey) {
      const [initial] = tabList.filter((v) => v.key === initialKey);
      return { ...initial, selected: true };
    } else {
      return {};
    }
  });

  return [current, setCurrent];
}
