import React, { useEffect, useState } from "react";
import axios from "axios";
import { header, SERVER_URI } from "../../assets/utils/HttpHandler";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Tree, message, Select, Dropdown, Menu, Tooltip } from "antd";
import {
  SmileOutlined,
  FolderOutlined,
  AlignRightOutlined,
  MenuOutlined,
  EllipsisOutlined,
  UserOutlined,
  ApartmentOutlined,
  BankOutlined,
  PlusSquareOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";
import {
  Button,
  useModal,
  Modal,
  Text,
  Basement,
  useInput,
  Input,
  delay,
} from "../../assets/common";
import { ruleSchema } from "../../assets/common/util/ruleSchema";
import PageLoading from "../../assets/utils/PageLoading";

export default function OrgnzChart(props) {
  const { t } = useTranslation();
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [searchValue, setSearchValue] = useState(``);
  const [searching, setSearching] = useState(false);
  const [autoExpandParent, setAutoExpandParent] = useState(false);
  const [selectedMember, setSelectedMember] = useState({});
  const [selectedGroup, setSelectedGroup] = useState({});
  const [addMemberVisible, setAddMemberVisible] = useModal();
  const [addMemberLoading, setAddMemberLoading] = useState(false);
  const [modifyMemberVisible, setModifyMemberVisible] = useModal();
  const [modifyMemberLoading, setModifyMemberLoading] = useState(false);
  const [deleteMemberVisible, setDeleteMemberVisible] = useState(false);
  const [deleteMemberLoading, setDeleteMemberLoading] = useState(false);
  const [addGroupVisible, setAddGroupVisible] = useModal();
  const [addGroupLoading, setAddGroupLoading] = useState(false);
  const [modifyGroupVisible, setModifyGroupVisible] = useModal();
  const [modifyGroupLoading, setModifyGroupLoading] = useState(false);
  const [deleteGroupVisible, setDeleteGroupVisible] = useModal();
  const [deleteGroupLoading, setDeleteGroupLoading] = useState(false);
  const [groupNameValid, setGroupNameValid, groupName, setGroupName] = useInput(
    ``,
    ruleSchema.groupName.rule,
    null
  );
  const [idValid, setIdValid, id, setId] = useInput(
    ``,
    ruleSchema.id.rule,
    null
  );
  const [nameValid, setNameValid, name, setName] = useInput(
    ``,
    ruleSchema.name.rule,
    null
  );
  const [emailValid, setEmailValid, email, setEmail] = useInput(
    ``,
    ruleSchema.email.rule,
    null
  );
  const [phoneValid, setPhoneValid, phone, setPhone] = useInput(
    ``,
    ruleSchema.phone.rule,
    null
  );
  const [jobTitleValid, setJobTitleValid, jobTitle, setJobTitle] = useInput(
    ``,
    ruleSchema.jobTitle.rule,
    null
  );
  const [isNoticeManager, setIsNoticeManager] = useState(false);

  const { TreeNode } = Tree;

  const flatList = [];

  const generateList = (data) => {
    for (let i = 0; i < data.length; i++) {
      const node = data[i];

      const { key, title } = node;

      flatList.push({ key, title });

      if (node.children) {
        generateList(node.children);
      }
    }
  };

  generateList(props.treeData);

  useEffect(() => {
    // 제일 첫번째 Node로 펼쳐주기
    if (props.treeData.length > 0)
      setExpandedKeys((oldArr) => [...oldArr, props.treeData[0].key]);
  }, [props.treeData]);

  useEffect(() => {
    const [selected] = Object.values(props.memberData).filter(
      (v) => v.id === selectedGroup.dataRef.userId
    );
    setSelectedMember(selected);
  }, [selectedGroup]);

  useEffect(() => {
    console.log(`selected Member: `, selectedMember);
    console.log(`isNoticeManager: `, isNoticeManager);
  });

  /** 조직도 위치 변경 */
  const onDrop = (info) => {
    // Drag & Drop 시, 전달받는 데이터
    const dragNode = info.dragNode.props.dataRef; // Drag 하는 노드
    const targetNode = info.node.props.dataRef; // Drag하는 노드가 변경 할 위치의 타겟이 되는 노드
    const changeData = {
      restCode: info.dragNode.props.eventKey,
      kind: dragNode.kind,
    };

    /**=================================================
     *  Node를 Drag&Drop 할때 발생하는 케이스
     *  1. 타겟 노드의 하위 그룹으로 넣기
     *  2. 타겟 노드의 사이에 넣기
     *     2-1. 타겟 노드가 최상위 그룹일 경우, 타겟 노드와 형제 관계가 될 수 없으므로 최상위 그룹의 하위에 넣어줘야 한다. (1번과 동일하게 처리)
     * =================================================
     */
    if ((info.dropToGap && targetNode.level === 0) || !info.dropToGap) {
      // 1. 타겟 노드의 하위 그룹으로 넣기

      // 멤버 하위에는 넣을 수 없다.
      if (targetNode.kind === "1") {
        message.error(t("memberManagement.orgnzChart.notPossibleUnderMember"));
        return false;
      }

      // 현재 위치와 동일한 경우
      if (dragNode.upperClassId === targetNode.idx) return false;

      // 타겟 노드를 부모 노드로 삼아야 한다.
      changeData.level = targetNode.level + 1;
      changeData.upperClassId = targetNode.idx;
      changeData.upperGroupCode = targetNode.groupCode;

      // 멤버라면 부서코드로 추가해 준다.
      if (changeData.kind == "1") {
        changeData.groupCode = targetNode.groupCode;
        changeData.groupName = targetNode.groupName;
      }

      // 부모 노드의 자식중 제일 마지막에 위치한 노드 orderNo에 + 1 해준다.
      changeData.orderNo = targetNode.children
        ? targetNode.children[targetNode.children.length - 1].orderNo
        : 0;

      transferTreeNode(changeData);
    } else {
      // 2. 타겟 노드의 사이에 넣기

      // 타겟 노드와 형제 관계로, 같은 부모를 삼아야 한다.
      changeData.level = targetNode.level;
      changeData.upperClassId = targetNode.upperClassId;
      changeData.upperGroupCode = targetNode.upperGroupCode;

      // 멤버라면 부서코드로 추가해 준다.
      if (changeData.kind == "1") {
        changeData.groupCode = targetNode.groupCode;
        changeData.groupName = targetNode.groupName;
      }

      const dropPos = info.node.props.pos.split("-");
      const dropPosition =
        info.dropPosition - Number(dropPos[dropPos.length - 1]); //1 : 아래 , -1: 위

      if (dropPosition === 1) {
        // 타겟 노드보다 아래에 위치시킬 경우
        changeData.orderNo = targetNode.orderNo + 1;
      } else if (dropPosition === -1) {
        // 타겟 노드보다 위에 위치시킬 경우, 타겟의 순서가 곧 변경할 노드의 순서가 된다.
        changeData.orderNo = targetNode.orderNo;
      }

      transferTreeNode(changeData);
    }
  };

  const getParentKey = (key, tree) => {
    let parentKey;

    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];

      if (node.children) {
        if (node.children.some((item) => item.key === key)) {
          parentKey = node.key;
        } else if (getParentKey(key, node.children)) {
          parentKey = getParentKey(key, node.children);
        }
      }
    }

    return parentKey;
  };

  const onExpand = (expandedKeys) => {
    setExpandedKeys(expandedKeys);

    setAutoExpandParent(false);
  };

  const onChange = (next) => {
    setSearchValue(next);

    setSearching(false);
  };

  const onSearch = () => {
    const parentKeys = flatList

      .map((item) => {
        if (item.title.indexOf(searchValue) > -1) {
          return getParentKey(item.key, props.treeData);
        }
        return null;
      })

      .filter((item, i, self) => item && self.indexOf(item) === i);

    setAutoExpandParent(true);

    setExpandedKeys(parentKeys);

    setSearching(true);
  };

  const transferTreeNode = (data) => {
    axios
      .put(
        `${SERVER_URI}/api/workspace/${props.workspaceRestCode}/tree/${data.restCode}/transfer`,
        data,
        header()
      )
      .then((response) => {
        if (response.data) {
          props.getTreeData();
          message.success(t("memberManagement.orgnzChart.moveComplete"));
        }
      })
      .catch((err) => {
        console.log(err);
        message.error(t("memberManagement.orgnzChart.moveFail"));
      });
  };

  const handleAddMember = () => {
    // 이용 가능한 멤버 수를 초과했을 경우
    if (props.memberData.length >= props.licenseCount) {
      message.error(t("memberManagement.member.availableAfterPurchase"));
      return false;
    }

    setAddMemberVisible(true);
    setGroupName(selectedGroup.dataRef?.title);
    setIdValid(true);
    setNameValid(true);
    setEmailValid(true);
    setPhoneValid(true);
    setJobTitleValid(true);
  };

  const handleAddMemberClose = () => {
    setGroupName(``);
    setId(``);
    setName(``);
    setEmail(``);
    setPhone(``);
    setJobTitle(``);
    setIsNoticeManager(false);
  };

  const checkDuplicatedId = async () => {
    const response = await axios.get(
      `${SERVER_URI}/api/workspace/${props.workspaceRestCode}/user/check-id?id=${id}@${props.workspaceTeamCode}`,
      header()
    );

    console.log(response.data);

    return response.data;
  };

  const handleSubmitAddMember = async () => {
    let valid = true;

    if (!idValid || !id?.length) {
      setIdValid(false);
      valid = false;
    }
    if (!nameValid || !name?.length) {
      setNameValid(false);
      valid = false;
    }
    if (!emailValid || !email?.length) {
      setEmailValid(false);
      valid = false;
    }
    if (!phoneValid || !phone?.length) {
      setPhoneValid(false);
      valid = false;
    }

    if (valid) {
      try {
        setAddMemberLoading(true);
        await delay();
        const duplicatedValid = await checkDuplicatedId();
        if (!duplicatedValid) {
          message.error(t("register.duplicateAccount"));
          setAddMemberLoading(false);
          return false;
        }
        const data = {
          id: `${id}@${props.workspaceTeamCode}`,
          name,
          email: email.trim(),
          phone,
          jobTitle,
          isNoticeManager: isNoticeManager ? "Y" : "N",
          groupCode: selectedGroup.groupCode,
          userWorkspaceUserAuthModel: { authType: "S" },
        };

        const response = await axios.post(
          `${SERVER_URI}/api/workspace/${props.workspaceRestCode}/user`,
          data,
          header()
        );
        if (response.data) {
          setAddMemberVisible(false);
          setAddMemberLoading(false);
          handleAddMemberClose();
          props.getMemberData();
          props.getTreeData();
          message.success(t(`_message.addMember`));
        }
      } catch (error) {
        console.log(error);
        message.error(t("memberManagement.addMemberFail"));
      }
    }
  };

  const handleModifyMember = () => {
    setModifyMemberVisible(true);
    setGroupName(selectedMember.groupName);
    setId(selectedMember.id);
    setName(selectedMember.name);
    setEmail(selectedMember.email);
    setPhone(selectedMember.phone);
    setJobTitle(selectedMember.jobTitle);
    setIsNoticeManager(selectedMember.isNoticeManager === "Y" ? true : false);
    setIdValid(true);
    setNameValid(true);
    setEmailValid(true);
    setPhoneValid(true);
    setJobTitleValid(true);
  };

  const handleModifyMemberClose = () => {
    setGroupName(``);
    setId(``);
    setName(``);
    setEmail(``);
    setPhone(``);
    setJobTitle(``);
    setIsNoticeManager(false);
  };

  const handleSubmitModifyMember = async () => {
    let valid = true;

    if (!nameValid || !name?.length) {
      setNameValid(false);
      valid = false;
    }
    if (!emailValid || !email?.length) {
      setEmailValid(false);
      valid = false;
    }
    if (!phoneValid || !phone?.length) {
      setPhoneValid(false);
      valid = false;
    }

    if (valid) {
      const data = {
        id,
        name,
        email: email.trim(),
        phone,
        jobTitle,
        isNoticeManager: isNoticeManager ? "Y" : "N",
        orgGroupCode: props.workspaceTeamCode,
      };

      try {
        setModifyMemberLoading(true);
        await delay();
        const response = await axios.put(
          `${SERVER_URI}/api/workspace/${props.workspaceRestCode}/user/${selectedMember.restCode}`,
          data,
          header()
        );
        if (response.data) {
          setModifyMemberVisible(false);
          setModifyMemberLoading(false);
          handleModifyMemberClose();
          props.getMemberData();
          props.getTreeData();
        }
      } catch (error) {
        console.log(error);
        message.error(t("memberManagement.modifyMemberFail"));
      }
    }
  };

  const handleDeleteMember = () => {
    setDeleteMemberVisible(true);
  };

  const handleDeleteMemberClose = () => {
    setDeleteMemberVisible(false);
  };

  const handleSubmitDeleteMember = async () => {
    try {
      const param = `userRestCode=${selectedMember.restCode}`;

      setDeleteMemberLoading(true);
      const response = await axios.delete(
        `${SERVER_URI}/api/workspace/${props.workspaceRestCode}/user_delete?${param}`,
        header()
      );
      await delay();
      if (response.data) {
        props.getTreeData();
        setDeleteMemberVisible(false);
        setDeleteMemberLoading(false);
      }
    } catch (error) {
      console.log(error);
      message.error(t(`_message.deleteMemberFail`));
    }
  };

  //********************************************* 그룹 추가 *******************************************//

  const handleAddGroup = () => {
    setAddGroupVisible(true);
    setGroupNameValid(true);
  };

  const handleAddGroupClose = () => {
    setGroupName(``);
  };

  const handleSubmitAddGroup = () => {
    let valid = true;

    if (!groupNameValid || !groupName.length) {
      valid = false;
      setGroupNameValid(false);
    }

    if (valid) {
      const data = {
        restCode: selectedGroup.eventKey,
        groupName: groupName,
        orderNo:
          selectedGroup.children && selectedGroup.children.length > 0
            ? selectedGroup.children[selectedGroup.children.length - 1].props
              .orderNo + 1
            : 0,
        kind: "2",
      };
      const addGroup = async () => {
        setAddGroupLoading(true);
        try {
          const response = await axios.post(
            `${SERVER_URI}/api/workspace/${props.workspaceRestCode}/tree`,
            data,
            header()
          );
          await delay();
          if (response.data) {
            setAddGroupVisible(false);
            setAddGroupLoading(false);
            handleAddGroupClose();
            props.getTreeData();
          }
        } catch (error) {
          message.error(t(`_message.addGroupFail`));
        }
      };
      addGroup();
    }
  };

  // 그룹 수정
  const handleModifyGroup = () => {
    if (checkSelectedRootGroup()) {
      setGroupName(selectedGroup.dataRef?.title);
      setModifyGroupVisible(true);
      setGroupNameValid(true);
    }
  };

  const handleModifyGroupClose = () => {
    setGroupName(``);
  };

  const handleSubmitModifyGroup = () => {
    let valid = true;

    if (!groupNameValid || !groupName.length) {
      valid = false;
      setGroupNameValid(false);
    }

    if (valid) {
      const data = {
        groupName,
      };

      const modifyGroup = async () => {
        try {
          setModifyGroupLoading(true);

          const response = axios.put(
            `${SERVER_URI}/api/workspace/${props.workspaceRestCode}/tree/${selectedGroup.eventKey}`,
            data,
            header()
          );
          await delay();
          setModifyGroupVisible(false);
          handleModifyGroupClose();
          props.getTreeData();
          setSelectedGroup((prev) => ({
            ...prev,
            dataRef: {
              ...prev.dataRef,
              title: groupName,
            },
          }));
          setModifyGroupLoading(false);
        } catch (error) {
          console.log(error);
          message.error(t(`_message.modifyGroupFail`));
        }
      };

      modifyGroup();
    }
  };

  // 조직도에서 삭제
  const handleDeleteGroup = () => {
    if (checkSelectedRootGroup() && checkExistChildren()) {
      setDeleteGroupVisible(true);
    }
  };

  const handleDeleteGroupClose = () => {
    setDeleteGroupVisible(false);
  };

  const handleSubmitDeleteGroup = async () => {
    try {
      setDeleteGroupLoading(true);
      const response = await axios.delete(
        `${SERVER_URI}/api/workspace/${props.workspaceRestCode}/tree/${selectedGroup.dataRef.key}`,
        header()
      );
      await delay();
      if (response.data) {
        props.getTreeData();
        setDeleteGroupVisible(false);
        setDeleteGroupLoading(false);
      }
    } catch (error) {
      console.log(error);
      message.error(t(`_message.deleteGroupFail`));
    }
  };

  // 최상위 그룹 선택 여부 체크
  const checkSelectedRootGroup = () => {
    if (selectedGroup.dataRef.level === 0) {
      message.error(t("memberManagement.orgnzChart.cannotChangeTopLevelGroup"));
      return false;
    }
    return true;
  };

  // 자식 노드 체크
  const checkExistChildren = () => {
    if (selectedGroup.dataRef.children) {
      message.error(t("memberManagement.orgnzChart.checkEmptyGroup"));
      return false;
    }
    return true;
  };

  const reSendMemberAuth = async () => {
    console.log(`workspaceRestCode: `, props.workspaceRestCode);
    console.log(`selectedMember RestCode: `, selectedMember.restCode);
    try {
      const response = await axios.get(
        `${SERVER_URI}/api/workspace/${props.workspaceRestCode}/user/reSendMemberAuthMail/${selectedMember.restCode}`,
        header()
      );

      if (response.data) {
        message.success(t("memberManagement.sendMemberAuthSuccess"));
      } else {
        message.error(t("memberManagement.sendMemberAuthFail"));
      }
    } catch (error) {
      message.error(t("memberManagement.sendMemberAuthFail"));
    }
  };

  const groupMenu = (
    <Menu>
      <Menu.Item key="0" onClick={handleAddGroup}>
        <Text small>{t(`_common.addGroup`)}</Text>
      </Menu.Item>
      <Menu.Item key="1" onClick={handleModifyGroup}>
        <Text small>{t(`common.modify`)}</Text>
      </Menu.Item>
      <Menu.Item key="2" onClick={handleDeleteGroup}>
        <Text small>{t(`common.delete`)}</Text>
      </Menu.Item>
      <Menu.Item key="3" onClick={handleAddMember}>
        <Text small>{t(`_common.addMember`)}</Text>
      </Menu.Item>
    </Menu>
  );

  const memberMenu = (
    <Menu>
      <Menu.Item key="0" disabled>
        <Text>{selectedMember?.id}</Text>
        <Text small>{selectedMember?.email}</Text>
        <Text small>
          {selectedMember?.userWorkspaceUserAuthModel?.authFlag === `Y`
            ? t(`_common.verified`)
            : t(`_common.unverified`)}
        </Text>
      </Menu.Item>
      <Menu.Item key="1" onClick={handleModifyMember}>
        <Text small>{t(`common.modify`)}</Text>
      </Menu.Item>
      <Menu.Item key="2" onClick={handleDeleteMember}>
        <Text small>{t(`common.delete`)}</Text>
      </Menu.Item>
      <Menu.Item key="3" onClick={reSendMemberAuth}>
        <Text small>
          {selectedMember?.userWorkspaceUserAuthModel?.authFlag === `Y`
            ? t(`_common.sendResetPasswordMail`)
            : t(`_common.verify`)}
        </Text>
      </Menu.Item>
    </Menu>
  );

  // Tree Node 그려주기
  const renderTreeNodes = (data) => {
    return data.map((item) => {
      const index = item.title.indexOf(searchValue);

      const beforeStr = item.title.substr(0, index);

      const afterStr = item.title.substr(index + searchValue.length);
      if (item.children) {
        // 자식 노드가 있을 경우
        // 객체 배열 정렬

        // 사용자가 먼저 나오도록 한다.
        item.children.sort((a, b) => {
          if (a.kind == "1" && b.kind == "2") {
            return -1;
          } else if (a.kind == "2" && b.kind == "1") {
            return 1;
          } else {
            return a.orderNo < b.orderNo ? -1 : a.orderNo > b.orderNo ? 1 : 0;
          }
        });

        return (
          <TreeNode
            title={
              <>
                <BankOutlined style={{ paddingRight: `5px` }} />
                <>
                  {index > -1 ? (
                    <span>
                      {beforeStr}

                      <span
                        style={{
                          color: searching && `black`,

                          fontWeight: searching && `600`,
                        }}
                      >
                        {searchValue}
                      </span>

                      {afterStr}
                    </span>
                  ) : (
                    <span>{item.title}</span>
                  )}
                </>
              </>
              // item.kind === "2"
              // ? item.title + " (" + item.childCnt + ")"
              // : item.title
            }
            key={item.key}
            icon={
              item.kind === "2" ? (
                // <FolderOutlined
                //   theme="twoTone"
                //   twoToneColor="#979797"
                //   onClick={() => alert(`fuck`)}
                // />

                <Dropdown
                  trigger={[`click`]}
                  overlay={groupMenu}
                  placement="bottomLeft"
                >
                  <MenuOutlinedExtended />
                </Dropdown>
              ) : (
                <Dropdown
                  trigger={[`click`]}
                  overlay={memberMenu}
                  placement="bottomLeft"
                >
                  <MenuOutlinedExtended />
                </Dropdown>
              )
            }
            dataRef={item}
            dataTitle={item.title}
            groupCode={item.groupCode}
          >
            {renderTreeNodes(item.children)}
          </TreeNode>
        ); // 자식 노드를 재귀 호출 한다.
      }
      // 자식 노드가 없을 경우
      return (
        <TreeNode
          {...item}
          title={
            <>
              <>
                {item.kind === "1" ? (
                  <UserOutlined style={{ paddingRight: `5px` }} />
                ) : (
                  <BankOutlined style={{ paddingRight: `5px` }} />
                )}
              </>
              <>
                {index > -1 ? (
                  <span>
                    {beforeStr}

                    <span
                      style={{
                        color: searching && `black`,

                        fontWeight: searching && `600`,
                      }}
                    >
                      {searchValue}
                    </span>

                    {afterStr}
                  </span>
                ) : (
                  <span>{item.title}</span>
                )}
              </>
            </>
          }
          key={item.key}
          icon={
            item.kind === "2" ? (
              <Dropdown
                trigger={[`click`]}
                overlay={groupMenu}
                placement="bottomLeft"
              >
                <MenuOutlinedExtended />
              </Dropdown>
            ) : (
              <Dropdown
                trigger={[`click`]}
                overlay={memberMenu}
                placement="bottomLeft"
              >
                <MenuOutlinedExtended />
              </Dropdown>
            )
          }
          dataRef={item}
          dataTitle={item.title}
          groupCode={item.groupCode}
        />
      );
    });
  };

  return (
    <>
      <Container>
        {props.loading ? (
          <PageLoading />
        ) : (
          <>
            <Input
              type="text"
              current={searchValue}
              callback={onChange}
              valid={true}
              search
              searchCallback={onSearch}
            />
            <Tree
              draggable
              showIcon
              defaultExpandedKeys={expandedKeys}
              onSelect={(selectedKeys, info) => {
                info.selected && setSelectedGroup(info.node.props);
              }}
              onDrop={onDrop}
              onExpand={onExpand}
              expandedKeys={expandedKeys}
              autoExpandParent={autoExpandParent}
            >
              {renderTreeNodes(props.treeData)}
            </Tree>
          </>
        )}
      </Container>

      <Modal
        visible={addGroupVisible}
        setVisible={setAddGroupVisible}
        cleanUp={handleAddGroupClose}
      >
        <Basement>
          <Text big color="black" textAlign="left">
            {t(`_info.addGroup`)}
          </Text>
          <Input
            type="text"
            label={t(ruleSchema.groupName.label)}
            errorMsg={t(ruleSchema.groupName.errorMsg)}
            current={groupName}
            callback={(next) => setGroupName(next)}
            valid={groupNameValid}
          />
          <Button big onClick={handleSubmitAddGroup} loading={addGroupLoading}>
            {t(`_common.continue`)}
          </Button>
        </Basement>
      </Modal>

      <Modal
        visible={modifyGroupVisible}
        setVisible={setModifyGroupVisible}
        cleanUp={handleModifyGroupClose}
      >
        <Basement>
          <Text big color="black" textAlign="left">
            {t(`_info.modifyGroup`)}
          </Text>
          <Input
            type="text"
            label={t(ruleSchema.groupName.label)}
            errorMsg={t(ruleSchema.groupName.errorMsg)}
            current={groupName}
            callback={(next) => setGroupName(next)}
            valid={groupNameValid}
          />
          <Button
            big
            onClick={handleSubmitModifyGroup}
            loading={modifyGroupLoading}
          >
            {t(`_common.continue`)}
          </Button>
        </Basement>
      </Modal>

      <Modal visible={deleteGroupVisible} setVisible={setDeleteGroupVisible}>
        <Basement>
          <Text big color="black" textAlign="left">
            {`${t("memberManagement.deleteGroupCheck")}
            ${selectedGroup.dataRef?.title}`}
          </Text>
          <Text>{t(`_info.cannotRollback`)}</Text>
          <F1>
            <Button
              onClick={handleSubmitDeleteGroup}
              loading={deleteGroupLoading}
            >
              {t(`common.delete`)}
            </Button>
            <Button onClick={handleDeleteGroupClose}>
              {t(`common.cancel`)}
            </Button>
          </F1>
        </Basement>
      </Modal>

      <Modal
        visible={addMemberVisible}
        setVisible={setAddMemberVisible}
        cleanUp={handleAddMemberClose}
      >
        <Basement>
          <Text big color="black" textAlign="left">
            {t(`_info.addMember`)}
          </Text>

          <Input
            type="text"
            label={t(ruleSchema.groupName.label)}
            current={groupName}
            disabled
          />
          <Input
            type="text"
            label={t(ruleSchema.id.label)}
            errorMsg={t(ruleSchema.id.errorMsg)}
            current={id}
            callback={(next) => setId(next)}
            valid={idValid}
          />
          <Input
            type="text"
            label={t(ruleSchema.name.label)}
            errorMsg={t(ruleSchema.name.errorMsg)}
            current={name}
            callback={(next) => setName(next)}
            valid={nameValid}
          />
          <Input
            type="text"
            label={t(ruleSchema.email.label)}
            errorMsg={t(ruleSchema.email.errorMsg)}
            current={email}
            callback={(next) => setEmail(next)}
            valid={emailValid}
          />
          <Input
            type="text"
            label={t(ruleSchema.phone.label)}
            errorMsg={t(ruleSchema.phone.errorMsg)}
            current={phone}
            callback={(next) => setPhone(next)}
            valid={phoneValid}
          />
          <Input
            type="text"
            label={t(ruleSchema.jobTitle.label)}
            errorMsg={t(ruleSchema.jobTitle.errorMsg)}
            current={jobTitle}
            callback={(next) => setJobTitle(next)}
            valid={jobTitleValid}
          />
          <div
            style={{ padding: `5px`, display: `flex`, alignItems: `center` }}
          >
            <input
              type="checkbox"
              id="agreementCheck"
              checked={isNoticeManager}
              onChange={() => {
                setIsNoticeManager(!isNoticeManager);
              }}
              style={{ margin: `0 5px` }}
            />

            <label class for="agreementCheck">
              {t("memberManagement.addToNoticeManager")}
            </label>
            &nbsp;
            <Tooltip title={t("memberManagement.whatIsNoticeManager")}>
              <InfoCircleOutlined />
            </Tooltip>
          </div>
          <Button
            big
            onClick={handleSubmitAddMember}
            loading={addMemberLoading}
          >
            {t(`_common.continue`)}
          </Button>
        </Basement>
      </Modal>

      <Modal
        visible={modifyMemberVisible}
        setVisible={setModifyMemberVisible}
        cleanUp={handleModifyMemberClose}
      >
        <Basement>
          <Text big color="black" textAlign="left">
            {t(`_info.modifyMember`)}
          </Text>

          <Input
            type="text"
            label={t(ruleSchema.groupName.label)}
            current={groupName}
            disabled
          />
          <Input
            type="text"
            label={t(ruleSchema.id.label)}
            current={id}
            disabled
          />
          <Input
            type="text"
            label={t(ruleSchema.name.label)}
            errorMsg={t(ruleSchema.name.errorMsg)}
            current={name}
            callback={(next) => setName(next)}
            valid={nameValid}
          />
          <Input
            type="text"
            label={t(ruleSchema.email.label)}
            errorMsg={t(ruleSchema.email.errorMsg)}
            current={email}
            callback={(next) => setEmail(next)}
            valid={emailValid}
          />
          <Input
            type="text"
            label={t(ruleSchema.phone.label)}
            errorMsg={t(ruleSchema.phone.errorMsg)}
            current={phone}
            callback={(next) => setPhone(next)}
            valid={phoneValid}
          />
          <Input
            type="text"
            label={t(ruleSchema.jobTitle.label)}
            errorMsg={t(ruleSchema.jobTitle.errorMsg)}
            current={jobTitle}
            callback={(next) => setJobTitle(next)}
            valid={jobTitleValid}
          />
          <div
            style={{ padding: `5px`, display: `flex`, alignItems: `center` }}
          >
            <input
              type="checkbox"
              id="agreementCheck"
              checked={isNoticeManager}
              onChange={() => {
                setIsNoticeManager(!isNoticeManager);
              }}
              style={{ margin: `0 5px` }}
            />

            <label class for="agreementCheck">
              {t("memberManagement.addToNoticeManager")}
            </label>
          </div>
          <Button
            big
            onClick={handleSubmitModifyMember}
            loading={modifyMemberLoading}
          >
            {t(`_common.continue`)}
          </Button>
        </Basement>
      </Modal>

      <Modal visible={deleteMemberVisible} setVisible={setDeleteMemberVisible}>
        <Basement>
          <Text big color="black" textAlign="left">
            {`${t(`_info.deleteMember`)}
            ${selectedMember?.name}`}
          </Text>
          <Text>{t(`_info.cannotRollback`)}</Text>
          <F1>
            <Button
              onClick={handleSubmitDeleteMember}
              loading={deleteMemberLoading}
            >
              {t(`common.delete`)}
            </Button>
            <Button onClick={handleDeleteMemberClose}>
              {t(`common.cancel`)}
            </Button>
          </F1>
        </Basement>
      </Modal>
    </>
  );
}

const Container = styled.div`
  border: 1px solid #ebebeb;
  border-radius: 1rem;
  padding: 1.5rem 1rem;
  width: 40rem;
  @media (max-width: 768px) {
    width: 90vw;
  }

  margin: 0.5rem;
  overflow: auto;
  & input {
    margin-bottom: 2rem;
  }

  .ant-tree-node-content-wrapper {
    padding-right: 30px !important;

    position: relative;

    & > span {
      right: 8px;
      top: -3px;
    }
  }

  .ant-tree-icon__customize {
    position: absolute;
    right: -100000px;
    top: -100000px;
  }
`;

const F1 = styled.div`
  display: flex;
`;

const MenuOutlinedExtended = styled(PlusSquareOutlined)`
  font-size: 10px;
  &:hover {
    background-color: #dedede;
    padding: 1px;
  }
`;
