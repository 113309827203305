import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Text } from "../../assets/common";
import styled from "styled-components";
import PlanCards from "../common/PlanCards";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@react-hook/media-query";

export default function SelectPlan(props) {
  const { t } = useTranslation();
  const match = useMediaQuery(`(max-width: 768px)`);
  const [loading, setLoading] = useState(false);
  const {
    setStep,
    skip,
    paymentInfo: { licenseList, licenseType },
    setPaymentInfo,
  } = props;
  const handleUpgrade = (idx) => {
    const current_plan = licenseList.find((v) => v.idx === idx);
    console.log('current_plan', current_plan)
    const [max_cnt] = current_plan.policyList.filter(
      (v) => v.key === "serverMaxUsers"
    );

    setPaymentInfo((prev) => ({
      ...prev,
      currentPlan: current_plan,
      wishPlan: current_plan.name,
      maxMember: Number(max_cnt.value) === 0 ? 10000 : max_cnt.value,
    }));

    setStep((prev) => prev + 1);
  };

  const handleEnterprise = () => {
    window.open(
      "http://www.ucware.net/support1.php?page=1&currentFun=all",
      "",
      "noopener noreferrer"
    );
  };

  const handleSkip = () => {
    setLoading(true);
    skip();
  };

  const FreeButton = () => (
    <Button disabled={licenseType !== "New"} onClick={() => handleUpgrade(1)}>
      {t("_common.checkout")}
    </Button>
  );

  const StandardButton = () => (
    <Button
      disabled={licenseType !== "Free" && licenseType !== "New"}
      onClick={() => handleUpgrade(2)}
    >
      {t("_common.checkout")}
    </Button>
  );

  const PremiumButton = () => (
    <Button
      disabled={
        licenseType !== "Standard" &&
        licenseType !== "Free" &&
        licenseType !== "New"
      }
      onClick={() => handleUpgrade(3)}
    >
      {t("_common.checkout")}
    </Button>
  );

  const EnterpriseButton = () => (
    <Button
      disabled={licenseType !== "New" && licenseType !== "Premium"}
      onClick={() => handleUpgrade(4)}
    >
      {t("_common.checkout")}
    </Button>
  );

  return (
    <>
      <F0>
        <Text
          super={match ? false : true}
          big={match ? true : false}
          color="black"
        >
          {t("pricing1.1")}
        </Text>
        <Text>{t("pricing1.2")}</Text>
        <Text>{t("pricing1.3")}</Text>
      </F0>
      <PlanCards
        FreeButton={<FreeButton />}
        StandardButton={<StandardButton />}
        PremiumButton={<PremiumButton />}
        EnterpriseButton={<EnterpriseButton />}
      />
    </>
  );
}

const F0 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5vw 0;
`;
