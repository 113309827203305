import React, { useEffect, useState } from "react";
import { span } from "..";
import styled from "styled-components";
import Text from "./Text";
import { SearchOutlined } from "@ant-design/icons";

export default function Input({
  type,
  label,
  errorMsg,
  current,
  callback,
  valid,
  required,
  rangeSensitive,
  search,
  searchCallback,
  ...rest
}) {
  const handleKeyPress = (e) => {
    const which = e.which;

    if (which === 13 && typeof searchCallback === `function`) {
      searchCallback();
    }
  };

  const handleChange = (e) => {
    const target = e.target;
    if (!callback) {
      return false;
    }
    if (rangeSensitive && target.value.toString().length > 5) {
      return false;
    }

    callback(target.value);
  };

  const handleRangeValidation = (e) => {
    const target = e.target;
    if (target.value < Number(rest.min)) {
      callback(rest.min);
    }

    if (target.value > Number(rest.max)) {
      callback(rest.max);
    }
  };

  return (
    <Container className={required && `required`} search={search}>
      {type === "textarea" ? (
        <textarea
          className={`${!valid && `invalid`} ${current && `exist`}`}
          {...rest}
          value={current}
          onChange={handleChange}
        />
      ) : (
        <>
          <input
            type={type}
            className={`${!valid && `invalid`} ${current && `exist`}`}
            {...rest}
            value={current}
            onChange={handleChange}
            onBlur={type === `number` && handleRangeValidation}
            onKeyPress={handleKeyPress}
          />
          {search && (
            <SearchOutlinedExtended onClick={searchCallback} search={search} />
          )}
        </>
      )}
      <label className={`${!valid && `invalid`} ${current && `exist`}`}>
        {label}
      </label>
      {!valid && errorMsg && (
        <Text small textAlign="left" className="errorMsg">
          {errorMsg}
        </Text>
      )}
    </Container>
  );
}

const SearchOutlinedExtended = styled(SearchOutlined)`
  position: absolute;
  top: 1rem;
  right: 3rem;
`;

const Container = styled.div`
  *,
  :before,
  :after {
    box-sizing: border-box;
  }

  position: relative;
  margin: 0.5rem;
  width: ${(props) => (props.search ? `30rem` : `40rem`)};
  @media (max-width: 768px) {
      width: ${(props) => (props.search ? `80vw` : `90vw`)};
    }

  &.required > label:after {
    content: "*";
    color: red;
  }

  & textarea {
    resize: none;
    height: 100%;
  }

  & input::-webkit-outer-spin-button,
  & input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & input,
  & textarea {
    background: none;
    color: #6d6d6d;
    font-size: 1.6rem;
    padding: ${(props) =>
      props.search ? `0.5rem 1rem` : `2.5rem 3rem 0.5rem 1.5rem`};
    display: block;
    /* width: ${(props) => (props.search ? `30rem` : `40rem`)}; */
    border-radius: 0.8rem;
    border: 1px solid #d2d2d7;
      width: 100%;
    /* @media (max-width: 768px) {
      width: ${(props) => (props.search ? `80vw` : `90vw`)};
    } */

    &.exist:not(:focus) ~ label {
      top: 1rem;
      font-size: 1rem;
    }

    &:disabled {
      background-color: #ebebeb;
      background: #ebebeb;
    }

    &:not(:disabled).invalid {
      & ~ label {
        color: red;
      }

      &:not(:focus) {
        border: 1px solid red;
        background-color: #fff1f4;
        & ~ label {
          top: 1rem;
          font-size: 1rem;

          background-color: #fff1f4;
        }
      }
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 4px rgba(0, 125, 250, 0.6);
    }

    &:focus ~ label {
      top: 1rem;
      font-size: 1rem;
    }
  }

  & label {
    color: gray;
    font-size: 1.6rem;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    display: inline-block;
    /* width: 100%; */
    left: 1.5rem;
    top: 1.9rem;

    transition: 300ms ease all;
  }
  & .errorMsg {
    color: red;
    width: 40rem;

    @media (max-width: 768px) {
      width: 90vw;
    }
  }
`;
