import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Text from "./Text";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { RightOutlined } from "@ant-design/icons";

export default function HistoryNavigation(props) {
  const { t } = useTranslation();
  const {
    history: {
      location: { pathname },
    },
    params,
  } = props;

  useEffect(() => {
    console.log(`pathname: `, pathname);
    console.log(`params: `, params);
  }, []);

  const renderNavigation = (pathname) => {
    switch (true) {
      case /^\/workspace$/.test(pathname):
        return (
          <Text maxContent mid color="black">
            <Link to={pathname}>{t(`_historyNavigation.workspace`)}</Link>
          </Text>
        );

      case /^\/workspace\/create/.test(pathname):
        return (
          <>
            <Text maxContent>
              <Link to={`/workspace`}>{t(`_historyNavigation.workspace`)}</Link>
            </Text>
            <RightOutlined />
            <Text maxContent color="black" mid>
              <Link to={pathname}>
                {t(`_historyNavigation.createWorkspace`)}
              </Link>
            </Text>
          </>
        );

      case /^\/workspace\/detail.*/.test(pathname):
        return (
          <>
            <Text maxContent>
              <Link to={`/workspace`}>{t(`_historyNavigation.workspace`)}</Link>
            </Text>
            <RightOutlined />
            <Text maxContent color="black" mid>
              <Link to={pathname}>
                {t(`_historyNavigation.workspaceDetail`)}
              </Link>
            </Text>
          </>
        );

      case /^\/workspace\/detail.*/.test(pathname):
        return (
          <>
            <Text maxContent>
              <Link to={`/workspace`}>{t(`_historyNavigation.workspace`)}</Link>
            </Text>
            <RightOutlined />
            <Text maxContent color="black" mid>
              <Link to={pathname}>
                {t(`_historyNavigation.workspaceDetail`)}
              </Link>
            </Text>
          </>
        );

      case /^\/payment\/license.*/.test(pathname):
        return (
          <>
            <Text maxContent>
              <Link to={`/workspace`}>{t(`_historyNavigation.workspace`)}</Link>
            </Text>
            <RightOutlined />
            <Text maxContent>
              <Link to={`/workspace/detail/${params.workspaceRestCode}`}>
                {t(`_historyNavigation.workspaceDetail`)}
              </Link>
            </Text>
            <RightOutlined />
            <Text maxContent color="black" mid>
              <Link to={pathname}>
                {t(`_historyNavigation.paymentLicense`)}
              </Link>
            </Text>
          </>
        );

      case /^\/payment\/member.*/.test(pathname):
        return (
          <>
            <Text maxContent>
              <Link to={`/workspace`}>{t(`_historyNavigation.workspace`)}</Link>
            </Text>
            <RightOutlined />
            <Text maxContent>
              <Link to={`/workspace/detail/${params.workspaceRestCode}`}>
                {t(`_historyNavigation.workspaceDetail`)}
              </Link>
            </Text>
            <RightOutlined />
            <Text maxContent color="black" mid>
              <Link to={pathname}>{t(`_historyNavigation.paymentMember`)}</Link>
            </Text>
          </>
        );

      case /^\/payment\/storage.*/.test(pathname):
        return (
          <>
            <Text maxContent>
              <Link to={`/workspace`}>{t(`_historyNavigation.workspace`)}</Link>
            </Text>
            <RightOutlined />
            <Text maxContent>
              <Link to={`/workspace/detail/${params.workspaceRestCode}`}>
                {t(`_historyNavigation.workspaceDetail`)}
              </Link>
            </Text>
            <RightOutlined />
            <Text maxContent color="black" mid>
              <Link to={pathname}>
                {t(`_historyNavigation.paymentStorage`)}
              </Link>
            </Text>
          </>
        );

      case /^\/workspace\/organization.*/.test(pathname):
        return (
          <>
            <Text maxContent>
              <Link to={`/workspace`}>{t(`_historyNavigation.workspace`)}</Link>
            </Text>
            <RightOutlined />
            <Text maxContent>
              <Link to={`/workspace/detail/${params.restCode}`}>
                {t(`_historyNavigation.workspaceDetail`)}
              </Link>
            </Text>
            <RightOutlined />
            <Text maxContent color="black" mid>
              <Link to={pathname}>
                {t(`_historyNavigation.memberManagement`)}
              </Link>
            </Text>
          </>
        );

      case /^\/login/.test(pathname):
        return (
          <>
            <Text maxContent color="black" mid>
              <Link to={pathname}>{t(`_historyNavigation.login`)}</Link>
            </Text>
          </>
        );
      case /^\/reset_user/.test(pathname):
        return (
          <>
            <Text maxContent>
              <Link to={`/login`}>{t(`_historyNavigation.login`)}</Link>
            </Text>
            <RightOutlined />
            <Text maxContent color="black" mid>
              <Link to={pathname}>
                {t(`_historyNavigation.forgotPassword`)}
              </Link>
            </Text>
          </>
        );
      case /^\/register/.test(pathname):
        return (
          <>
            <Text maxContent>
              <Link to={`/login`}>{t(`_historyNavigation.login`)}</Link>
            </Text>
            <RightOutlined />
            <Text maxContent color="black" mid>
              <Link to={pathname}>{t(`_historyNavigation.register`)}</Link>
            </Text>
          </>
        );

      default:
    }
  };

  return (
    <Container>
      <F1>{renderNavigation(pathname)}</F1>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  background-color: #f5f5f7;
  height: 60px;
  width: 100vw;
  overflow: auto;

  @media (max-width: 768px) {
    justify-content: flex-start;
  }
`;

const F1 = styled.div`
  width: 970px;
  display: flex;
  align-items: center;
  margin: 0px 20px;

  & a {
    transition: all 0.3s;
  }

  & a:hover {
    text-decoration: none;
    opacity: 0.7;
    transition: all 0.3s;
  }
`;
