/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PlanCards from "../components/common/PlanCards";
import { Text, Button, Title } from "../assets/common";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import { useMediaQuery } from "@react-hook/media-query";

export default function Pricing(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const match = useMediaQuery(`(max-width: 768px)`);
  const handleClick = () => {
    const token = localStorage.getItem(`accessToken`);

    if (token) {
      history.push(`/workspace`);
    } else {
      history.push(`/login`);
    }
  };

  const tryButton = <Button onClick={handleClick}>{t(`_common.try`)}</Button>;

  return (
    <Container>
      <Title title={t(`_title.pricing`)} src="/images/buyBg.jpg" />
      <F0>
        <Text
          super={match ? false : true}
          big={match ? true : false}
          color="black"
        >
          {t("pricing1.1")}
        </Text>
        <Text>{t("pricing1.2")}</Text>
        <Text>{t("pricing1.3")}</Text>
      </F0>
      <PlanCards
        FreeButton={tryButton}
        StandardButton={tryButton}
        PremiumButton={tryButton}
        EnterpriseButton={tryButton}
      />
    </Container>
  );
}

const F0 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5vw 0;
`;

const Container = styled.div``;
