import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { SERVER_URI, header } from "../assets/utils/HttpHandler";
import PaymentInfomation from "../components/MemberPayment/PaymentInfomation";
import PageLoading from "../assets/utils/PageLoading";
import { Basement, Text, HistoryNavigation } from "../assets/common";
import { Link, useParams, useHistory } from "react-router-dom";
import Result from "../components/common/Result";
import moment from "moment";

export default function MemberPayment(props) {
  const params = useParams();
  const { workspaceName, workspaceRestCode, licenseType } = params;
  const history = useHistory();
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [orderable, __orderable] = useState(true); // 구매 가능 여부
  const [isFree, setIsFree] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState({
    adminName: "",
    adminEmail: "",
    currentMember: 1, // 현재 멤버 수
    wishMember: 1,
    currentMemberPrice: 0, // 멤버 당 가격
    wishPriceRestCode: "",
    totalPrice: 0,

    teamName: "",
    systemLicense: "",
    validEndDate: "",
    maxMember: 0,
    workspaceRestCode,
  });

  useEffect(() => {
    getWorkspaceInfo();
  }, []);

  const getWorkspaceInfo = async () => {
    try {
      const { data } = await axios.get(
        `${SERVER_URI}/api/workspace/${workspaceRestCode}`,
        header()
      );

      const isFree = data.userLicense[0]?.systemLicense?.name === `Free`;
      setIsFree(isFree);

      const [userLicense] = data.userLicense.filter(
        (v) => v.serviceStatus === "Y" && v.systemLicense.name !== "Storage"
      );

      const [max_cnt] = userLicense.systemLicense.policyList.filter(
        (v) => v.key === "serverMaxUsers"
      );

      setPaymentInfo((prev) => ({
        ...prev,
        teamName: data.teamName,
        systemLicense: userLicense.systemLicense.name,
        validEndDate: userLicense.validEndDate,
        maxMember: Number(max_cnt.value) === 0 ? 10000 : max_cnt.value,
      }));

      // 현재 사용중인 라이선스에 적용된 가격과 현재 일자부터 라이선스 종료일까지 멤버 1인당 금액 측정 API -> system_price에서 멤버 추가 비용 가져오는 로직으로 변경 필요
      const { data: perPrice } = await axios.get(
        `${SERVER_URI}/api/user/license/current-price?workspaceRestCode=${workspaceRestCode}`,
        header()
      );

      const { data: user } = await axios.get(
        `${SERVER_URI}/api/user`,
        header()
      );

      console.log(`per price: `, perPrice);

      setPaymentInfo((prev) => ({
        ...prev,
        wishPriceRestCode: perPrice.restCode,
        currentMemberPrice: isFree ? 0 : perPrice.price,
        currentMember: userLicense.count,
        adminName: user.id,
        adminEmail: user.email,
      }));

      // __orderable(
      //   10000 - userLicense.count === 0 ||
      //     max_cnt.value - userLicense.count === 0
      //     ? false
      //     : true
      // );

      setLoading(false);
    } catch (e) {
      history.push("/workspace");
    }
  };

  const paymentPayload = [
    { label: `paymentInfo.teamName`, value: paymentInfo.teamName },
    { label: `paymentInfo.planInfo`, value: paymentInfo.systemLicense },
    {
      label: `paymentInfo.date`,
      value: `${moment().format("YYYY-MM-DD")} ~ ${moment(
        Number(paymentInfo.validEndDate)
      ).format("YYYY-MM-DD")}`,
    },
    {
      label: `paymentInfo.pricePerMember`,
      value: `₩${Number(
        paymentInfo.currentMemberPrice * 1000
      ).toLocaleString()}`,
    },
    {
      label: `paymentInfo.wishMemberNumber`,
      value: paymentInfo.wishMember,
    },
    { label: `paymentInfo.adminName`, value: paymentInfo.adminName },
    { label: `paymentInfo.adminEmail`, value: paymentInfo.adminEmail },
    {
      label: `paymentInfo.payPrice`,
      value: `₩${Number(paymentInfo.totalPrice).toLocaleString()}`,
    },
  ];

  return (
    <>
      <HistoryNavigation history={history} params={params} />
      {step === 0 &&
        (loading ? (
          <PageLoading />
        ) : (
          <PaymentInfomation
            paymentInfo={paymentInfo}
            setPaymentInfo={setPaymentInfo}
            setStep={setStep}
            isFree={isFree}
            setSuccess={setSuccess}
          />
        ))}
      {step === 1 && <Result success={success} paymentInfo={paymentPayload} />}
      {/* {!orderable && (
        <Basement>
          <Text big color="black" textAlign="left">
            {t("_info.memberPaymentError")}
          </Text>

          <Text textAlign="left">
            {paymentInfo.systemLicense.toUpperCase() === "FREE"
              ? t("_info.memberPaymentErrorFree")
              : Number(paymentInfo.maxMember) === paymentInfo.currentMember
              ? t("_info.memberPaymentErrorLimit")
              : Number(paymentInfo.currentMemberPrice) === 0
              ? t("_info.memberPaymentError500")
              : ""}
          </Text>
          {Number(paymentInfo.maxMember) === paymentInfo.currentMember && (
            <Text textAlign="left">
              <Link
                to={`/payment/license/${workspaceName}/${workspaceRestCode}/${licenseType}`}
              >
                {t("_workspaceDetail.wantToUpgrade")}
              </Link>
            </Text>
          )}
          <Text>
            <Link to={`/workspace/detail/${workspaceRestCode}`}>
              {t("success.goBack")}
            </Link>
          </Text>
        </Basement>
      )} */}
    </>
  );
}
