import React, { useEffect } from "react";
import CountUp from "../components/Landing/Counter";
import Slick from "../components/Landing/Slick";
import BlockOne from "../components/Landing/BlockOne";
import BlockThree from "../components/Landing/BlockThree";
import ReactGA from "react-ga";
import { BackTop } from "antd";
import Pricing from "./Pricing";
import styled from "styled-components";
import { Text, Button } from "../assets/common";
import { useMediaQuery } from "@react-hook/media-query";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PlanCards from "../components/common/PlanCards";

export default function Landing() {
  const { t } = useTranslation();
  const history = useHistory();
  const match = useMediaQuery(`(max-width: 768px)`);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const handleClick = () => {
    const token = localStorage.getItem(`accessToken`);
    if (token) {
      history.push(`/workspace`);
    } else {
      history.push(`/login`);
    }
  };

  const tryButton = <Button onClick={handleClick}>{t(`_common.try`)}</Button>;

  return (
    <>
      <BlockOne />
      <CountUp />
      <BlockThree />

      <Slick />
      <F1>
        <F0>
          <Text
            super={match ? false : true}
            big={match ? true : false}
            color="#fff"
          >
            {t("pricing1.1")}
          </Text>
          <Text color="#fff">{t("pricing1.2")}</Text>
          <Text color="#fff">{t("pricing1.3")}</Text>
        </F0>

        <PlanCards
          FreeButton={tryButton}
          StandardButton={tryButton}
          PremiumButton={tryButton}
          EnterpriseButton={tryButton}
        />
      </F1>
      <BackTop />
    </>
  );
}

const F0 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const F1 = styled.div`
  background-color: #07c9ff;
  padding: 10rem 0;
`;
