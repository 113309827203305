import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";
import { Text, Button, Input, Basement, useInput } from "../../assets/common";
import { ruleSchema } from "../../assets/common/util/ruleSchema";
import { useDispatch } from "react-redux";
import { configActions } from "../../assets/common/reducer/config";
import axios from "axios";
import { SERVER_URI, header } from "../../assets/utils/HttpHandler";
import useMeasure from "react-use-measure";
import RequestPay from "../../assets/utils/RequestPay";
import { useHistory } from "react-router-dom";
import { ResizeObserver } from "@juggle/resize-observer";

export default function PaymentInformation(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [ref, bounds] = useMeasure({ polyfill: ResizeObserver });
  const [loading, setLoading] = useState(false);
  const {
    paymentInfo,
    paymentInfo: {
      wishStorage,
      currentStoragePrice,
      workspaceName,
      workspaceRestCode,
      wishPriceRestCode,
      totalPrice,
    },
    setSuccess,
    setPaymentInfo,
    setStep,
  } = props;
  const [blockCount, setBlockCount] = useState(wishStorage);
  const [adminNameValid, setAdminNameValid, adminName, setAdminName] = useInput(
    paymentInfo.adminName,
    ruleSchema.adminName.rule,
    null
  );
  const [
    adminEmailValid,
    setAdminEmailValid,
    adminEmail,
    setAdminEmail,
  ] = useInput(paymentInfo.adminEmail, ruleSchema.adminEmail.rule, null);

  useEffect(() => {
    dispatch(configActions.config.expand.on(bounds.height));

    return () => {
      dispatch(configActions.config.expand.off());
    };
  }, [bounds.height]);

  useEffect(() => {
    setPaymentInfo((prev) => ({
      ...prev,
      wishStorage: blockCount,
      totalPrice: blockCount * currentStoragePrice * 1000,
    }));
  }, [blockCount]);

  const pay = (param) => {
    setLoading(true);

    RequestPay(param, async (res, data = undefined) => {
      if (res) {
        try {
          await axios.post(`${SERVER_URI}/api/order/license`, data, header());
          setSuccess(true);
          setStep((prev) => prev + 1);
        } catch (error) {
          setStep((prev) => prev + 1);
        }
      } else {
        setStep((prev) => prev + 1);
      }
    });
  };

  const param = {
    imp: {
      pg: "html5_inicis",
      pay_method: "card",
      name: `Storage`,
      amount: Number(totalPrice),
      buyer_email: `${paymentInfo.adminEmail.trim()}`,
      buyer_name: `${paymentInfo.adminName}`,
      currency: `KRW`,
      language: `ko`,
      m_redirect_url: `${SERVER_URI}/payment/license/complete`,
    },
    portal: {
      price: Number(totalPrice),
      orderFlag: "Y",
      count: Number(`${wishStorage}`),
      workspaceRestCode: `${workspaceRestCode}`,
      priceRestCode: `${wishPriceRestCode}`,
      targetLicenseRestCode: "",
      userOrderPg: {
        name: "Storage",
        price: Number(totalPrice),
        currency: `KRW`,
        buyerName: `${paymentInfo.adminName}`,
        buyerEmail: `${paymentInfo.adminEmail.trim()}`,
      },
    },
  };

  const handleSubmit = () => {
    let valid = true;
    if (!adminNameValid || !adminName.length) {
      setAdminNameValid(false);
      valid = false;
    }
    if (!adminEmailValid || !adminEmail.length) {
      setAdminEmailValid(false);
      valid = false;
    }

    if (valid) {
      pay(param);
    }
  };

  const handleCancel = () => {
    history.push(`/workspace/detail/${workspaceRestCode}`);
  };

  return (
    <>
      <Basement>
        <Text>{t("paymentInfo.information")}</Text>

        <Input
          type="text"
          label={t("paymentInfo.teamName")}
          current={workspaceName}
          disabled
        />
        <Input
          type="text"
          label={t(ruleSchema.adminName.label)}
          errorMsg={ruleSchema.adminName.errorMsg}
          current={adminName}
          callback={(next) => setAdminName(next)}
          valid={adminNameValid}
        />
        <Input
          type="text"
          label={t(ruleSchema.adminEmail.label)}
          errorMsg={ruleSchema.adminEmail.errorMsg}
          current={adminEmail}
          callback={(next) => setAdminEmail(next)}
          valid={adminEmailValid}
        />
        <Input
          type="number"
          label={t("paymentInfo.storageCount")}
          current={blockCount}
          callback={(next) => setBlockCount(next)}
          valid={true}
          min={blockCount}
          max={200}
          rangeSensitive
        />
      </Basement>
      <F1 ref={ref}>
        <F2>
          <F3>
            <F4>
              <Text small textAlign="right">
                {t("paymentInfo.leftStoragePerPrice")}
              </Text>
              <TextExtended mid color="black">{`₩${Number(
                currentStoragePrice * 1000
              ).toLocaleString()}`}</TextExtended>
            </F4>

            <F4>
              <Text small textAlign="right">
                {t("paymentInfo.totalStorageSize")}
              </Text>
              <TextExtended mid color="black">{`${Number(
                blockCount * 10
              ).toLocaleString()}GB`}</TextExtended>
            </F4>

            <F4>
              <Text small textAlign="right">
                {t("paymentInfo.payPrice")}
              </Text>
              <TextExtended mid color="black">{`₩${Number(
                totalPrice
              ).toLocaleString()}`}</TextExtended>
            </F4>
          </F3>
          <F3_1>
            <Button onClick={handleSubmit} loading={loading}>
              {t("paymentInfo.checkOut")}
            </Button>
            <Button onClick={handleCancel}>{t("_common.cancel")}</Button>
          </F3_1>
        </F2>
      </F1>
    </>
  );
}

const F1 = styled.div`
  /* height: 18rem; */
  bottom: 0;
  width: 100%;
  position: fixed;
  background-color: #fff;
  border-top: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* padding: 3vh; */
`;

const F2 = styled.div`
  width: 970px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const F3 = styled.div`
  /* width: 90%; */
  display: flex;

  flex-direction: column;
  justify-content: baseline;
`;

const F3_1 = styled.div`
  margin: 1rem 1rem 0 1rem;
  padding: 0.5rem 0;
`;

const F4 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`;

const TextExtended = styled(Text)`
  padding: 0 0.5rem;
`;
