import React, { useState, useEffect } from "react";
import axios from "axios";
import { SERVER_URI, header } from "../assets/utils/HttpHandler";
import { useParams, useHistory } from "react-router-dom";
import { Tooltip, message } from "antd";
import IconInfo from "../assets/images/icon_info.svg";

import { useTranslation } from "react-i18next";
import {
  Button,
  Input,
  Basement,
  Text,
  Image,
  delay,
  useInput,
} from "../assets/common";
import styled from "styled-components";
import PageLoading from "../assets/utils/PageLoading";
import { useVerify } from "../assets/common";
import { ruleSchema } from "../assets/common/util/ruleSchema";

export default function ResetPassword() {
  const { t } = useTranslation();
  const history = useHistory();
  const { restCode } = useParams();
  const [verified, loading] = useVerify(restCode);
  const [pwValid, setPwValid, pw, setPw] = useInput(
    ``,
    ruleSchema.pw.rule,
    null
  );
  const [pwConfirmValid, setPwConfirmValid, pwConfirm, setPwConfirm] = useInput(
    ``,
    null,
    pw
  );
  const [modifyLoading, setModifyLoading] = useState(false);

  const handleSubmit = (e) => {
    let valid = true;
    if (!pwValid || !pw.length) {
      setPwValid(false);
      valid = false;
    }
    if (!pwConfirmValid) {
      setPwConfirmValid(false);
      valid = false;
    }

    if (valid) {
      const payload = { restCode, pw };
      modify(payload);
    }
  };

  const modify = async (payload) => {
    setModifyLoading(true);
    try {
      await delay();
      const response = await axios.put(
        `${SERVER_URI}/api/auth/reset-pw`,
        payload,
        header()
      );
      if (!response.data) {
        console.log(`modify failed: `, response);
        message.error(t(`_message.failToChangePw`));
      }
      message.success(t(`_message.successToChangePw`));
      setTimeout(() => history.push(`/login`), 3000);
    } catch (error) {
      console.log(`modify failed: `, error);
      message.error(t(`_message.failToChangePw`));
    } finally {
      setModifyLoading(false);
    }
  };

  return loading ? (
    <PageLoading />
  ) : (
    verified && (
      <Basement>
        <F1>
          <Text big color="black">
            {t("_title.resetPw")}
          </Text>

          <Tooltip title={t("_info.resetPw")} placement="rightTop">
            <Image src={IconInfo} width="2rem" height="2rem" />
          </Tooltip>
        </F1>

        <Input
          type="password"
          label={t(ruleSchema.pw.label)}
          errorMsg={t(ruleSchema.pw.errorMsg)}
          current={pw}
          callback={(next) => setPw(next)}
          valid={pwValid}
          autoComplete="off"
        />
        <Input
          type="password"
          label={t(ruleSchema.pwConfirm.label)}
          errorMsg={t(ruleSchema.pwConfirm.errorMsg)}
          current={pwConfirm}
          callback={(next) => setPwConfirm(next)}
          valid={pwConfirmValid}
          autoComplete="off"
        />
        <div>
          <Button big onClick={handleSubmit} loading={modifyLoading}>
            {t("_common.complete")}
          </Button>
        </div>
      </Basement>
    )
  );
}

const F1 = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
`;
