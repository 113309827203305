import React from "react";
import ReactDOM from "react-dom";
import App from "./routes/App";
import { BrowserRouter } from "react-router-dom";
import "./assets/css/reset.css";
import "./i18n";
import "core-js/stable";
import "regenerator-runtime/runtime";
import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "antd/dist/antd.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Provider } from "react-redux";
import { createStore } from "redux";
import rootReducer from "./assets/common/reducer";

const configureStore = () => {
  const store = createStore(rootReducer);
  return store;
};

ReactDOM.render(
  <Provider store={configureStore()}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
