/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint no-restricted-globals: ["off"] */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { HEADER, SERVER_URI } from "../assets/utils/HttpHandler";
import PrivatePolicy from "./PrivatePolicy";
import TermsConditions from "./TermsConditions";
import { Countries } from "../components/Register/Countries";
import { Modal, message } from "antd";
import { useHistory } from "react-router-dom";
import {
  Input,
  Select,
  Basement,
  Button,
  Text,
  delay,
  useSelect,
  useInput,
  HistoryNavigation,
} from "../assets/common";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { ruleSchema } from "../assets/common/util/ruleSchema";

export default function Register() {
  const { info, warning } = Modal;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [idValid, setIdValid, id, setId] = useInput(
    ``,
    ruleSchema.id.rule,
    null
  );
  const [pwValid, setPwValid, pw, setPw] = useInput(
    ``,
    ruleSchema.pw.rule,
    null
  );
  const [pwConfirmValid, setPwConfirmValid, pwConfirm, setPwConfirm] = useInput(
    ``,
    null,
    pw
  );
  const [nameValid, setNameValid, name, setName] = useInput(
    ``,
    ruleSchema.name.rule,
    null
  );
  const [emailValid, setEmailValid, email, setEmail] = useInput(
    ``,
    ruleSchema.email.rule,
    null
  );
  const [phoneValid, setPhoneValid, phone, setPhone] = useInput(
    ``,
    ruleSchema.phone.rule,
    null
  );
  const [officeFax, setOfficeFax] = useState(``);
  const [officePhone, setOfficePhone] = useState(``);
  const [current, setCurrent] = useSelect(0, Countries);
  const [agreementChecked, setAgreementChecked] = useState(false);

  const countryClickCallback = (next) => {
    setCurrent(next);
  };
  const handleAgreementChange = (e) => {
    setAgreementChecked((prev) => !prev);
  };

  const handleSubmit = () => {
    let valid = true;
    if (!idValid || !id.length) {
      setIdValid(false);
      valid = false;
    }

    if (!pwValid || !pw.length) {
      setPwValid(false);
      valid = false;
    }

    if (!pwConfirmValid) {
      setPwConfirmValid(false);
      valid = false;
    }

    if (!nameValid || !name.length) {
      setNameValid(false);
      valid = false;
    }

    if (!emailValid || !email.length) {
      setEmailValid(false);
      valid = false;
    }

    if (!phoneValid || !phone.length) {
      setPhone(false);
      valid = false;
    }

    if (!agreementChecked) {
      const modal = warning({
        title: t("_register.pleaseAccept"),
        okText: t("_register.ok"),
        width: 700,
      });
      valid = false;
    }

    if (valid) {
      const payload = {
        id,
        pw,
        name,
        email: email.trim(),
        phone,
        country: current.key,
        officeFax,
        officePhone,
      };

      register(payload);
    }
  };

  const checkDuplicated = async (id) => {
    const response = await axios.get(
      `${SERVER_URI}/api/auth/check-id?id=${id}`
    );
    return response.data;
  };

  const register = async (payload) => {
    try {
      setLoading(true);

      await delay();
      const valid = await checkDuplicated(payload.id);

      if (!valid) {
        message.error(t(`_message.duplicatedId`));
        setLoading(false);
        return false;
      }

      const response = await axios.post(
        `${SERVER_URI}/api/auth/register`,
        payload
      );

      if (!response) {
        message.error(t(`_message.failToGetRestCode`));
        setLoading(false);
        return false;
      }

      history.push(`/register_complete/${payload.email}`);
    } catch (error) {
      message.error(t(`_message.failToSignUp`));
      setLoading(false);
    }
  };

  const handleModal = (e) => {
    e.stopPropagation();
    const target = e.target;
    const modal = info({
      title:
        target.name === `term`
          ? `${t("_register.termOf")}`
          : `${t("_register.policyOf")}`,
      content: target.name === `term` ? <TermsConditions /> : <PrivatePolicy />,
      okText: t("_register.ok"),
      width: 700,
    });
  };

  return (
    <>
      <HistoryNavigation history={history} />
      <Basement>
        <Text big color="black" textAlign="left">
          {t("_info.signingIn")}
        </Text>

        <Input
          type="text"
          label={t(ruleSchema.id.label)}
          errorMsg={t(ruleSchema.id.errorMsg)}
          current={id}
          callback={(next) => setId(next)}
          valid={idValid}
          autoComplete="off"
          required
        />
        <Input
          type="password"
          label={t(ruleSchema.pw.label)}
          errorMsg={t(ruleSchema.pw.errorMsg)}
          current={pw}
          callback={(next) => setPw(next)}
          valid={pwValid}
          autoComplete="off"
          required
        />
        <Input
          type="password"
          label={t(ruleSchema.pwConfirm.label)}
          errorMsg={t(ruleSchema.pwConfirm.errorMsg)}
          current={pwConfirm}
          callback={(next) => setPwConfirm(next)}
          valid={pwConfirmValid}
          autoComplete="off"
          required
        />
        <Input
          type="text"
          label={t(ruleSchema.name.label)}
          errorMsg={t(ruleSchema.name.errorMsg)}
          current={name}
          callback={(next) => setName(next)}
          valid={nameValid}
          autoComplete="off"
          required
        />
        <Input
          type="text"
          label={t(ruleSchema.email.label)}
          errorMsg={t(ruleSchema.email.errorMsg)}
          current={email}
          callback={(next) => setEmail(next)}
          valid={emailValid}
          autoComplete="off"
          required
        />
        <Input
          type="text"
          label={t(ruleSchema.phone.label)}
          errorMsg={t(ruleSchema.phone.errorMsg)}
          current={phone}
          callback={(next) => setPhone(next)}
          valid={phoneValid}
          autoComplete="off"
          required
        />
        <Input
          type="text"
          label={t("_form.officeFax")}
          current={officeFax}
          callback={(next) => setOfficeFax(next)}
          valid={true}
          autoComplete="off"
        />
        <Input
          type="text"
          label={t("_form.officePhone")}
          current={officePhone}
          callback={(next) => setOfficePhone(next)}
          valid={true}
          autoComplete="off"
        />

        <Select
          label={t("_form.country")}
          list={Countries}
          current={current}
          callback={countryClickCallback}
        />

        <F1>
          <input
            type="checkbox"
            id="agreementCheck"
            value={agreementChecked.value}
            onChange={handleAgreementChange}
          />

          <label class for="agreementCheck">
            <Text>
              {localStorage.getItem("i18nextLng") === "ko" ? (
                <F2>
                  <a onClick={handleModal}>{t("_register.term")}</a>
                  <span>/</span>
                  <a onClick={handleModal}>{t("_register.policy")}</a>
                  <span>에 동의합니다</span>
                </F2>
              ) : (
                <F2>
                  <span style={{ margin: `0 2px` }}>I Accept</span>
                  <a onClick={handleModal}>{t("_register.term")}</a>
                  <span style={{ margin: `0 2px` }}>and</span>
                  <a onClick={handleModal}>{t("_register.policy")}</a>
                </F2>
              )}
            </Text>
          </label>
        </F1>
        <Button big onClick={handleSubmit} loading={loading}>
          {t("_register.registerBtn")}
        </Button>
      </Basement>
    </>
  );
}

const F1 = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
`;

const F2 = styled.div`
  display: flex;
  align-items: baseline;
  padding: 0 0.5rem;
`;
