import React, { useState, useEffect } from "react";
import axios from "axios";
import { HEADER, SERVER_URI } from "../../assets/utils/HttpHandler";
import { Typography, Alert } from "antd";
import { Table, Text } from "../../assets/common";
import PageLoading from "../../assets/utils/PageLoading";
import moment from "moment";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

export default function WorkspaceBillingHistory({ workspaceRestCode }) {
  const [billingLists, setBillingLists] = useState([]);
  const [loadingBilling, setLoadingBilling] = useState(false);
  const [nextPayDate, setNextPayDate] = useState("");
  const { t } = useTranslation();
  const column = [
    { label: t("_workspaceHistory.type"), type: `text` },
    { label: t("_workspaceHistory.amount"), type: `text` },
    { label: t("_workspaceHistory.headCount"), type: `text` },
    { label: t("_workspaceHistory.period"), type: `text` },
    { label: t("_workspaceHistory.card"), type: `text` },
    { label: t("_workspaceHistory.date"), type: `text` },
  ];
  useEffect(() => {
    setLoadingBilling(true);
    axios
      .get(
        `${SERVER_URI}/api/workspace/${workspaceRestCode}/order/list`,
        HEADER
      )
      .then((response) => {
        const billingResponse = response.data;

        let data = [];

        billingResponse.map((res, i) => {
          if (
            res.systemLicense.type === "L" &&
            res.systemLicense.name.toUpperCase() !== "FREE"
          ) {
            setNextPayDate(res.payDate);
          }

          res.userOrder.map((userOrders, i) => {
            if (userOrders.orderFlag !== "N") {
              const dateValue = userOrders.userOrderPg.paidAt + "000";
              data = data.concat({
                type: `${userOrders.userOrderPg.name}`,
                price: `₩${Number(
                  userOrders.userOrderPg.price
                ).toLocaleString()}`,
                count: `${userOrders.count}`,
                cycle: `${
                  Number(userOrders.cycle) === 1
                    ? "1 Month"
                    : Number(userOrders.cycle) === 6
                    ? "6 Months"
                    : Number(userOrders.cycle) === 12
                    ? "1 Year"
                    : ""
                }`,

                card: `${userOrders.userOrderPg.userOrderPgCard.cardName}`,

                date: `${moment(Number(dateValue)).format("YYYY-MM-DD HH:mm")}`,
              });
            }
            return null;
          });

          return data;
        });

        setBillingLists(data);
        setLoadingBilling(false);
      });
  }, []);

  return loadingBilling ? (
    <PageLoading />
  ) : (
    <Container>
      <Table column={column} payload={billingLists} shadow />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  margin: 30px 0;
`;
