/* eslint-disable no-useless-escape */
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { SERVER_URI, header } from "../assets/utils/HttpHandler";
import { useHistory } from "react-router-dom";
import IconInfo from "../assets/images/icon_info.svg";
import {
  useInput,
  delay,
  Text,
  Input,
  Basement,
  Button,
  HistoryNavigation,
  Select,
} from "../assets/common";
import { ruleSchema } from "../assets/common/util/ruleSchema";
import { message } from "antd";

export default function CreateWorkspace() {
  const history = useHistory();
  const { t } = useTranslation(null, { useSuspense: true });
  const [loading, setLoading] = useState(false);
  const [
    workspaceNameValid,
    setWorkspaceNameValid,
    workspaceName,
    setWorkspaceName,
  ] = useInput(``, ruleSchema.workspaceName.rule, null);
  const [
    workspaceCodeValid,
    setWorkspaceCodeValid,
    workspaceCode,
    setWorkspaceCode,
  ] = useInput(``, ruleSchema.workspaceCode.rule, null);
  const [
    workspaceDescriptionValid,
    setWorkspaceDescribtionValid,
    workspaceDescription,
    setWorkspaceDescription,
  ] = useInput(``, ruleSchema.workspaceDescription.rule, null);

  const [region, setRegion] = useState("KR");

  const handleRegionChange = (region) => {
    setRegion(region);
  };

  const handleSubmit = () => {
    let valid = true;

    if (!workspaceNameValid || !workspaceName.length) {
      setWorkspaceNameValid(false);
      valid = false;
    }

    if (!workspaceCodeValid || !workspaceCode.length) {
      setWorkspaceCodeValid(false);
      valid = false;
    }
    if (!workspaceDescriptionValid || !workspaceDescription.length) {
      setWorkspaceDescribtionValid(false);
      valid = false;
    }

    if (valid) {
      const payload = {
        teamCode: workspaceCode.toLowerCase(),
        teamName: workspaceName,
        teamDescription: workspaceDescription,
        region,
      };

      console.log(`payload: `, payload);

      createWorkspace(payload);
    }
  };

  const checkWorkspaceCodeDuplicated = async (workspaceCode) => {
    const response = await axios.get(
      `${SERVER_URI}/api/workspace/check-teamcode?teamCode=${workspaceCode}`,
      header()
    );
    return response.data;
  };

  const createWorkspace = async (payload) => {
    try {
      setLoading(true);

      await delay();
      const valid = await checkWorkspaceCodeDuplicated(payload.teamCode);
      if (valid) {
        const response = await axios.post(
          `${SERVER_URI}/api/workspace/create`,
          payload,
          header()
        );
        if (response) {
          message.success(t(`_message.createWorkspace`));
          setTimeout(() => {
            history.push("/workspace");
          }, 3000);
        }
      } else {
        message.error(t(`_message.duplicatedWorkspaceCode`));
      }
    } catch (error) {
      message.error(t(`_message.failToCreateWorkspace`));
    }
  };

  const country = [
    { key: "KR", value: "korea" },
    // { key: "JP", value: "japan" },
  ];

  return (
    <>
      <HistoryNavigation history={history} />
      <Basement>
        <Input
          type="text"
          label={t(ruleSchema.workspaceName.label)}
          errorMsg={t(ruleSchema.workspaceName.errorMsg)}
          current={workspaceName}
          callback={(next) => setWorkspaceName(next)}
          valid={workspaceNameValid}
        />
        <Text small style={{ marginLeft: `5px`, paddingBottom: `0px` }}>
          {t(`_createWorkspace.workspaceCode`)}
        </Text>
        <Input
          type="text"
          label={t(ruleSchema.workspaceCode.label)}
          errorMsg={t(ruleSchema.workspaceCode.errorMsg)}
          current={workspaceCode}
          callback={(next) => setWorkspaceCode(next)}
          valid={workspaceCodeValid}
        />

        <Input
          type="text"
          label={t(ruleSchema.workspaceDescription.label)}
          errorMsg={t(ruleSchema.workspaceDescription.errorMsg)}
          current={workspaceDescription}
          callback={(next) => setWorkspaceDescription(next)}
          valid={workspaceDescriptionValid}
        />

        <Select
          list={country}
          label={t("region")}
          current="KR"
          callback={(next) => {
            setRegion(next?.key);
          }}
        />

        <Button big onClick={handleSubmit} loading={loading}>
          {t(`_common.continue`)}
        </Button>
      </Basement>
    </>
  );
}
