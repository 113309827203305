import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { SERVER_URI, header } from "../assets/utils/HttpHandler";
import { message } from "antd";
import PageLoading from "../assets/utils/PageLoading";
import { Text, Button, Basement } from "../assets/common";
import { Link, useParams, useHistory } from "react-router-dom";

export default function VerifyAccount(props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [verified, setVerified] = useState(false);
  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    const payload = { restCode: params.restCode };

    verifyAccount(payload);
  }, []);

  const verifyAccount = async (payload) => {
    try {
      const response = await axios.post(
        `${SERVER_URI}/api/auth/verify-account`,
        payload
      );

      if (response.data) {
        setVerified(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const reSend = async (payload) => {
    try {
      const response = await axios.post(
        `${SERVER_URI}/api/auth/resend`,
        payload
      );

      response.data && message.success(t("reSendVerifyMail.5"));
    } catch (error) {
      console.log(`verify failed: `, error);
      message.error(t("reSendVerifyMail.6"));
    }
  };

  const handleClick = () => {
    const payload = { restCode: params.restCode };

    reSend(payload);
  };

  const handleLogin = () => {
    history.push(`/login`);
  };

  return loading ? (
    <PageLoading />
  ) : (
    <Basement>
      {verified ? (
        <>
          <Text big color="black">
            {t("verifyAccount.2")}
          </Text>
          <Text> {t("verifyAccount.3")}</Text>
          <Button onClick={handleLogin}>{t("common.goLogin")}</Button>
        </>
      ) : (
        <>
          <Text big color="black">
            {t("reSendVerifyMail.1")}
          </Text>
          <Text>{t("reSendVerifyMail.2")}</Text>
          <Button onClick={handleClick}>{t("reSendVerifyMail.3")}</Button>
          <Text>
            <Link to="/resources/call_center">{t("reSendVerifyMail.4")}</Link>
          </Text>
        </>
      )}
    </Basement>
  );
}
