/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Text } from "../../assets/common";
import { useMediaQuery } from "@react-hook/media-query";
import Fade from "react-reveal/Fade";

export default function BlockThree() {
  const { t } = useTranslation();
  const match = useMediaQuery(`(max-width: 768px)`);
  const currentLanguage = window.localStorage.i18nextLng;
  let mindex1;
  let mmindex1;

  if (currentLanguage === "ko") {
    mindex1 = require("../../assets/images/mindex1.png");
    mmindex1 = require("../../assets/images/mmindex1.png");
  } else {
    mindex1 = require("../../assets/images/eng/mindex1.png");
    mmindex1 = require("../../assets/images/eng/mmindex1.png");
  }

  return (
    <Container>
      <F0>
        <F1>
          <Text
            super={match ? false : true}
            big={match ? true : false}
            color="black"
          >
            {t("blockThree.1")}
          </Text>

          <Text>{t("blockThree.2")}</Text>
        </F1>
        <F1>
          <img style={{ width: `100%` }} src={mindex1} />
        </F1>
      </F0>
      <F0>
        <F1>
          <Text
            super={match ? false : true}
            big={match ? true : false}
            color="black"
          >
            {t("blockThree.7")}
          </Text>

          <Text>{t("blockThree.9")}</Text>
        </F1>

        <F1>
          <img style={{ width: `100%` }} src={mmindex1} />
        </F1>
      </F0>
    </Container>
  );
}

const Container = styled.div``;

const F0 = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  padding: 5vw 0;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const F1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5vw;
  width: 50vw;

  @media (max-width: 768px) {
    width: 100vw;
  }
`;
