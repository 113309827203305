import React, { useEffect } from "react";
import styled from "styled-components";
import BtnClose from "../../../assets/images/btn_close.svg";
import Image from "./Image";

export default function Modal({ visible, setVisible, cleanUp, children }) {
  useEffect(() => {
    if (visible) {
      document.body.style.overflow = `hidden`;
    }

    return () => (document.body.style.overflow = `unset`);
  }, [visible]);

  const handleClick = () => {
    cleanUp && cleanUp();
    setVisible((prev) => !prev);
  };

  return (
    <Container style={{ display: `${visible ? `block` : `none`}` }}>
      <F1>{children}</F1>
      <ImageExtended
        src={BtnClose}
        width="20px"
        height="20px"
        onClick={handleClick}
      />
    </Container>
  );
}

const F1 = styled.div`
  margin: 50px 0;
  background-color: #fff;
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 100;
  overflow: auto;
`;

const ImageExtended = styled(Image)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 102;
  &:hover {
    cursor: pointer;
  }
`;
