import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { SERVER_URI, header } from "../assets/utils/HttpHandler";
import { useParams, useHistory, Link } from "react-router-dom";
import PageLoading from "../assets/utils/PageLoading";
import {
  Input,
  Button,
  Basement,
  Text,
  useInput,
  delay,
} from "../assets/common";
import { useTranslation } from "react-i18next";
import { ruleSchema } from "../assets/common/util/ruleSchema";
import { message } from "antd";

export default function VerifyWorkspaceAccount() {
  const { t } = useTranslation();
  const { restCode } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [verified, setVerified] = useState(false);
  const [pwValid, setPwValid, pw, setPw] = useInput(
    ``,
    ruleSchema.pw.rule,
    null
  );
  const [pwConfirmValid, setPwConfirmValid, pwConfirm, setPwConfirm] = useInput(
    ``,
    ruleSchema.pwConfirm.rule,
    pw
  );
  const [resetPwLoading, setResetPwLoading] = useState(false);

  useEffect(() => {
    axios
      .post(`${SERVER_URI}/api/workspace/user/verify-account`, { restCode })
      .then((response) => {
        console.log('response.data', response.data)
        if (response.data) {
          setVerified(true);
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
  }, []);

  // const handleSubmit = (e) => {
  //   let valid = true;

  //   if (!pwValid || !pw.length) {
  //     setPwValid(false);
  //     return false;
  //   }

  //   if (!pwConfirmValid) {
  //     setPwConfirmValid(false);
  //     return false;
  //   }

  //   if (valid) {
  //     const resetPw = async () => {
  //       setResetPwLoading(true);
  //       try {
  //         await delay();
  //         const response = await axios.put(
  //           `${SERVER_URI}/api/workspace/user/passwd`,
  //           {
  //             pw,
  //             restCode,
  //           }
  //         );

  //         if (response.data) {

  //           const responseTwo = await axios.get(
  //             `${process.env.REACT_APP_BACK_SERVER_URI}/api/user/userByRestCode/${restCode}`
  //           );

  //           console.log('responseTwo', responseTwo);

  //           if (responseTwo.data.region === "JP") {
  //             history.push("/resources/download?lng=jp");
  //             window.location.reload();
  //           } else {
  //             history.push("/resources/download");
  //           }

  //         }
  //       } catch (error) {
  //         message.error(t(`_message.failToChangePw`));
  //         setResetPwLoading(false);
  //       }
  //     };

  //     resetPw();
  //   }
  // };
  
  const handleSubmit = (e) => {
    let valid = true;

    if (!pwValid || !pw.length) {
      setPwValid(false);
      return false;
    }

    if (!pwConfirmValid) {
      setPwConfirmValid(false);
      return false;
    }

    if (valid) {
      const resetPw = async () => {
        setResetPwLoading(true);
        try {
          await delay();
          const response = await axios.put(
            `${SERVER_URI}/api/workspace/user/passwd`,
            {
              pw,
              restCode,
            }
          );

          if (response.data) {
            history.push("/resources/download");
          }
        } catch (error) {
          message.error(t(`_message.failToChangePw`));
          setResetPwLoading(false);
        }
      };

      resetPw();
    }
  };

  return loading ? (
    <PageLoading />
  ) : (
    <Basement>
      {verified ? (
        <>
          <Text big color="black">
            {t("_verifyWorkspaceAccountPage.title")}
          </Text>

          <Input
            type="password"
            label={t(ruleSchema.pw.label)}
            errorMsg={t(ruleSchema.pw.errorMsg)}
            current={pw}
            callback={(next) => setPw(next)}
            valid={pwValid}
          />
          <Input
            type="password"
            label={t(ruleSchema.pwConfirm.label)}
            errorMsg={t(ruleSchema.pwConfirm.errorMsg)}
            current={pwConfirm}
            callback={(next) => setPwConfirm(next)}
            valid={pwConfirmValid}
          />

          <Button onClick={handleSubmit} loading={resetPwLoading}>
            {t("_common.continue")}
          </Button>
        </>
      ) : (
        <>
          <Text big color="black">
            {t("_verifyWorkspaceAccountPage.invalid")}
          </Text>
          <Text>{t(`_info.paymentFailDetail`)}</Text>
          <Text>
            <Link to={`/workspace`}>{t(`_common.goBack`)}</Link>
          </Text>
        </>
      )}
    </Basement>
  );
}
