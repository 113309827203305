import ReactGA from "react-ga";
import React, { Suspense } from "react";
import { Switch, Route, Router } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";
import { BackTop, Spin } from "antd";
import TermsConditions from "./TermsConditions";
import PrivatePolicy from "./PrivatePolicy";
import Resources from "./Download";
import MajorCustomer from "./MajorCustomer";
import CustomerStories from "./CustomerStories";
import CreateWorkSpace from "./CreateWorkspace";
import CallCenter from "./CallCenter";
import Pricing from "./Pricing";
import Solution from "./Solution";
import Login from "./Login";
import Register from "./Register";
import Landing from "./Landing";
import WorkSpace from "./Workspace";
import WorkSpaceDetail from "./WorkspaceDetail";
import MemberManagement from "./MemberManagement";
import LicenseStorage from "./LicenseStorage";
import LicensePayment from "./LicensePayment";
import MemberPayment from "./MemberPayment";
import StoragePayment from "./StoragePayment";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import VerifyAccount from "./VerifyAccount";
import VerifyWorkspaceAccount from "./VerifyWorkspaceAccount";

// Hoc  s
import Auth from "./Auth";

import Footer from "./Footer";
import Header from "./Header";

import NotFound from "./NotFound";
import RegisterComplete from "./RegisterComplete";

function App() {
  return (
    <>
      <Suspense
        fallback={
          <LoadingWrap>
            <Spin tip="Loading..." />
          </LoadingWrap>
        }
      >
        <Header />
        <Switch>
          {/* Simple  s */}
          <Route exact path="/" component={Landing} />
          <Route exact path="/private_policy" component={PrivatePolicy} />
          <Route exact path="/terms_conditions" component={TermsConditions} />
          <Route exact path="/pricing" component={Pricing} />
          <Route exact path="/solution" component={Solution} />
          <Route exact path="/resources/download" component={Resources} />
          <Route
            exact
            path="/resources/major_customer"
            component={MajorCustomer}
          />
          <Route
            exact
            path="/resources/customer_stories"
            component={CustomerStories}
          />
          <Route exact path="/resources/call_center" component={CallCenter} />
          <Route exact path="/workspace" component={Auth(WorkSpace)} />
          <Route
            exact
            path="/workspace/detail/:workspaceRestCode"
            component={Auth(WorkSpaceDetail)}
          />
          <Route
            exact
            path="/workspace/create"
            component={Auth(CreateWorkSpace)}
          />
          <Route
            exact
            path="/workspace/organization/:restCode"
            component={Auth(MemberManagement)}
          />
          <Route
            exact
            path="/payment/license/:workspaceName/:workspaceRestCode/:licenseType"
            component={Auth(LicensePayment)}
          />
          <Route
            exact
            path="/payment/member/:workspaceName/:workspaceRestCode/:licenseType"
            component={Auth(MemberPayment)}
          />
          <Route
            exact
            path="/payment/storage/:workspaceName/:workspaceRestCode/:_ncp_or_ucm"
            component={Auth(StoragePayment)}
          />

          <Route
            exact
            path="/license_storage"
            component={Auth(LicenseStorage)}
          />

          <Route
            exact
            path="/verify-workspace-account/:restCode"
            component={VerifyWorkspaceAccount}
          />

          {/* User Authentication */}
          <Route exact path="/register" component={Register} />
          <Route exact path="/login" component={Login} />
          <Route
            exact
            path="/register_complete/:email"
            component={RegisterComplete}
          />
          <Route
            exact
            path="/verify-account/:restCode"
            component={VerifyAccount}
          />
          <Route exact path="/reset_user" component={ForgotPassword} />
          <Route exact path="/reset-pw/:restCode" component={ResetPassword} />
          <Route exact path="/notFound" component={NotFound} />
        </Switch>

        <Footer />
      </Suspense>
    </>
  );
}

export default App;

const EntireWrapper = styled.div`
  min-height: calc(100vh - 80px);
`;
const LoadingWrap = styled.div`
  text-align: center;
  line-height: 50rem;
`;
