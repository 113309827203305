export const messagePolicy = [
  [
    {
      label: "pricing2.2",
      free: "pricing2.unlimited",
    },
    // {
    //   label: "pricing2.16",
    //   free: `_info.perUser10GB`,
    // },
    {
      label: "pricing2.6",
      free: `4096KB`,
    },
    {
      label: "pricing2.10",
      free: `1GB`,
    },
    {
      label: `pricing3.2`,
      free: `O`,
    },
    {
      label: "pricing2.4",
      free: `O`,
    },
    {
      label: "pricing2.5",
      free: `O`,
    },
    {
      label: "pricing2.9",
      free: `O`,
    },
    {
      label: `pricing3.3`,
      free: `O`,
    },
    {
      label: `pricing2.22`,
      free: `O`,
    },
    {
      label: `pricing4.4`,
      free: `O`,
    },
    {
      label: `pricing4.3`,
      free: `O`,
    },
    {
      label: `pricing2.23`,
      free: `O`,
    },
    {
      label: `pricing4.8`,
      free: `O`,
    },
  ],
  [
    {
      label: "pricing2.2",
      standard: "pricing2.unlimited",
    },
    // {
    //   label: "pricing2.16",
    //   standard: `_info.perUser10GB`,
    // },
    {
      label: "pricing2.6",
      standard: `4096KB`,
    },
    {
      label: "pricing2.10",
      standard: `1GB`,
    },
    {
      label: `pricing3.2`,
      standard: `O`,
    },
    {
      label: "pricing2.4",
      standard: `O`,
    },
    {
      label: "pricing2.5",
      standard: `O`,
    },
    {
      label: "pricing2.9",
      standard: `O`,
    },
    {
      label: `pricing3.3`,
      standard: `O`,
    },
    {
      label: `pricing2.22`,
      standard: `O`,
    },
    {
      label: `pricing4.4`,
      standard: `O`,
    },
    {
      label: `pricing4.3`,
      standard: `O`,
    },
    {
      label: `pricing2.23`,
      standard: `O`,
    },
    {
      label: `pricing4.8`,
      standard: `O`,
    },
  ],
  [
    {
      label: "pricing2.2",
      premium: "pricing2.unlimited",
    },
    // {
    //   label: "pricing2.16",
    //   premium: `_info.perUser10GB`,
    // },
    {
      label: "pricing2.6",
      premium: `4096KB`,
    },
    {
      label: "pricing2.10",
      premium: `1GB`,
    },
    {
      label: `pricing3.2`,
      premium: `O`,
    },
    {
      label: "pricing2.4",
      premium: `O`,
    },
    {
      label: "pricing2.5",
      premium: `O`,
    },
    {
      label: "pricing2.9",
      premium: `O`,
    },
    {
      label: `pricing3.3`,
      premium: `O`,
    },
    {
      label: `pricing2.22`,
      premium: `O`,
    },
    {
      label: `pricing4.4`,
      premium: `O`,
    },
    {
      label: `pricing4.3`,
      premium: `O`,
    },
    {
      label: `pricing2.23`,
      premium: `O`,
    },
    {
      label: `pricing4.8`,
      premium: `O`,
    },
  ],
];
