/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Text, Title } from "../assets/common";
import { useMediaQuery } from "@react-hook/media-query";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { BackTop } from "antd";

export default function Solution() {
  const { t } = useTranslation();
  const match = useMediaQuery(`(max-width: 768px)`);
  const sol1 = require("../assets/images/sol1.png");
  const sol2 = require("../assets/images/sol2.png");
  const sol3 = require("../assets/images/sol3.png");
  const sol5 = require("../assets/images/sol5.png");
  const sol6 = require("../assets/images/sol6.png");
  const currentLanguage = window.localStorage.i18nextLng;

  let mindex1;
  let mmindex1;
  let sol4;
  let sol7;
  let sol8;

  if (currentLanguage === "ko") {
    mindex1 = require("../assets/images/mindex1.png");
    mmindex1 = require("../assets/images/mmindex1.png");
    sol4 = require("../assets/images/sol4.png");
    sol7 = require("../assets/images/sol7.png");
    sol8 = require("../assets/images/sol8.png");
  } else {
    mindex1 = require("../assets/images/eng/mindex1.png");
    mmindex1 = require("../assets/images/eng/mmindex1.png");
    sol4 = require("../assets/images/eng/sol4.png");
    sol7 = require("../assets/images/eng/sol7.png");
    sol8 = require("../assets/images/eng/sol8.png");
  }

  return (
    <>
      <Container>
        <Title title={t(`_title.solution`)} src="/images/solutionBg.jpg" />
        <F0>
          <Text
            super={match ? false : true}
            big={match ? true : false}
            color="black"
          >
            {t(`_info.ucworksIs`)}
          </Text>
          <Text>{t(`_info.ucworksIs2`)}</Text>
        </F0>

        <F1>
          <F2>
            <Text
              super={match ? false : true}
              big={match ? true : false}
              color="black"
            >
              {t(`_info.userFriendly`)}
            </Text>
            <Text>{t(`_info.userFriendly2`)}</Text>
          </F2>
          <F2>
            <img style={{ width: `100%` }} src={mindex1} />
            <img style={{ width: `100%` }} src={mmindex1} />
          </F2>
        </F1>
        <F1>
          <F2>
            <Text
              super={match ? false : true}
              big={match ? true : false}
              color="black"
            >
              {t(`_info.organization`)}
            </Text>
            <Text>{t(`_info.organization2`)}</Text>
          </F2>
          <F2>
            <img style={{ width: `100%` }} src={sol1} />
          </F2>
        </F1>
        <F1>
          <F2>
            <Text
              super={match ? false : true}
              big={match ? true : false}
              color="black"
            >
              {t(`_info.solution.message`)}
            </Text>
            <Text>{t(`_info.solution.message2`)}</Text>
          </F2>
          <F2>
            <img style={{ width: `100%` }} src={sol2} />
          </F2>
        </F1>
        <F1>
          <F2>
            <Text
              super={match ? false : true}
              big={match ? true : false}
              color="black"
            >
              {t(`_info.solution.chat`)}
            </Text>
            <Text>{t(`_info.solution.chat2`)}</Text>
          </F2>
          <F2>
            <img style={{ width: `100%` }} src={sol3} />
          </F2>
        </F1>
        <F1>
          <F2>
            <Text
              super={match ? false : true}
              big={match ? true : false}
              color="black"
            >
              {t(`_info.solution.remoteControl`)}
            </Text>
            <Text>{t(`_info.solution.remoteControl2`)}</Text>
          </F2>
          <F2>
            <img style={{ width: `100%` }} src={sol4} />
          </F2>
        </F1>
        <F1>
          <F2>
            <Text
              super={match ? false : true}
              big={match ? true : false}
              color="black"
            >
              {t(`_info.solution.voiceTalk`)}
            </Text>
            <Text>{t(`_info.solution.voiceTalk2`)}</Text>
          </F2>
          <F2>
            <img style={{ width: `100%` }} src={sol5} />
          </F2>
        </F1>
        <F1>
          <F2>
            <Text
              super={match ? false : true}
              big={match ? true : false}
              color="black"
            >
              {t(`_info.solution.band`)}
            </Text>
            <Text>{t(`_info.solution.band2`)}</Text>
          </F2>
          <F2>
            <img style={{ width: `100%` }} src={sol6} />
          </F2>
        </F1>
        <F1>
          <F2>
            <Text
              super={match ? false : true}
              big={match ? true : false}
              color="black"
            >
              {t(`_info.solution.ipt`)}
            </Text>
            <Text>{t(`_info.solution.ipt2`)}</Text>
          </F2>
          <F2>
            <img style={{ width: `100%` }} src={sol7} />
          </F2>
        </F1>
        <F1>
          <F2>
            <Text
              super={match ? false : true}
              big={match ? true : false}
              color="black"
            >
              {t(`_info.solution.legacy`)}
            </Text>
            <Text>{t(`_info.solution.legacy2`)}</Text>
          </F2>
          <F2>
            <img style={{ width: `100%` }} src={sol8} />
          </F2>
        </F1>
        <F0>
          <Text
            super={match ? false : true}
            big={match ? true : false}
            color="black"
          >
            {t(`_info.uniqueFeatures.title`)}
          </Text>

          <Text>{t(`_info.uniqueFeatures.title2`)}</Text>
        </F0>
        <F1_1>
          <Group>
            <F2_2>
              <img src="/images/index2.png" />
              <F3>
                <Text big color="black">
                  {t(`_info.uniqueFeatures.1thing1`)}
                </Text>
                <Text> {t(`_info.uniqueFeatures.1thing2`)}</Text>
              </F3>
            </F2_2>
            <F2_2>
              <img src="/images/index3.png" />
              <F3>
                <Text big color="black">
                  {t(`_info.uniqueFeatures.2thing1`)}
                </Text>
                <Text>{t(`_info.uniqueFeatures.2thing2`)}</Text>
              </F3>
            </F2_2>
            <F2_2>
              <img src="/images/index4.png" />
              <F3>
                <Text big color="black">
                  {t(`_info.uniqueFeatures.3thing1`)}
                </Text>
                <Text>{t(`_info.uniqueFeatures.3thing2`)}</Text>
              </F3>
            </F2_2>
          </Group>
          <Group>
            <F2_2>
              <img src="/images/index5.png" />
              <F3>
                <Text big color="black">
                  {t(`_info.uniqueFeatures.4thing1`)}
                </Text>
                <Text>{t(`_info.uniqueFeatures.4thing2`)}</Text>
              </F3>
            </F2_2>
            <F2_2>
              <img src="/images/index6.png" />
              <F3>
                <Text big color="black">
                  {t(`_info.uniqueFeatures.5thing1`)}
                </Text>
                <Text>{t(`_info.uniqueFeatures.5thing2`)}</Text>
              </F3>
            </F2_2>
            <F2_2>
              <img src="/images/index7.png" />
              <F3>
                <Text big color="black">
                  {t(`_info.uniqueFeatures.6thing1`)}
                </Text>
                <Text>{t(`_info.uniqueFeatures.6thing2`)}</Text>
              </F3>
            </F2_2>
          </Group>
        </F1_1>
      </Container>
      <BackTop />
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const F0 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 30vh;
  padding: 10vw;
`;

const F1 = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  padding: 5vw 0;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const F1_1 = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  padding: 0 0 5vw 0;
  background-color: #e3f3ff;
  flex-direction: column;
`;

const Group = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const F2_2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 33.3vw;
  padding: 5vw;
  @media (max-width: 768px) {
    width: 100vw;
  }

  & > img {
    padding: 5rem;
  }
`;

const F2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50vw;

  @media (max-width: 768px) {
    width: 100vw;
  }

  padding: 5vw;
`;

const F3 = styled.div`
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
