export const ruleSchema = {
  id: {
    label: "_form.id", // t("_form.id")
    rule: /^[A-Za-z0-9\{\}\[\]\/?\.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]{3,25}$/,
    errorMsg: "_validation.id", // t("_validation.id")
  },
  idLogin: {
    label: "_form.id", // t("_form.id")
    rule: /^.{1,30}/,
    errorMsg: "_validation.idLogin", // t("_validation.idLogin")
  },
  pw: {
    label: "_form.pw", // t("_form.pw")
    rule: /^[A-Za-z0-9\{\}\[\]\/?\.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]{4,50}$/,
    errorMsg: "_validation.pw", // t("_validation.pw")
  },
  pwLogin: {
    label: "_form.pw", // t("_form.pw")
    rule: /^.{1,50}/,
    errorMsg: "_validation.pwLogin", // t("_validation.pwLogin")
  },
  pwConfirm: {
    label: "_form.pwConfirm", // t("_form.pwConfirm")
    rule: undefined,
    errorMsg: "_validation.pwConfirm", // t("_validation.pwConfirm")
  },
  name: {
    label: "_form.name", // t("_form.name")
    rule: /^.{2,50}/,
    errorMsg: "_validation.name", // t("_validation.name")
  },
  email: {
    label: "_form.email", // t("_form.email")
    rule: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
    errorMsg: "_validation.email", // t("_validation.email")
  },
  phone: {
    label: "_form.phone", // t("_form.phone")
    rule: /^[\d-\s]{8,20}$/,
    errorMsg: "_validation.phone", // t("_validation.phone")
  },
  workspaceName: {
    label: "_create.workspaceName.title", // t("_create.workspaceName.title")
    rule: /^[가-힣A-Za-z0-9_\-\s]{5,20}$/,
    errorMsg: "_create.workspaceName.rule", // t("_create.workspaceName.rule")
  },
  workspaceCode: {
    label: "_create.workspaceCode.title", // t("_create.workspaceCode.title")
    rule: /^[A-Za-z0-9_\-]{5,30}$/,
    errorMsg: "_create.workspaceCode.rule", // t("_create.workspaceCode.rule")
  },
  workspaceDescription: {
    label: "_create.workspaceDescription.title", // t("_create.workspaceDescription.title")
    rule: /^.{1,2000}$/,
    errorMsg: "_create.workspaceDescription.rule", // t("_create.workspaceDescription.rule")
  },
  adminName: {
    label: "_payment.adminName", // t("_payment.adminName")
    rule: /^[가-힣A-Za-z0-9_\-\s]{1,50}$/,
    errorMsg: "_validation.name", // t("_validation.name")
  },
  adminEmail: {
    label: "_payment.adminEmail", // t("_payment.adminEmail")
    rule: /^[\S]{2,30}@{1}[A-Za-z0-9]{2,30}\.{1}[A-Za-z]{2,10}\s*$/,
    errorMsg: "_validation.email", // t("_validation.email")
  },
  groupName: {
    label: "_info.groupName", // t("_info.groupName")
    rule: /^[가-힣A-Za-z0-9_\-\s]{2,20}$/,
    errorMsg: "_create.workspaceName.rule", // t("_create.workspaceName.rule")
  },
  jobTitle: {
    label: "_info.jobTitle", // t(`_info.jobTitle`)
    rule: /^.{2,50}/,
    errorMsg: "_validation.jobTitle", // t(`_validation.jobTitle`)
  },
};
