import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Text, Button, Table } from "../../assets/common";
import Slider from "react-slick";
import styled from "styled-components";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import axios from "axios";
import { SERVER_URI, header } from "../../assets/utils/HttpHandler";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@react-hook/media-query";
import { messagePolicy } from "../../assets/common/util/messagePolicy";

const freeColumn = [{ label: ``, type: `text` }, { label: ``, type: `text` }];

const standardColumn = [
  { label: ``, type: `text` },
  { label: ``, type: `text` },
];

const premiumColumn = [
  { label: ``, type: `text` },

  { label: ``, type: `text` },
];

export default function PlanCards(props) {
  const { t } = useTranslation();
  const match = useMediaQuery(`(max-width: 768px)`);
  const [licenseList, setLicenseList] = useState([]);
  useEffect(() => {
    console.log(props.FreeButton);
    console.log(props);
    fetch();
  }, []);

  const fetch = async () => {
    const { data: list } = await axios.get(
      `${SERVER_URI}/api/license/list?type=L`,
      header()
    );
    setLicenseList(list);
  };

  const PrevArrow = ({ onClick, style }) => {
    return (
      <LeftOutlined
        onClick={onClick}
        style={{
          position: `fixed`,
          top: `45%`,
          left: `1rem`,
          zIndex: `1`,
          fontSize: `3rem`,
          color: `gray`,
        }}
      />
    );
  };
  const NextArrow = ({ onClick, style }) => {
    return (
      <RightOutlined
        onClick={onClick}
        style={{
          position: `fixed`,
          top: `45%`,
          right: `1rem`,
          zIndex: `1`,
          fontSize: `3rem`,
          color: `gray`,
        }}
      />
    );
  };

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    centerPadding: "60px",
    speed: 500,
    slidesToShow: 3,
    slidesToSlide: 3,
    draggable: false,
    swipe: false,

    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 1, slidesToScroll: 1, variableWidth: false },
      },
    ],
  };

  return (
    <>
      <Container>
        <Slider {...settings}>
          <div>
            <CardExtended>
              <Text big color="black" letterSpacing={0.2} fontWeight={500}>
                {`Free`}
              </Text>
              <F1>
                <F2>
                  <Text big color="#0070c9">
                    ₩0
                  </Text>
                  {props.FreeButton}
                </F2>
              </F1>
              <F1>
                <Text>{t("_selectPlan.free.description")}</Text>

                {/* <Text>{t("_selectPlan.free.footer")}</Text> */}
              </F1>

              <Table
                column={freeColumn}
                payload={messagePolicy[0]}
                small
                transparent
              />
            </CardExtended>
          </div>
          <div>
            <CardExtended>
              <Text big color="black" letterSpacing={0.2} fontWeight={500}>
                {`Standard`}
              </Text>
              <F1>
                <F2>
                  <Text big color="#0070c9">
                    {`₩${Number(
                      licenseList[1]?.priceList[2].price * 1000
                    ).toLocaleString()}`}
                    {props.StandardButton}
                  </Text>
                </F2>
              </F1>
              <F1>
                <Text>{t("_selectPlan.per")}</Text>
                <Text>{t("_selectPlan.apply")}</Text>
                <Text>
                  {t("_selectPlan.monthly", {
                    price: Number(
                      licenseList[1]?.priceList[0].price * 1000
                    ).toLocaleString(),
                  })}
                </Text>
                {/* <Text>{t("_selectPlan.standard.footer")}</Text> */}
              </F1>
              <Table
                column={standardColumn}
                payload={messagePolicy[1]}
                small
                transparent
              />
            </CardExtended>
          </div>

          <div>
            <CardExtended>
              <Text big color="black" letterSpacing={0.2} fontWeight={500}>
                {`Premium`}
              </Text>
              <F1>
                <F2>
                  <Text big color="#0070c9">
                    {`₩${Number(
                      licenseList[2]?.priceList[2].price * 1000
                    ).toLocaleString()}`}
                  </Text>
                  {props.PremiumButton}
                </F2>
              </F1>
              <F1>
                <Text>{t("_selectPlan.per")}</Text>
                <Text>{t("_selectPlan.apply")}</Text>
                <Text>
                  {t("_selectPlan.monthly", {
                    price: Number(
                      licenseList[2]?.priceList[0].price * 1000
                    ).toLocaleString(),
                  })}
                </Text>
                {/* <Text>{t("_selectPlan.premium.footer")}</Text> */}
              </F1>
              <Table
                column={premiumColumn}
                payload={messagePolicy[2]}
                small
                transparent
              />
            </CardExtended>
          </div>



          {/* <div>
            <CardExtended>
              <Text big color="black" letterSpacing={0.2} fontWeight={500}>
                {`Enterprise`}
              </Text>
              <F1>
                <F2>
                  <Text big color="#0070c9">
                    {`₩`}
                  </Text>
                  {props.EnterpriseButton}
                </F2>
              </F1>
              <F1>
                <Text>{t("_selectPlan.per")}</Text>
                <Text>{t("_selectPlan.apply")}</Text>
                <Text>
                  {t("_selectPlan.monthly", {
                    price: Number(
                      licenseList[3]?.priceList[0].price * 1000
                    ).toLocaleString(),
                  })}
                </Text>
              </F1>
              <Table
                column={premiumColumn}
                payload={messagePolicy[2]}
                small
                transparent
              />
            </CardExtended>
          </div> */}
        </Slider>
      </Container>
    </>
  );
}

const Container = styled.div`
  margin: 5rem 0;

  & .slick-center {
    transform: scale(1.03);
    @media (max-width: 768px) {
      transform: scale(1.01);
    }
  }

  & .slick-slide {
    transition: transform 0.5s;
  }
`;

const CardExtended = styled.div`
  padding: 1rem;
  /* test */
  border: 0.1rem solid #d2d2d7;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 35rem; */
  position: relative;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  width: 90%;
  margin: 3rem auto 3rem auto;
  background-color: #f5f5f7;
  background: linear-gradient(white, #f5f5f7);
`;

const F1 = styled.div`
  height: 10vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const F2 = styled.div`
  display: flex;
`;
