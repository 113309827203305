import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip, message } from "antd";
import {
  Button,
  Input,
  Icon,
  Basement,
  Text,
  Image,
  delay,
  useInput,
  HistoryNavigation,
} from "../assets/common";
import IconInfo from "../assets/images/icon_info.svg";
import styled from "styled-components";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
import { SERVER_URI, header } from "../assets/utils/HttpHandler";
import { ruleSchema } from "../assets/common/util/ruleSchema";

export default function ForgotPassword(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const [idValid, setIdValid, id, setId] = useInput(
    ``,
    ruleSchema.id.rule,
    null
  );
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    let valid = true;
    if (!idValid || !id.length) {
      setIdValid(false);
      valid = false;
    }

    if (valid) {
      const payload = { id };
      sendResetPasswordEmail(payload);
    }
  };

  const sendResetPasswordEmail = async (payload) => {
    try {
      setLoading(true);

      await delay();
      const response = await axios.post(
        `${SERVER_URI}/api/auth/forgot-pw`,
        payload,
        header()
      );

      if (response?.status !== 200) {
        if (response.status === 204) {
          console.log(`sendResetPasswordEmail failed: `, response);
          message.error(t(`_message.noUser`));
        } else {
          console.log(`sendResetPasswordEmail failed: `, response);
          message.error(t(`_message.failToSendEmail`));
        }
        return false;
      }
      message.success(t(`_message.successToSendEmail`));
    } catch (error) {
      console.log(`sendResetPasswordEmail failed: `, error);
      message.error(t(`_message.failToSendEmail`));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <HistoryNavigation history={history} />
      <Basement>
        <F1>
          <Text big color="black">
            {t("_title.resetPw")}
          </Text>

          <Tooltip title={t("_info.forgotPw")} placement="rightTop">
            <Image src={IconInfo} width="2rem" height="2rem" />
          </Tooltip>
        </F1>
        <Input
          type="text"
          label={t(ruleSchema.idLogin.label)}
          errorMsg={t(ruleSchema.idLogin.errorMsg)}
          current={id}
          callback={(next) => setId(next)}
          valid={idValid}
          autoComplete="off"
          autoFocus
        />
        <Button big onClick={handleSubmit} loading={loading}>
          {t("_common.continue")}
        </Button>
        <Text>
          <Link>{t("_info.forgotId")}</Link>
        </Text>
      </Basement>
    </>
  );
}

const F1 = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
`;
