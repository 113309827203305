import { createActions, handleActions, createAction } from "redux-actions";

const initialState = {
  expand: false,
  expandHeight: 0,
};

export const configActions = createActions({
  CONFIG: {
    EXPAND: {
      ON: (height) => ({ height }),
      OFF: undefined,
    },
  },
});

export default handleActions(
  {
    [`CONFIG/EXPAND/ON`]: (state, { payload }) => ({
      ...state,
      expandHeight: payload.height,
      expand: true,
    }),
    [`CONFIG/EXPAND/OFF`]: (state, { payload }) => ({
      ...state,
      expand: false,
    }),
  },
  initialState
);
