import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Modal from "react-awesome-modal";
import axios from "axios";
import { SERVER_URI, header } from "../assets/utils/HttpHandler";
import * as HttpHandler from "../assets/utils/HttpHandler";
import { useTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";

const storageLists = [
  { uid: 1, storageLabel: "1GB", perchaseDate: "2019/07/01" },
  { uid: 2, storageLabel: "3GB", perchaseDate: "2019/07/10" },
];

function LicenseAndStrorage(props) {
  const { t } = useTranslation();

  const [clickedLicense, setClickedLicense] = useState("");
  const [clickedStorage, setClickedStorage] = useState("");
  const [selectedTeamNameForLicense, setSelectedTeamNameForLicense] = useState(
    ""
  );
  const [selectedTeamNameForStorage, setSelectedTeamNameForStorage] = useState(
    ""
  );

  const [unassignedLicenseOpen, setUnassignedLicenseOpen] = useState(false);
  const [unassignedStorageOpen, setUnassignedStorageOpen] = useState(false);

  const [assignLicenseConfirm, setAssignLicenseConfirm] = useState(false);
  const [assignStorageConfirm, setAssignStorageConfirm] = useState(false);

  const [licenseLists, setLicenseLists] = useState([]);
  const [loadingForLicense, setLoadingForLicense] = useState(true);

  const [unassignedTeamList, setUnassignedTeamList] = useState([]);

  useEffect(() => {
    HttpHandler.httpRequestExternalLink(
      `${SERVER_URI}/api/user/license/list`,
      "get"
    ).then((response) => {
      console.log(JSON.parse(response));
      setLicenseLists(JSON.parse(response));
      setLoadingForLicense(false);
    });
  }, []);

  const handleModal = (name, value, licenseIdOrStorageId = "") => {
    if (name === "unassignedLicenseOpen") {
      setClickedLicense(licenseIdOrStorageId);
      setUnassignedLicenseOpen(value);
    } else if (name === "unassignedStorageOpen") {
      setClickedStorage(licenseIdOrStorageId);
      setUnassignedStorageOpen(value);
    } else if (name === "assignLicenseConfirm") {
      setAssignLicenseConfirm(value);
    } else {
      setAssignStorageConfirm(value);
    }
  };

  const handleAssignLicense = (teamName) => {
    setSelectedTeamNameForLicense(teamName);
    setAssignLicenseConfirm(true);
    setUnassignedLicenseOpen(false);
  };

  const handleAssignStorage = (teamName) => {
    setSelectedTeamNameForStorage(teamName);
    setAssignStorageConfirm(true);
    setUnassignedStorageOpen(false);
  };

  const handleAssignLicenseConfirm = (value) => {
    if (value === true) {
      alert(
        `${selectedTeamNameForLicense}에게 uid ${clickedLicense}인 라이센스를 할당 합니다`
      );
      setAssignLicenseConfirm(false);
      setClickedLicense(false);
      setSelectedTeamNameForLicense(false);
    } else {
      setAssignLicenseConfirm(false);
      setClickedLicense(false);
      setSelectedTeamNameForLicense(false);
    }
  };

  const handleAssignStorageConfirm = (value) => {
    if (value === true) {
      alert(
        `${selectedTeamNameForStorage}에게 uid ${clickedStorage}인 스토리지를 할당 합니다`
      );
      setAssignStorageConfirm(false);
      setClickedStorage(false);
      setSelectedTeamNameForStorage(false);
    } else {
      setAssignStorageConfirm(false);
      setClickedStorage(false);
      setSelectedTeamNameForStorage(false);
    }
  };

  const licenseUnassignedWorkspaceRendered = () =>
    unassignedTeamList &&
    unassignedTeamList.map((list, i) => (
      <React.Fragment key={i}>
        <ListDiv key={i}>
          <div style={{ width: "20%" }}>{list.name}</div>
          <div style={{ paddingLeft: "1rem", width: "50%" }}>
            {list.restCode}
          </div>
          <div
            style={{
              width: "20%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <button
              className="waves-effect waves-light blue lighten-1 btn"
              onClick={() => handleAssignLicense(list.teamLabel)}
            >
              {" "}
              {t("licenseStorage.assign")}
            </button>
          </div>
        </ListDiv>
        <br />
      </React.Fragment>
    ));

  const storageUnassignedWorkspaceRendered = () =>
    unassignedTeamList &&
    unassignedTeamList.map((list, i) => (
      <React.Fragment key={i}>
        <ListDiv key={i}>
          <div style={{ width: "20%" }}>{list.name}</div>
          <div style={{ paddingLeft: "1rem", width: "50%" }}>
            {list.restCode}
          </div>
          <div
            style={{
              width: "20%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <button
              className="waves-effect waves-light blue lighten-1 btn"
              onClick={() => handleAssignStorage(list.teamLabel)}
            >
              {t("licenseStorage.assign")}
            </button>
          </div>
        </ListDiv>
        <br />
      </React.Fragment>
    ));

  const licenseListsRendered = () =>
    licenseLists &&
    licenseLists.map((list, i) => (
      <ListDiv key={i} style={{ width: "100%" }} id={list.uid}>
        <div style={{ width: "30%" }}>{list.license.name}</div>

        <div style={{ width: "30%" }}>{list.restCode}</div>

        {list.workspaceIdx === 0 ? (
          <div
            style={{
              justifyContent: "flex-end",
              display: "flex",
              width: "30%",
            }}
          >
            {t("licenseStorage.assigned")}&nbsp;&nbsp;&nbsp;
          </div>
        ) : (
          <div
            style={{
              justifyContent: "flex-end",
              display: "flex",
              cursor: "pointer",
              width: "30%",
            }}
          >
            <button
              className="waves-effect waves-light blue lighten-2 btn"
              onClick={() =>
                handleModal("unassignedLicenseOpen", true, list.restCode)
              }
            >
              {t("licenseStorage.assign")}
            </button>
          </div>
        )}
      </ListDiv>
    ));

  const storageListsRendered = () =>
    storageLists &&
    storageLists.map((list, i) => (
      <ListDiv key={i} style={{ width: "100%" }} id={list.uid}>
        <div style={{ width: "30%" }}>{list.storageLabel}</div>

        <div style={{ width: "30%" }}>{list.perchaseDate}</div>

        {list.assigned ? (
          <div
            style={{
              justifyContent: "flex-end",
              display: "flex",
              width: "30%",
            }}
          >
            {t("licenseStorage.assigned")}&nbsp;&nbsp;&nbsp;
          </div>
        ) : (
          <div
            style={{
              justifyContent: "flex-end",
              display: "flex",
              cursor: "pointer",
              width: "30%",
            }}
          >
            <button
              className="waves-effect waves-light blue lighten-2 btn"
              onClick={() => handleModal("unassignedStorageOpen", true)}
            >
              {t("licenseStorage.assign")}
            </button>
          </div>
        )}
      </ListDiv>
    ));

  return (
    <React.Fragment>
      <div className="container">
        <BackgroundTab className="row hide-on-large-only">
          <div className="col s4 ">
            <Link to="/workspace">
              <button className="waves-effect waves-light blue lighten-2 btn">
                {" "}
                {t("licenseStorage.workSpace")}
              </button>
            </Link>
          </div>
          <div className="col s4 ">
            <Link to="/license_storage">
              <button className="waves-effect waves-light blue lighten-1 btn">
                {t("licenseStorage.licenseStorage")}
              </button>
            </Link>
          </div>
          <div className="col s4 ">
            <Link to="/administer_regular_payment">
              <button className="waves-effect waves-light blue lighten-2 btn">
                {t("licenseStorage.periodicPaymentManageMent")}
              </button>
            </Link>
          </div>
        </BackgroundTab>

        <BackgroundTab
          style={{ display: "flex", justifyContent: "center" }}
          className="row hide-on-med-and-down"
        >
          <div style={{ margin: "0 0.5rem" }}>
            <Link to="/workspace">
              <button className="waves-effect waves-light blue lighten-2 btn">
                {t("licenseStorage.workSpace")}
              </button>
            </Link>
          </div>
          <div style={{ margin: "0 0.5rem" }}>
            <Link to="/license_storage">
              <button className="waves-effect waves-light blue lighten-1 btn">
                {t("licenseStorage.licenseStorage")}
              </button>
            </Link>
          </div>
          <div style={{ margin: "0 0.5rem" }}>
            <Link to="/administer_regular_payment">
              <button className="waves-effect waves-light blue lighten-2 btn">
                {t("licenseStorage.periodicPaymentManageMent")}
              </button>
            </Link>
          </div>
        </BackgroundTab>
        <br />

        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{ width: "50%", fontSize: "1.2rem", marginBottom: "2px" }}
          >
            {t("licenseStorage.licenses")}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "50%",
            }}
          >
            <Link to="/payment/license">
              {t("licenseStorage.moreLicenses")}
            </Link>
          </div>
        </div>

        <div>
          {loadingForLicense ? (
            <div
              style={{
                width: "100%",
                height: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress color="primary" />
            </div>
          ) : (
            licenseListsRendered()
          )}
        </div>

        <br />
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{ width: "50%", fontSize: "1.2rem", marginBottom: "2px" }}
          >
            {t("licenseStorage.storages")}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "50%",
            }}
          >
            <Link to="/payment/storage">
              {t("licenseStorage.moreStorages")}
            </Link>
          </div>
        </div>
        <div>{storageListsRendered()}</div>

        <br />
        <br />

        <Modal
          visible={unassignedLicenseOpen}
          width="400"
          effect="fadeInUp"
          onClickAway={() => handleModal("unassignedLicenseOpen", false)}
        >
          <div
            style={{
              padding: "1rem 2rem",
              overflowY: "scroll",
              maxHeight: "380px",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <h6>{t("licenseStorage.workspaces")}</h6>
            </div>
            <br />
            {licenseUnassignedWorkspaceRendered()}
            <br />
            <span
              style={{
                display: "flex",
                justifyContent: "flex-end",
                cursor: "pointer",
              }}
              onClick={() => handleModal("unassignedLicenseOpen", false)}
            >
              Close
            </span>
          </div>
        </Modal>

        <Modal
          visible={unassignedStorageOpen}
          width="400"
          effect="fadeInUp"
          onClickAway={() => handleModal("unassignedStorageOpen", false)}
        >
          <div
            style={{
              padding: "1rem 2rem",
              overflowY: "scroll",
              maxHeight: "380px",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <h6>{t("licenseStorage.workspaces")}</h6>
            </div>
            <br />
            {storageUnassignedWorkspaceRendered()}
            <br />
            <span
              style={{
                display: "flex",
                justifyContent: "flex-end",
                cursor: "pointer",
              }}
              onClick={() => handleModal("unassignedStorageOpen", false)}
            >
              Close
            </span>
          </div>
        </Modal>

        <Modal
          visible={assignLicenseConfirm}
          width="400"
          effect="fadeInUp"
          onClickAway={() => handleModal("assignLicenseConfirm", false)}
        >
          <div style={{ padding: "1rem", textAlign: "center" }}>
            <div style={{ fontSize: "1.2rem", color: "blue" }}>
              {t("licenseStorage.confirmAssignLicense")}
            </div>
            <br />
            <div style={{ fontSize: "1.2rem" }}>
              <button
                style={{ border: "none", background: "transparent" }}
                onClick={() => handleAssignLicenseConfirm(true)}
              >
                {t("licenseStorage.yes")}
              </button>
              <Divider padding />
              <button
                style={{ border: "none", background: "transparent" }}
                onClick={() => handleAssignLicenseConfirm(false)}
              >
                {t("licenseStorage.no")}
              </button>
            </div>
          </div>
        </Modal>

        <Modal
          visible={assignStorageConfirm}
          width="400"
          effect="fadeInUp"
          onClickAway={() => handleModal("assignStorageConfirm", false)}
        >
          <div style={{ padding: "1rem", textAlign: "center" }}>
            <div style={{ fontSize: "1.2rem", color: "blue" }}>
              {t("licenseStorage.confirmAssignStorage")}
            </div>
            <br />
            <div style={{ fontSize: "1.2rem" }}>
              <button
                style={{ border: "none", background: "transparent" }}
                onClick={() => handleAssignStorageConfirm(true)}
              >
                {t("licenseStorage.yes")}
              </button>
              <Divider padding />
              <button
                style={{ border: "none", background: "transparent" }}
                onClick={() => handleAssignStorageConfirm(false)}
              >
                {t("licenseStorage.no")}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
}

export default LicenseAndStrorage;

const ListDiv = styled.div`
  border: 1px solid lightgray;
  padding: 1rem;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 0.7rem;
`;

const BackgroundTab = styled.div`
  text-align: center;
  font-weight: bold;

  div {
    padding: 0.3rem 0 !important;
  }

  button {
    border-radius: 15px;
  }

  @media (max-width: 500px) {
    font-size: 8px;
  }

  a {
    color: black;
  }

  a:hover {
    color: gray;
  }
`;

const Divider = styled.div`
  border-bottom: solid 1px #e5e5ea;
  margin: ${(props) => (props.padding ? "0.7rem 0" : "0")};
  width: 100%;
`;
