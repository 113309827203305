import React, { useEffect, useState } from "react";
import PaymentInformation from "../components/StoragePayment/PaymentInformation";
import axios from "axios";
import { SERVER_URI, header } from "../assets/utils/HttpHandler";
import PageLoading from "../assets/utils/PageLoading";
import { useParams, useHistory } from "react-router-dom";
import Result from "../components/common/Result";
import { HistoryNavigation } from "../assets/common";

export default function StoragePayment(props) {
  const params = useParams();
  const { workspaceName, workspaceRestCode, _ncp_or_ucm } = params;
  const history = useHistory();
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);

  const [paymentInfo, setPaymentInfo] = useState({
    adminName: "",
    adminEmail: "",
    wishStorage: 1,
    licenseList: [],
    totalPrice: 0,
    wishPlan: "",
    currentStoragePrice: 0,
    wishPriceRestCode: "",
    workspaceName,
    workspaceRestCode,
  });

  const paymentPayload = [
    { label: `paymentInfo.teamName`, value: paymentInfo.workspaceName },
    { label: `paymentInfo.storageCount`, value: paymentInfo.wishStorage },
    {
      label: `paymentInfo.price`,
      value: `₩${Number(paymentInfo.totalPrice).toLocaleString()}`,
    },
    { label: `paymentInfo.adminName`, value: paymentInfo.adminName },
    { label: `paymentInfo.adminEmail`, value: paymentInfo.adminEmail },
  ];

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data: licenseList } = await axios.get(
          `${SERVER_URI}/api/license/list?type=S`,
          header()
        );

        // 현재 사용중인 라이선스에 적용된 가격과 현재 일자부터 라이선스 종료일까지 블럭당 가격을 가져온다.
        const { data: LeftStoragePrice } = await axios.get(
          `${SERVER_URI}/api/user/license/current-storage-price?workspaceRestCode=${workspaceRestCode}`,
          header()
        );

        const { data: user } = await axios.get(
          `${SERVER_URI}/api/user`,
          header()
        );

        setPaymentInfo((prev) => ({
          ...prev,
          currentStoragePrice: LeftStoragePrice
            ? LeftStoragePrice.pricePerBlock.toFixed(1)
            : 0,
          wishPriceRestCode: licenseList[0].priceList[0].restCode,
          adminName: user.id,
          adminEmail: user.email,
        }));

        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetch();
  }, []);

  return (
    <>
      <HistoryNavigation history={history} params={params} />
      {step === 0 &&
        (loading ? (
          <PageLoading />
        ) : (
          <PaymentInformation
            setStep={setStep}
            paymentInfo={paymentInfo}
            setPaymentInfo={setPaymentInfo}
            setSuccess={setSuccess}
          />
        ))}
      {step === 1 && <Result success={success} paymentInfo={paymentPayload} />}
    </>
  );
}
