import styled from "styled-components";
import React from "react";

export default function Basement({ children, color, width }) {
  return (
    <Container color={color}>
      <F1 width={width}>{children}</F1>
    </Container>
  );
}
const Container = styled.div`
  background-color: ${(props) => props.color && props.color};
  display: flex;
  justify-content: center;
`;

const F1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 20px;

  width: ${(props) => (props.width ? props.width : `970px`)};

  @media (max-width: 768px) {
    width: 90vw;
  }
`;
