import React from "react";
import { useTranslation } from "react-i18next";
import { Text, Title } from "../assets/common";
import { useMediaQuery } from "@react-hook/media-query";
import styled from "styled-components";
import Fade from "react-reveal/Fade";
import { BackTop } from "antd";

export default function MajorCustomer() {
  const match = useMediaQuery(`(max-width: 768px)`);
  const Learn1 = require("../assets/images/learn1.png");
  const Learn2 = require("../assets/images/learn2.png");
  const Learn3 = require("../assets/images/learn3.png");
  const Learn4 = require("../assets/images/learn4.png");

  const { t } = useTranslation();

  return (
    <>
      <Container>
        <Title title={t(`_title.majorCustomer`)} src="/images/aboutBg.jpg" />
        <F1>
          <Text
            super={match ? false : true}
            big={match ? true : false}
            color="black"
          >
            {t("majorCustomer1.2")}
          </Text>
        </F1>

        <F2>
          <F3>
            <Text
              super={match ? false : true}
              big={match ? true : false}
              color="black"
            >
              {t("majorCustomer1.3")}
            </Text>
            <Text>
              {t("majorCustomer1.4")}
              {t("majorCustomer1.5")}
              {t("majorCustomer1.6")}
              {t("majorCustomer1.7")}
              {t("majorCustomer1.8")}
              {t("majorCustomer1.9")}
              {t("majorCustomer1.10")}
              {t("majorCustomer1.11")}
              {t("majorCustomer1.12")}{" "}
            </Text>
          </F3>
          <F3>
            <img src={Learn1} style={{ width: "100%" }} alt="learn1" />
          </F3>
        </F2>
        <F2>
          <F3>
            <Text
              super={match ? false : true}
              big={match ? true : false}
              color="black"
            >
              {t("majorCustomer2.1")}
            </Text>
            <Text>
              {t("majorCustomer2.3")}
              {t("majorCustomer2.4")}
              {t("majorCustomer2.5")}
              {t("majorCustomer2.6")}
              {t("majorCustomer2.7")}
              {t("majorCustomer2.8")}
              {t("majorCustomer2.9")}
              {t("majorCustomer2.10")}
              {t("majorCustomer2.11")}
              {t("majorCustomer2.12")}
              {t("majorCustomer2.13")}
              {t("majorCustomer2.14")}
              {t("majorCustomer2.15")}
              {t("majorCustomer2.16")}
              {t("majorCustomer2.17")}
              {t("majorCustomer2.18")}
              {t("majorCustomer2.19")}
              {t("majorCustomer2.20")}
            </Text>
          </F3>

          <F3>
            <img src={Learn2} style={{ width: "100%" }} alt="learn2" />
          </F3>
        </F2>
        <F2>
          <F3>
            <Text
              super={match ? false : true}
              big={match ? true : false}
              color="black"
            >
              {t("majorCustomer3.1")}
            </Text>
            <Text>
              {t("majorCustomer3.3")}
              {t("majorCustomer3.4")}
              {t("majorCustomer3.5")}
              {t("majorCustomer3.6")}
              {t("majorCustomer3.7")}
              {t("majorCustomer3.8")}
              {t("majorCustomer3.9")}
              {t("majorCustomer3.10")}
              {t("majorCustomer3.11")}
              {t("majorCustomer3.12")}
              {t("majorCustomer3.13")}
              {t("majorCustomer3.14")}
              {t("majorCustomer3.15")}
              {t("majorCustomer3.16")}
              {t("majorCustomer3.17")}
              {t("majorCustomer3.18")}
              {t("majorCustomer3.19")}
              {t("majorCustomer3.20")}
              {t("majorCustomer3.21")}
              {t("majorCustomer3.22")}
              {t("majorCustomer3.23")}
              {t("majorCustomer3.24")}
              {t("majorCustomer3.25")}
              {t("majorCustomer3.26")}
            </Text>
          </F3>

          <F3>
            <img src={Learn3} style={{ width: "100%" }} alt="learn3" />
          </F3>
        </F2>
        <F2>
          <F3>
            <Text
              super={match ? false : true}
              big={match ? true : false}
              color="black"
            >
              {t("majorCustomer4.1")}

              {t("majorCustomer4.2")}
            </Text>
            <Text>
              {t("majorCustomer4.3")}

              {t("majorCustomer4.4")}

              {t("majorCustomer4.5")}

              {t("majorCustomer4.6")}

              {t("majorCustomer4.7")}

              {t("majorCustomer4.8")}

              {t("majorCustomer4.9")}

              {t("majorCustomer4.10")}

              {t("majorCustomer4.11")}

              {t("majorCustomer4.12")}
            </Text>
          </F3>
          <F3>
            <img src={Learn4} style={{ width: "100%" }} alt="learn4" />
          </F3>
        </F2>
      </Container>
      <BackTop />
    </>
  );
}

const Container = styled.div``;

const F1 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 5vw 0;
`;

const F2 = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  padding: 5vw 0;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const F3 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5vw;
  width: 50vw;

  @media (max-width: 768px) {
    width: 100vw;
  }
`;
