import React, { useMemo } from "react";
import styled from "styled-components";
import { FaWindows } from "react-icons/fa";
import { FaAndroid } from "react-icons/fa";
import { FaApple } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { Text, Title } from "../assets/common";
import { useMediaQuery } from "@react-hook/media-query";

export default function Download() {
  const { t, i18n } = useTranslation();
  const match = useMediaQuery(`(max-width: 768px)`);

  const windowsPath = useMemo(() => {
    switch (i18n.language) {
      case `ko`:
        return process.env.REACT_APP_S3_WINDOWS_KR_PATH;

      case `jp`:
        return process.env.REACT_APP_S3_WINDOWS_JP_PATH;
      default:
        return process.env.REACT_APP_S3_WINDOWS_KR_PATH;
    }
  }, [i18n.language]);

  const macPath = useMemo(() => {
    switch (i18n.language) {
      case `ko`:
        return process.env.REACT_APP_S3_MAC_KR_PATH;

      case `jp`:
        return process.env.REACT_APP_S3_MAC_JP_PATH;
      default:
        return process.env.REACT_APP_S3_MAC_KR_PATH;
    }
  }, [i18n.language]);


  const androidPath = useMemo(() => {
    switch (i18n.language) {
      case `ko`:
        return process.env.REACT_APP_S3_ANDROID_KR_PATH;

      case `jp`:
        return process.env.REACT_APP_S3_ANDROID_JP_PATH;
      default:
        return process.env.REACT_APP_S3_ANDROID_KR_PATH;
    }
  }, [i18n.language])

  const iosPath = useMemo(() => {
    switch (i18n.language) {
      case `ko`:
        return process.env.REACT_APP_S3_IOS_KR_PATH;

      case `jp`:
        return process.env.REACT_APP_S3_IOS_JP_PATH;
      default:
        return process.env.REACT_APP_S3_IOS_KR_PATH;
    }

  }, [i18n.language])

  const windowsManual = useMemo(() => {
    switch (i18n.language) {
      case `ko`:
        return process.env.REACT_APP_S3_WINDOWS_MANUAL_KR_PATH;
      case `jp`:
        return process.env.REACT_APP_S3_WINDOWS_MANUAL_JP_PATH;
      default:
        return process.env.REACT_APP_S3_WINDOWS_MANUAL_KR_PATH;
    }
  }, [i18n.language]);

  const macManual = useMemo(() => {
    switch (i18n.language) {
      case `ko`:
        return process.env.REACT_APP_S3_MAC_MANUAL_KR_PATH;
      case `jp`:
        return process.env.REACT_APP_S3_MAC_MANUAL_JP_PATH;
      default:
        return process.env.REACT_APP_S3_MAC_MANUAL_KR_PATH;
    }
  }, [i18n.language]);

  const androidManual = useMemo(() => {
    switch (i18n.language) {
      case `ko`:
        return process.env.REACT_APP_S3_ANDROID_MANUAL_KR_PATH;
      case `jp`:
        return process.env.REACT_APP_S3_ANDROID_MANUAL_JP_PATH;
      default:
        return process.env.REACT_APP_S3_ANDROID_MANUAL_KR_PATH;
    }
  }, [i18n.language]);

  const iosManual = useMemo(() => {
    switch (i18n.language) {
      case `ko`:
        return process.env.REACT_APP_S3_IOS_MANUAL_KR_PATH;
      case `jp`:
        return process.env.REACT_APP_S3_IOS_MANUAL_JP_PATH;
      default:
        return process.env.REACT_APP_S3_IOS_MANUAL_KR_PATH;
    }
  }, [i18n.language]);

  return (
    <>
      <Title title={t(`_title.download`)} src="/images/downloadBg.jpg" />


      <Container>

        <F1>
          <F2>
            <Text super color="black">
              Windows
              {/* / Mac */}
            </Text>
            <img
              src="/images/download_mac.png"
              style={{ width: `260px`, height: `220px` }}
            />
            <F3>
              <Button href={windowsPath} download>
                <FaWindows
                  style={{
                    fontSize: "30px",
                  }}
                />
                <F4>
                  <TextExtended small>{`Download`}</TextExtended>
                  <TextExtended color="black">{`Windows`}</TextExtended>
                </F4>
              </Button>
              <Button href={macPath} download>
                <FaApple
                  style={{
                    fontSize: "30px",
                  }}
                />
                <F4>
                  <TextExtended small>{`Download`}</TextExtended>
                  <TextExtended color="black">{`Mac`}</TextExtended>
                </F4>
              </Button>
            </F3>
            <F3>
              <Button href={windowsManual} download>
                <FaWindows
                  style={{
                    fontSize: "30px",
                  }}
                />
                <F4>
                  <TextExtended small>{`Manual`}</TextExtended>
                  <TextExtended color="black">{`Windows`}</TextExtended>
                </F4>
              </Button>
              <Button href={macManual} download>
                <FaApple
                  style={{
                    fontSize: "30px",
                  }}
                />
                <F4>
                  <TextExtended small>{`Manual`}</TextExtended>
                  <TextExtended color="black">{`Mac`}</TextExtended>
                </F4>
              </Button>
            </F3>
          </F2>
          <F2>
            <Text super color="black">
              Mobile
            </Text>
            <img
              src="/images/download_mobile.png"
              style={{ width: `130px`, height: `220px` }}
            />
            <F3>
              <Button href={androidPath} download>
                <FaAndroid
                  style={{
                    fontSize: "30px",
                  }}
                />
                <F4>
                  <TextExtended small>{`Download on`}</TextExtended>
                  <TextExtended color="black">{`Google Play`}</TextExtended>
                </F4>
              </Button>

              <Button href={iosPath} download>
                <FaApple
                  style={{
                    fontSize: "30px",
                  }}
                />
                <F4>
                  <TextExtended small>{`Download on`}</TextExtended>
                  <TextExtended color="black">{`App Store`}</TextExtended>
                </F4>
              </Button>
            </F3>
            <F3>
              <Button
                href={process.env.REACT_APP_S3_ANDROID_GUIDE_PATH}
                download
              >
                <FaAndroid
                  style={{
                    fontSize: "30px",
                  }}
                />
                <F4>
                  <TextExtended small>{`Install guide`}</TextExtended>
                  <TextExtended color="black">{` Android`}</TextExtended>
                </F4>
              </Button>
              <Button href={process.env.REACT_APP_S3_IOS_GUIDE_PATH} download>
                <FaApple
                  style={{
                    fontSize: "30px",
                  }}
                />
                <F4>
                  <TextExtended small>{`Install guide`}</TextExtended>
                  <TextExtended color="black">{`iOS`}</TextExtended>
                </F4>
              </Button>
            </F3>
            <F3>
              <Button href={androidManual} download>
                <FaAndroid
                  style={{
                    fontSize: "30px",
                  }}
                />
                <F4>
                  <TextExtended small>{`Manual`}</TextExtended>
                  <TextExtended color="black">{` Android`}</TextExtended>
                </F4>
              </Button>
              <Button href={iosManual} download>
                <FaApple
                  style={{
                    fontSize: "30px",
                  }}
                />
                <F4>
                  <TextExtended small>{`Manual`}</TextExtended>
                  <TextExtended color="black">{`iOS`}</TextExtended>
                </F4>
              </Button>
            </F3>
          </F2>
        </F1>
      </Container>
      <F5>
        <Text mid color="black" >
          {i18n.language} Version Download
        </Text>
      </F5>
      <Help>
        <F1_1>
          <Text color="#fff">{t(`_download.help1`)}</Text>
          <Text color="#fff" textAlign="left">
            {t(`_download.help2`)}
          </Text>
        </F1_1>
      </Help>
    </>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const Help = styled.div`
  display: flex;
  justify-content: center;
  background: linear-gradient(0.25turn, #053272, #073c82);
  height: 150px;
`;

const F1 = styled.div`
  display: flex;
  justify-content: center;
  width: 750px;

  @media (max-width: 970px) {
    flex-direction: column;
  }
`;

const F1_1 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 970px;
  margin: 0px 20px;

  & > div:before {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin-right: 8px;
    border-radius: 12px;
    background-color: #fff;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: black;
  }

  & > div:nth-child(1):before {
    content: "1";
  }

  & > div:nth-child(2):before {
    content: "2";
  }
`;

const F2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /* flex-wrap: wrap; */
  padding: 5vw 0;
  position: relative;

  & > img {
    user-select: none;
    margin: 20px 0 40px 0;
  }
`;

const F3 = styled.div`
  display: flex;
  margin: 5px 0px;
`;

const Button = styled.a`
  color: black;
  display: flex;
  align-items: center;
  border: 1px solid #dedede;
  border-radius: 5px;
  padding: 5px;
  width: 150px;
  margin: 0px 5px;

  &:hover {
    color: black;
  }
`;

const F4 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
`;

const F5 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TextExtended = styled(Text)`
  padding: 0;
`;
