/* eslint-disable no-unused-vars */
/* eslint-disable no-unreachable */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { SERVER_URI, header } from "../assets/utils/HttpHandler";
import { Menu, Dropdown } from "antd";
import {
  CaretDownOutlined,
  UserOutlined,
  MenuOutlined,
  AlignLeftOutlined,
} from "@ant-design/icons";
import Logo from "../assets/images/logo.png";
import styled from "styled-components";
import { Image, Text, Basement, Tab, useTab } from "../assets/common";

const tabList = [
  { key: 1, value: `_header.solution`, to: `/solution` },
  { key: 2, value: `_header.inquiry`, to: `/pricing` },
  { key: 3, value: `header.download`, to: `/resources/download` },
  { key: 4, value: `header.majorCustomer`, to: `/resources/major_customer` },
  {
    key: 5,
    value: `header.customerStories`, // t(`header.customerStories`)
    to: `/resources/customer_stories`,
  },
  { key: 6, value: `header.callCenter`, to: `/resources/call_center` },
];

export default function Header() {
  const history = useHistory();
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useState({});
  const [current, setCurrent] = useTab(null, tabList);
  const [active, setActive] = useState(true);

  useEffect(() => {
    const [initial] = tabList.filter((v) => v.to === history.location.pathname);

    if (initial) {
      setCurrent(initial);
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem(`accessToken`);

    const inspect = async () => {
      try {
        const response = await axios.get(`${SERVER_URI}/api/user`, header());
        setUserInfo(response.data);
      } catch (error) {
        console.log(`inspect error: `, error);
      }
    };

    if (token) {
      inspect();
    }
  }, []);

  const tabClickCallback = (next) => {
    setActive(true);
    setCurrent(next);
    history.push(next.to);
  };

  const tabMouseOver = () => {
    setActive(true);
  };

  const handleImage = () => {
    setActive(false);
    setCurrent({});
    history.push(`/`);
  };

  const handleLogout = () => {
    setActive(false);
    setCurrent({});
    localStorage.removeItem("accessToken");
    window.location.href = "/login";
  };

  const handleMyService = () => {
    setActive(false);
    setCurrent({});
    history.push(`/workspace`);
  };

  const handleProfile = () => {
    setActive(false);
    setCurrent({});
    history.push(`/profile`);
  };

  const handleLogin = () => {
    setActive(false);
    setCurrent({});
    history.push(`/login`);
  };

  const handleFaq = () => {
    setActive(false);
    setCurrent({});
    window.open(
      `http://www.ucware.net/support1.php?page=1&currentFun=all`,
      `_blank`,
      `noopener, noreferrer`
    );
  };

  const burgerMenu = (
    <Menu>
      <Menu.Item key="setting:2">
        <Text color="gray">
          <Link to="/solution">{t("_header.solution")}</Link>
        </Text>
      </Menu.Item>
      <Menu.Item key="setting:3">
        <Text color="gray">
          <Link to="/pricing">{t("_header.inquiry")}</Link>
        </Text>
      </Menu.Item>

      <Menu.Item key="setting:4">
        <Text color="gray">
          <Link to="/resources/download">{t("header.download")}</Link>
        </Text>
      </Menu.Item>
      <Menu.Item key="setting:5">
        <Text color="gray">
          <Link to="/resources/major_customer">
            {t("header.majorCustomer")}
          </Link>
        </Text>
      </Menu.Item>
      <Menu.Item key="setting:6">
        <Text color="gray">
          <Link to="/resources/customer_stories">
            {t("header.customerStories")}
          </Link>
        </Text>
      </Menu.Item>
      <Menu.Item key="setting:7">
        <Text color="gray">
          <Link to="/resources/call_center">{t("header.callCenter")}</Link>
        </Text>
      </Menu.Item>
      <Menu.Item key="setting:8">
        <Text color="gray">
          <a
            href="http://www.ucware.net/support1.php?page=1&currentFun=all"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("header.faq")}
          </a>
        </Text>
      </Menu.Item>
    </Menu>
  );

  const statusMenu = (
    <Menu>
      <Menu.Item key="0" disabled>
        <F1_1>
          <F2_2>
            <img src="/images/userCircle.png" style={{ width: `80px` }} />
          </F2_2>
          <Text color="gray">{userInfo?.id}</Text>
          <Text small color="gray">
            {userInfo?.email}
          </Text>
        </F1_1>
      </Menu.Item>
      <Menu.Item key="1" onClick={handleMyService}>
        <Text color="gray">{t("_header.myService")}</Text>
      </Menu.Item>
      <Menu.Item key="2" onClick={handleProfile} disabled>
        <Text color="gray">{t("_header.profile")}</Text>
      </Menu.Item>
      <Menu.Item key="3" onClick={handleLogout}>
        <Text color="gray">{t("_header.logOut")}</Text>
      </Menu.Item>
      <Menu.Item key="4" onClick={handleFaq}>
        <Text color="gray">{t("header.faq")}</Text>
      </Menu.Item>
    </Menu>
  );

  return (
    <Container>
      <F0>
        <F1>
          <ImageWrapper onClick={handleImage}>
            <img src="/images/logo.png" style={{ width: `100%` }} />
          </ImageWrapper>
          <Desktop>
            <Tab
              list={tabList}
              current={current}
              callback={tabClickCallback}
              color="#fff"
              active={active}
              activeCallback={tabMouseOver}
            />
          </Desktop>

          <Mobile>
            <Dropdown
              trigger={[`click`]}
              overlay={burgerMenu}
              placement="bottomCenter"
            >
              <AlignLeftOutlined style={{ fontSize: `20px`, color: `#fff` }} />
            </Dropdown>
          </Mobile>
        </F1>
        <F1>
          {localStorage.getItem("accessToken") ? (
            <Dropdown
              trigger={[`click`]}
              overlay={statusMenu}
              placement="bottomRight"
            >
              <F2_2>
                <MenuOutlined style={{ fontSize: `20px`, color: `#fff` }} />
              </F2_2>
            </Dropdown>
          ) : (
            <>
              <Text color="#fff" onClick={handleLogin} hover>
                {t("_header.logIn")}
              </Text>
              <Text color="#fff" onClick={handleFaq} hover>
                {t("header.faq")}
              </Text>
            </>
          )}
        </F1>
      </F0>
    </Container>
  );
}

const ImageWrapper = styled.div`
  width: 40px;
  margin-right: 15px;
  &:hover {
    cursor: pointer;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3a3a3a;
  height: 50px;
`;

const F0 = styled.div`
  width: 970px;
  display: flex;
  justify-content: space-between;
  margin: 0px 20px;
`;

const F1 = styled.div`
  display: flex;
  align-items: center;
`;

const Desktop = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    display: none;
  }
`;

const Mobile = styled.div`
  display: flex;
  align-items: center;
  @media (min-width: 768px) {
    display: none;
  }
`;

const F1_1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const F2_2 = styled.div`
  &:hover {
    cursor: pointer;
  }
`;
